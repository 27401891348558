import { SVGProps } from 'react'

import { ReactComponent as LogoBlue } from './assets/byterat_logo_blue_belly.svg'
import { ReactComponent as LogoWhite } from './assets/byterat_logo_white_belly.svg'

export enum AccentColor {
  Blue = 'blue',
  White = 'white'
}

export type LogoProps = SVGProps<SVGSVGElement> & {
  accentColor?: AccentColor
  size?: number | string
}

export const Byterat_Logo = (props: LogoProps) => {
  const { accentColor = AccentColor.Blue, size = '1em', ...rest } = props

  const LogoComponent = accentColor === AccentColor.Blue ? LogoBlue : LogoWhite

  return <LogoComponent width={size} height={size} {...rest} />
}
