import { Grid2, Skeleton, styled } from '@mui/material'

type ReportListHeaderProps = {
  rowCount: number
  loading?: boolean
}
export function ReportListHeader(props: ReportListHeaderProps) {
  const { rowCount, loading } = props

  return (
    <>
      <Grid2 container>
        <Grid2 size={{ xs: 12, sm: 3 }}>
          {/* <Search_Input default_value={query_string} on_change={on_search} /> */}
        </Grid2>
      </Grid2>
      <Data_Info size={12}>
        {loading ? <Skeleton width={150} /> : `${rowCount} reports`}
      </Data_Info>
    </>
  )
}

const Data_Info = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  fontSize: theme.typography.xs,
  color: theme.palette.gray[500]
}))
