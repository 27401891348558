import { useQuery } from '@apollo/client'
import { Add, Close, TrashCan } from '@carbon/icons-react'
import { Box, Button, Divider, Grid2 } from '@mui/material'
import { useAtom } from 'jotai'
import { compact } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'

import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '@/gql_generated/graphql'
import { chartStatesFamily } from '@/insights/jotai/charts.atoms'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'

import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types
} from '../charts'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import {
  Byterat_Property,
  Excluded_Byterat_Property
} from '../charts/models/byterat_properties.model'
import { InsightsChartTypes } from '../charts/types'
import { format_property_label } from '../charts/utils'
import { select_staged_dataset_table_rows } from '../home/insights_slice'
import { Autocomplete_Filter_Pair } from './filters/Autocomplete_Filter_Pair'
import { Filter_Grid_Item } from './filters/Filter_Grid'
import { Menu_Header, use_menu_nav } from './menus'
import { Rounded_Icon_Button } from './menus/shared_styled_components'

export function InsightsPanelPropertyFilters({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const { metricType, propertyFilters } = chartState

  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  // gql
  const query = {
    [InsightsChartTypes.CYCLE_METRICS]: GET_CYCLE_SUMMARY_PROPERTIES,
    [InsightsChartTypes.IN_CYCLE_METRICS]: GET_CYCLE_OBSERVATION_FILTER_CONFIGS,
    [InsightsChartTypes.CELL_METRICS]: GET_CYCLE_SUMMARY_PROPERTIES
  }[metricType]

  const { data, loading } = useQuery(query, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids:
        (staged_dataset_table_rows.length > 0
          ? staged_dataset_table_rows.map(row => row.id)
          : chartState.datasetIds) || [],
      dataset_filters: [],
      additional_filter_keys: compact(
        Object.values(propertyFilters).map(
          _propertyFilter => _propertyFilter.property?.key
        )
      )
    },
    skip: !organization_id || !workspace_id
  })

  const propertyKeyOptions = useMemo(() => {
    if (metricType === InsightsChartTypes.IN_CYCLE_METRICS) {
      return (
        data?.get_observation_filter_configs
          ?.find(
            property => property.filter_property === 'property_filter_keys'
          )
          ?.options.map(v => ({
            ...v,
            label: format_property_label(v.label, v.units)
          })) || []
      )
    } else if (metricType === InsightsChartTypes.CYCLE_METRICS) {
      return (
        (
          data as any
        )?.get_cycle_summary_properties?.cycle_summary_properties.map(
          (v: any) => ({
            ...v,
            label: format_property_label(v.label, v.units)
          })
        ) || []
      )
    }

    return []
  }, [data, metricType])

  // Includes options for selected property filters (e.g. filename options)
  const additionalPropertyKeyOptions = Object.values(propertyFilters).reduce(
    (acc: Record<string, Filter_Option[]>, _propertyFilter) => {
      acc[_propertyFilter.property?.key || ''] =
        data?.get_observation_filter_configs
          ?.find(
            property =>
              property.filter_property === _propertyFilter.property?.key
          )
          ?.options.map(v => ({
            ...v,
            label: format_property_label(v.label, v.units)
          })) || []

      return acc
    },
    {}
  )

  const handleAddPropertyFilter = () => {
    setChartState({
      ...chartState,
      propertyFilters: {
        ...propertyFilters,
        [nanoid()]: {
          property: null,
          values: [],
          filter_type: Filter_Type.IsAnyOf
        }
      }
    })
  }

  const handleDeletePropertyFilter = (filterId: string) => {
    const { [filterId]: _, ...rest } = propertyFilters
    setChartState({
      ...chartState,
      propertyFilters: rest
    })
  }

  const handlePropertyFilterPropertyChange = (
    filterId: string,
    property: Filter_Option | null
  ) => {
    setChartState({
      ...chartState,
      propertyFilters: {
        ...propertyFilters,
        [filterId]: {
          property,
          values: [],
          filter_type: Numeric_Property_Types.includes(
            property?.type as Property_Type
          )
            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
        }
      }
    })
  }

  const handlePropertyFilterValuesChange = (
    filterId: string,
    values: Filter_Option[]
  ) => {
    const _newFilter = { ...propertyFilters[filterId], values }
    if (
      typeof values === 'number' &&
      _newFilter.filter_type === Filter_Type.IsAnyOf
    ) {
      _newFilter.filter_type = Filter_Type.NumericEquals
    }

    setChartState({
      ...chartState,
      propertyFilters: {
        ...propertyFilters,
        [filterId]: _newFilter
      }
    })
  }

  const handleDeleteAllPropertyFilters = () => {
    setChartState({
      ...chartState,
      propertyFilters: {}
    })
  }

  const handlePropertyOperatorChange = (payload: {
    filterId: string
    filterType: Filter_Type
  }) => {
    setChartState({
      ...chartState,
      propertyFilters: {
        ...propertyFilters,
        [payload.filterId]: {
          ...propertyFilters[payload.filterId],
          filter_type: payload.filterType || Filter_Type.IsAnyOf
        }
      }
    })
  }

  return (
    <>
      <Menu_Header title='Filters' on_go_back={return_to_root} />
      <Box width={500}>
        <Grid2 container padding={1} paddingBottom={2} rowGap={1}>
          <Grid2
            container
            justifyContent='flex-start'
            alignItems='flex-start'
            marginLeft='auto'
            gap={2}
            size={12}
          >
            <Filter_Grid_Item
              container
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
              gap={1}
            >
              {Object.entries(propertyFilters).map(([key, filter]) => {
                const propertyOptions = filter.property
                  ? additionalPropertyKeyOptions[filter.property.key]
                  : []

                return (
                  <Filter_Grid_Item
                    container
                    key={key}
                    alignItems='center'
                    width='100%'
                  >
                    <Autocomplete_Filter_Pair
                      left_filter={{
                        value: filter.property,
                        options: propertyKeyOptions.filter((_option: any) => {
                          // TODO - hardcoded list of exclusions, until we have a way to filter by date/time properties
                          return !Excluded_Byterat_Property.includes(
                            _option.key as Byterat_Property
                          )
                        }),
                        loading: loading,
                        placeholder: 'Select Filter Property',
                        multiple: false,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handlePropertyFilterPropertyChange(key, value),
                        sx: {
                          '& .MuiInputBase-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                          }
                        }
                      }}
                      center_operator={{
                        onChange: filterType => {
                          handlePropertyOperatorChange({
                            filterId: key,
                            filterType
                          })
                        },
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                      right_filter={{
                        value: filter.values,
                        options: propertyOptions,
                        placeholder: 'Select Filter Values',
                        multiple: true,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handlePropertyFilterValuesChange(key, value),
                        type: filter?.property?.type as Property_Type,
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                      filter_type={filter.filter_type}
                      loading={loading}
                    />
                    <Box
                      flexDirection='column'
                      display='flex'
                      alignSelf='stretch'
                    >
                      <Rounded_Icon_Button
                        size='small'
                        onClick={() => handleDeletePropertyFilter(key)}
                        sx={{
                          flex: 'auto',
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 6,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 6,
                          border: theme => `1px solid ${theme.palette.divider}`
                        }}
                      >
                        <Close />
                      </Rounded_Icon_Button>
                    </Box>
                  </Filter_Grid_Item>
                )
              })}

              <Button
                variant='text'
                size='small'
                startIcon={<Add width='1em' height='1em' />}
                onClick={handleAddPropertyFilter}
              >
                Add Filter
              </Button>

              {Object.keys(propertyFilters).length > 0 && (
                <>
                  <Divider />
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<TrashCan width='1em' height='1em' />}
                    onClick={handleDeleteAllPropertyFilters}
                  >
                    Remove All
                  </Button>
                </>
              )}
            </Filter_Grid_Item>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
