import { Typography } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { BaseLayout, Spin } from '@/components'
import { select_access_token } from '@/user/user_slice'

import {
  JupyterBridgeId,
  JupyterBridgeMessageEvent,
  JupyterBridgeMessageType
} from './constants'

const { Title } = Typography

export const JupyterLite = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const accessToken = useSelector(select_access_token)

  const [isReady, setIsReady] = useState(false)

  const onMessageReceived = useCallback((event: JupyterBridgeMessageEvent) => {
    const { source, type, payload } = event.data
    if (source !== JupyterBridgeId) return
    console.log('Message received in the parent:', event.data)

    switch (type) {
      case JupyterBridgeMessageType.Error:
        console.error('JupyterLite has an error:', payload)
        break
      case JupyterBridgeMessageType.Ready:
        console.log('JupyterLite is ready. Setting flag.')
        setIsReady(true)
        break
      case JupyterBridgeMessageType.Ack:
        console.log('It is an ack message, nothing to do')
        break
      // Add more cases as needed
      default:
        console.log('Unknown message type:', type)
    }

    if (type !== JupyterBridgeMessageType.Ack) {
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: JupyterBridgeMessageType.Ack,
          message: 'Message received in the parent'
        },
        '*'
      )
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [onMessageReceived])

  useEffect(() => {
    if (isReady) {
      console.log('JupyterLite is ready, sending access token')
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'token',
          payload: accessToken
        },
        '*'
      )
    }
  }, [accessToken, isReady])

  if (accessToken == null) return <Spin />

  return (
    <BaseLayout className='flex flex-col flex-1 gap-y-4 px-0 pt-4'>
      <Title level={3} className='!mb-0 px-4'>
        Jupyter Notebooks
      </Title>
      <iframe
        src='https://byterat-redspot.vercel.app/lab/index.html?path=byterat-quickstart.ipynb'
        title='byterat redspot'
        name='redspot'
        className='flex-1'
        ref={iframeRef}
      />
    </BaseLayout>
  )
}
