import { CalendarDate, DateValue } from '@internationalized/date'

/**
 * Convert Unix timestamp to @internationalized/date CalendarDate type
 */
export function unixToDateValue(unixTimestamp: number): CalendarDate {
  const date = new Date(unixTimestamp)
  return new CalendarDate(
    date.getUTCFullYear(),
    // Months are 0-based in JS Date and 1-based in CalendarDate
    date.getUTCMonth() + 1,
    date.getUTCDate()
  )
}

/**
 * Convert @internationalized/date CalendarDate type to Unix timestamp
 */
export function dateValueToUnix(dateValue: DateValue): number {
  const { year, month, day } = dateValue

  // Months are 0-based in JS Date and 1-based in CalendarDate
  const date = new Date(Date.UTC(year, month - 1, day))
  return Math.floor(date.getTime())
}
