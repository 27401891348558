import { PayloadAction, createSelector } from '@reduxjs/toolkit'

import { Filter_Option, Property_Type } from '../../../gql_generated/graphql'
import { create_app_slice } from '../../../state/redux/create_app_slice'
import { reset_insights } from '../../../state/redux/store'
import {
  select_properties,
  select_selected_dataset_ids,
  select_staged_dataset_table_rows
} from '../../home/insights_slice'
import {
  CHART_COLORS,
  CHART_SIG_FIGS,
  CHART_TYPE,
  VALID_SCATTER_SHAPES,
  create_chart_options
} from '../chart_options'
import { format_property_label } from '../utils'

const PLOTTABLE_PROPERTY_TYPES = [
  Property_Type.Float,
  Property_Type.Date,
  Property_Type.Integer,
  Property_Type.Long,
  Property_Type.Timestamp,
  Property_Type.Percent
]

export interface Dataset_Metrics_Slice {
  x_property: Filter_Option | null
  y_properties: Filter_Option[]
}

const initial_state: Dataset_Metrics_Slice = {
  x_property: { key: 'cycle_count', label: 'Cycle Count' },
  y_properties: [
    { key: 'discharge_capacity_cycle_1', label: 'Discharge Capacity Cycle 1' }
  ]
}

export const dataset_metrics_chart_slice = create_app_slice({
  name: 'dataset_metrics_chart',
  initialState: initial_state,
  reducers: create => ({
    set_selected_x_property: create.reducer(
      (state, { payload }: PayloadAction<Filter_Option | null>) => {
        state.x_property = payload
      }
    ),
    set_selected_y_properties: create.reducer(
      (state, { payload }: PayloadAction<Filter_Option[]>) => {
        state.y_properties = payload
      }
    )
  }),
  extraReducers: builder => {
    builder.addCase(reset_insights, () => initial_state)
  },
  selectors: {
    select_selected_x_property: slice_state => slice_state.x_property,
    select_selected_y_properties: slice_state => slice_state.y_properties
  }
})

// Action creators are generated for each case reducer function.
export const { set_selected_x_property, set_selected_y_properties } =
  dataset_metrics_chart_slice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { select_selected_x_property, select_selected_y_properties } =
  dataset_metrics_chart_slice.selectors

// Memoized selectors derived from slice state

const select_x_axis = createSelector(
  [select_selected_x_property, select_properties],
  (x_property, dataset_properties) => {
    const units = dataset_properties.find(
      property => property.key === x_property?.key
    )?.units

    return {
      key: x_property?.key,
      label: format_property_label(x_property?.label, units)
    }
  }
)

export const select_metric_options = createSelector(
  [select_properties],
  properties => {
    return properties
      .filter(
        ({ property_type }) =>
          property_type && PLOTTABLE_PROPERTY_TYPES.includes(property_type)
      )
      .map(({ key, units }) => ({
        key,
        label: format_column_label(key),
        units
      }))
  }
)

export const select_y_axes = createSelector(
  [select_selected_y_properties, select_properties],
  (y_properties, dataset_properties) => {
    const y_axes: Record<
      string,
      {
        label: string
        marker: MarkerShapeOptions
      }
    > = {}

    y_properties.forEach(({ key, label }, i) => {
      const units = dataset_properties.find(
        property => property.key === key
      )?.units
      const property_label = format_property_label(label, units)
      y_axes[key] = {
        label: property_label,
        marker: VALID_SCATTER_SHAPES[i % VALID_SCATTER_SHAPES.length]
      }
    })

    return y_axes
  }
)

export const select_series_groups = createSelector(
  select_selected_dataset_ids,
  dataset_ids => {
    const series_groups: Record<string, { color: string; label: string }> = {}

    dataset_ids.forEach((dataset_id, i) => {
      const color = CHART_COLORS[i % CHART_COLORS.length]
      series_groups[dataset_id] = { color, label: dataset_id }
    })

    return series_groups
  }
)

const select_series_by_y_property = createSelector(
  [
    select_staged_dataset_table_rows,
    select_properties,
    select_selected_y_properties,
    select_selected_x_property
  ],
  (datasets, dataset_properties, y_properties, x_property) => {
    const series_by_y_property: Record<
      string,
      {
        group: string
        name: string
        data: { x: number; y: number | null }[]
      }[]
    > = {}

    if (!datasets || !x_property) return series_by_y_property

    datasets?.forEach(dataset => {
      y_properties.forEach(y_property => {
        const group = dataset.id
        const units = dataset_properties.find(
          property => property.key === y_property.key
        )?.units
        const y_property_label = format_property_label(y_property.label, units)
        const name = `${group} ${y_property_label}`

        let data: {
          x: number
          y: number
        }[] = []

        if (
          dataset[y_property.key] !== null &&
          dataset[x_property.key] !== null
        ) {
          data.push({ x: dataset[x_property.key], y: dataset[y_property.key] })

          if (!series_by_y_property[y_property.key]) {
            series_by_y_property[y_property.key] = [{ name, group, data }]
          } else {
            series_by_y_property[y_property.key].push({ name, group, data })
          }
        }
      })
    })

    return series_by_y_property
  }
)

export const select_chart_data = createSelector(
  select_series_by_y_property,
  series_by_y_property => {
    return Object.values(series_by_y_property).flat()
  }
)

export const select_apex_chart_options = createSelector(
  [
    select_x_axis,
    select_y_axes,
    select_series_groups,
    select_series_by_y_property
  ],
  (x_axis, y_axes, series_groups, series_by_y_property) => {
    const apex_x_axis: ApexXAxis = {
      title: { text: x_axis.label },
      labels: {
        formatter: function (val: string) {
          if (val === undefined) return val

          return Number(val).toPrecision(CHART_SIG_FIGS + 1)
        }
      },
      type: 'numeric'
    }

    const apex_y_axes: ApexYAxis[] = []
    const colors: string[] = []
    const marker_shapes: MarkerShapeOptions[] = []

    Object.keys(y_axes).forEach((y_axis_key, y_axis_count) => {
      const y_axis = y_axes[y_axis_key]
      const group_keys = Object.keys(series_groups)
      const default_group_key = group_keys[0]
      group_keys.forEach((group_key, group_count) => {
        if (
          series_by_y_property[y_axis_key] &&
          series_by_y_property[y_axis_key].findIndex(
            series => series.group === group_key
          ) !== -1
        ) {
          const group = series_groups[group_key]
          colors.push(group.color)
          marker_shapes.push(y_axis.marker)
          apex_y_axes.push({
            title: { text: y_axis?.label },
            opposite: y_axis_count !== 0,
            show: group_count === 0,
            seriesName: `${default_group_key} ${y_axis.label}`,
            labels: {
              formatter: function (val: number) {
                return val?.toPrecision(CHART_SIG_FIGS + 1)
              }
            }
          })
        }
      })
    })

    return {
      options: create_chart_options({
        x_axis: apex_x_axis,
        y_axes: apex_y_axes,
        colors,
        marker_shapes,
        chart_type: CHART_TYPE.SCATTER
      })
    }
  }
)

// split column_name by underscores and capitalize each word
const format_column_label = (column_name: string) => {
  return column_name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
