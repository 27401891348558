import { gql } from '@/gql_generated'

export const LOAD_METRIC_TARGET_LIMITS = gql(`
  query load_metric_target_limits($organization_id: String!, $workspace_id: String!) {
    load_metric_target_limits(
      organization_id: $organization_id,
      workspace_id: $workspace_id
    ) {
      metric_target_limits {
        metric_name
        lower_limit
        upper_limit
      }
    }
  }
`)

export const SAVE_METRIC_TARGET_LIMITS = gql(`
  mutation save_metric_target_limits($organization_id: String!, $workspace_id: String!, $metric_target_limits: [Iris_Metric_Target_Limits_Input!]!) {
    save_metric_target_limits(
      organization_id: $organization_id,
      workspace_id: $workspace_id,
      metric_target_limits: $metric_target_limits
    ) {
      metric_target_limits {
        metric_name
        lower_limit
        upper_limit
      }
    }
  }
`)
