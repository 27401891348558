import { Grid2, Skeleton, styled } from '@mui/material'

import { Search_Input } from '@/components/Search_Input/Search_Input'
import { Styled_ChevronRight } from '@/insights/controls/menus/shared_styled_components'
import { Insights_Datasets_Table_Filter_Panel } from '@/insights/tables/Insights_Datasets_Table_Filter_Panel'

type EditableReportTableHeaderProps = {
  queryString?: string
  rowCount?: number
  selectedRowCount?: number
  loading?: boolean
  onSearch: (new_value: string) => void
}
export function EditableReportTableHeader(
  props: EditableReportTableHeaderProps
) {
  const {
    rowCount = 0,
    selectedRowCount = 0,
    loading,
    onSearch,
    queryString = ''
  } = props

  const selected_row_count = selectedRowCount

  return (
    <>
      <Grid2 container>
        <Grid2 size={{ xs: 12, sm: 3 }}>
          <Search_Input default_value={queryString} on_change={onSearch} />
        </Grid2>
        <Filter_Panel_Wrapper size={{ xs: 12, sm: 'grow' }}>
          <Insights_Datasets_Table_Filter_Panel />
        </Filter_Panel_Wrapper>
      </Grid2>
      <Data_Info size={12}>
        {loading ? <Skeleton width={150} /> : `${rowCount} matching datasets`}
        {selected_row_count > 0 && (
          <>
            <Styled_ChevronRight />
            {selected_row_count} selected
          </>
        )}
      </Data_Info>
    </>
  )
}

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0
  }
}))

const Data_Info = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  fontSize: theme.typography.xs,
  color: theme.palette.gray[500]
}))
