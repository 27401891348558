import { gql } from '@/gql_generated'

export const GET_UPLOADED_FILES = gql(`
  query get_uploaded_files($organization_key: String!, $workspace_key: String!) {
    get_uploaded_files(
      organization_key: $organization_key, 
      workspace_key: $workspace_key
    ) {
      files
    }
  }
`)

export const GET_UPLOADED_FILES_SUMMARY = gql(`
  query get_uploaded_files_summary($organization_key: String!, $workspace_key: String!) {
    get_uploaded_files_summary(
      organization_key: $organization_key, 
      workspace_key: $workspace_key
    ) {
      num_uploaded
      size_uploaded
      allowed_types
    }
  }
`)

export const UPLOAD_FILES = gql(`
  query upload_files($organization_key: String!, $workspace_key: String!, $files: [String!]!) {
    upload_files(
      organization_key: $organization_key, 
      workspace_key: $workspace_key, 
      files: $files
    ) {
      urls
    }
  }
`)

export const GET_ETL_STATUS = gql(`
  query get_etl_statuses($file_keys: [String!]!) {
    get_etl_statuses(
      file_keys: $file_keys
    ) {
      id
      file_key
      status
      start_time
      end_time
      created_at
    }
  }
`)
