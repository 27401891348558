export const getCustomTooltipHtml = (
  xFormatted: string,
  yTitle: string,
  yFormatted: string,
  seriesColor: string,
  annotations: string[]
) => {
  return `
		<div style="background-color: white; font-size: 0.75rem; color: rgb(31 41 55);">
			<div style="font-weight: 600; padding: 0.4rem; background-color: rgb(237, 239, 241); color: rgb(75 85 99);">
				${xFormatted}
			</div>
			<div style="display: flex; align-items: center; padding: 0.4rem; gap: 0.5rem;">
				<div
					style="width: 0.625rem; height: 0.625rem; border-radius: 9999px; background-color: ${seriesColor}">
				</div>
				<div style="color: rgb(31 41 55);">
					<span style="font-weight: 500;">${yTitle}:</span>
					<span>${yFormatted}</span>
				</div>
			</div>
			${
        annotations.length > 0
          ? `<div style="padding: 0.5rem; border-top: 1px solid rgb(229 231 235); padding-top: 0.5rem; color: rgb(255 255 255);">
						${annotations.map(a => `<div style="padding: 0.1rem 0.5rem; border-radius: 9999px; background-color: rgb(204,51,0); width: fit-content;">${a}</div>`).join('')}
					</div>`
          : ''
      }
		</div>
	`
}
