import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash-es'
import { useMemo } from 'react'

import { Filter_Option } from '@/gql_generated/graphql'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import {
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  CHART_AGGREGATION_TYPES
} from '../charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { format_property_label } from '../charts/utils'
import { visualizationAtomFamily } from '../reports/store/report.molecule'
import { TableConfig, Visualization } from '../reports/types'
import { Autocomplete_Filter } from './filters/Autocomplete_Filter'
import { Menu_Header, Menu_Item_Control, use_menu_nav } from './menus'

export function InsightsPanelTableGroupBy({ tableId }: { tableId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [visualization, setVisualization] = useAtom(
    visualizationAtomFamily({ id: tableId })
  )

  const config = visualization.config as TableConfig
  const { data, loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const setVisualizationGroupByProperty = (property: Filter_Option | null) => {
    // Add the column to the columns list if it's not already present
    const newColumns =
      property && !config?.columns?.includes(property?.key)
        ? [...(config?.columns ?? []), property?.key]
        : config?.columns

    setVisualization(prev => {
      const next: Visualization = {
        ...prev,
        config: {
          ...prev.config,
          groupByProperty: property?.key,
          columns: newColumns
        } as any
      }
      return isEqual(prev, next) ? prev : next
    })
  }

  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    return (
      data?.get_cycle_summary_properties?.dataset_properties?.map(v => ({
        ...v,
        label: format_property_label(v.label, v.units)
      })) || []
    )
  }, [data])

  return (
    <>
      <Menu_Header title='Group By' on_go_back={return_to_root} />

      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='Group By' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='group-by-type'
              aria-labelledby='group-by-type'
              disableClearable={false}
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={(_e: any, newGroupByProperty: Filter_Option | null) => {
                setVisualizationGroupByProperty(newGroupByProperty)
              }}
              options={observationPropertyOptions as Filter_Option[]}
              placeholder='Add Metric'
              value={{
                key: config.groupByProperty?.key || '',
                label: config.groupByProperty?.label || ''
              }}
            />
          </Menu_Item_Control>
          <Menu_Item_Control label='Aggregate by' input_id='aggregate-by'>
            <Autocomplete_Filter
              id='aggregate-by'
              aria-labelledby='aggregate-by'
              disableClearable
              disabled
              grid_item_size={7}
              loading={loading}
              multiple={false}
              options={CHART_AGGREGATION_TYPES}
              placeholder='Add Metric'
              value={AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD}
            />
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
