import { gql } from '@/gql_generated'

export const SAVE_REPORT = gql(`
  mutation save_report($id: String!, $title: String!, $organization_id: String!, $workspace_id: String!, $report_query: String!, $description: String, $schema_version: Int) {
    save_report(
      id: $id, 
      title: $title, 
      organization_id: $organization_id, 
      workspace_id: $workspace_id, 
      report_query: $report_query,
      description: $description,
      schema_version: $schema_version
    ) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_query
    }
  }
`)

export const GET_REPORTS = gql(`
  query get_reports($organization_id: String!, $workspace_id: String!, $page: Float!, $page_size: Float!) {
    get_reports(organization_id: $organization_id, workspace_id: $workspace_id, page: $page, page_size: $page_size) {
      data {
        id
        title
        description
        schema_version
        created_at
        updated_at
        report_query
      }
      total
      page
      page_size     
    }
  }
`)

export const LOAD_REPORT = gql(`
  query load_report($id: String!) {
    load_report(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_query
    }
  }
`)

export const DELETE_REPORT = gql(`
  mutation delete_report($id: String!) {
    delete_report(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_query
    }
  }
`)
