import { styled } from '@mui/material'

import { Byterat_Logo } from '../components'

export const Error_Fallback = () => (
  <Styled_Full_Page_Wrapper>
    <Byterat_Logo aria-label='Byterat Logo' height={100} width={100} />
  </Styled_Full_Page_Wrapper>
)

const Styled_Full_Page_Wrapper = styled('div')`
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
