import { AGGREGATION_TYPE_NONE } from '@/insights/charts'
import { InsightsChartTypes } from '@/insights/charts/types'
import { SelectorOption } from '@/insights/types'

export const isAggregateByNone = (
  chartType: InsightsChartTypes,
  groupByProperty: SelectorOption | null,
  aggregateByProperty: SelectorOption | null
) => {
  switch (chartType) {
    case InsightsChartTypes.CYCLE_METRICS:
      return (
        groupByProperty !== null &&
        aggregateByProperty?.key === AGGREGATION_TYPE_NONE.key
      )
    case InsightsChartTypes.IN_CYCLE_METRICS:
    case InsightsChartTypes.CELL_METRICS:
      return groupByProperty !== null
    default:
      return false
  }
}
