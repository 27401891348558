import { useMutation } from '@apollo/client'
import { Drawer } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { cloneDeep } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import {
  Alert,
  Button,
  Input,
  Modal,
  Popconfirm,
  Switch,
  Typography,
  notification
} from '@/components'
import { CurrentSchemaVersions } from '@/constants'
import { InsightsChartTypeLabels } from '@/insights/charts/types'
import { cn, omitNullishOrEmptyValues } from '@/utils'

import { SaveReportTemplate } from '../../components/SaveReportTemplate'
import { GET_REPORTS, LOAD_REPORT, SAVE_REPORT } from '../../queries/reports'
import {
  VisualizationType,
  fullReportAtomFamily,
  reportAtomFamily
} from '../../store/report.molecule'
import { FullReportAtom, TableConfig } from '../../types'
import { EditableReportChart } from './EditableReportChart'
import { EditableReportTable } from './EditableReportTable'
import { ReportTable } from './controls/InsightsReportTable'
import { calculateIsReportDirty } from './report.utils'

const { Title } = Typography

type ReportItemProps = {
  report: FullReportAtom
  isPreview?: boolean
  onResetVisualization?: (visualizationId: string) => void
}

export const ReportItem = (props: ReportItemProps) => {
  // Only expose the JSON modal if the debug query param is present
  const [urlSearchParams] = useSearchParams()
  const isDebug = urlSearchParams.get('debug') !== null
  const [api, contextHolder] = notification.useNotification()

  const { report, isPreview, onResetVisualization } = props
  const {
    id: reportId,
    visualizations = [],
    datasetIds,
    workspaceId,
    organizationId,
    title,
    schemaVersion
  } = report ?? {}
  const isSchemaOutdated = schemaVersion !== CurrentSchemaVersions.Chart

  const [showDatasetsDrawer, setShowDatasetsDrawer] = useState(false)
  const [showJsonModal, setShowJsonModal] = useState(false)
  const [reportCopyTitle, setReportCopyTitle] = useState<string>(
    `${title} (Copy)`
  )
  const [showEditControls, setShowEditControls] = useState(false)

  const tableItemConfigs = visualizations.filter(
    ({ config }) => config.type === VisualizationType.Table
  )
  const chartItemConfigs = visualizations.filter(
    ({ type }) => type === VisualizationType.Chart
  )

  const [transientReport, setTransientReport] = useAtom(
    reportAtomFamily({
      id: report.id,
      datasetIds,
      workspaceId,
      organizationId
    })
  )
  const fullReport = useAtomValue(fullReportAtomFamily(report.id))

  const [saveReport, { loading: savingReport }] = useMutation(SAVE_REPORT)

  const saveableReport = omitNullishOrEmptyValues(fullReport) as FullReportAtom

  const { datasetIds: transientDatasetIds } = transientReport

  // A report is considered dirty if it has unsaved changes or if the datasetIds have changed
  const isReportDirty = calculateIsReportDirty(
    omitNullishOrEmptyValues(report),
    saveableReport
  )

  const makeHandleSaveReport =
    (asNewReport = false) =>
    async () => {
      // Prevent saving the report if it's already saving or if the full report is null
      if (savingReport || saveableReport == null) return

      // Clone the full report to avoid mutating the original
      const reportToSave = cloneDeep(saveableReport)

      // If saving as a new report, reset the visualization atoms to their initial state,
      // set a new id for the visualization being saved, and set a new id for the report.
      if (asNewReport) {
        reportToSave.visualizations.forEach(visualization => {
          // Reset the visualization atoms to their initial state
          onResetVisualization?.(visualization.id)
          // Set a new id for the visualization being saved
          visualization.id = nanoid()
        })
        // Set a new id for the report
        reportToSave.id = uuidv4()
        // Set the new title
        reportToSave.title = reportCopyTitle || `${title} (Copy)`
      }
      try {
        // Save the report
        await saveReport({
          variables: {
            id: reportToSave.id,
            report_query: JSON.stringify(reportToSave),
            title: reportToSave.title,
            workspace_id: reportToSave.workspaceId,
            organization_id: reportToSave.organizationId,
            schema_version: reportToSave.schemaVersion
          },
          refetchQueries: [GET_REPORTS, LOAD_REPORT],
          awaitRefetchQueries: true
        })
        api.success({
          message: 'Report saved successfully',
          description: (
            <Link to={`../item/${reportToSave.id}`}>
              <div className='font-mono'>{reportToSave.title}</div>
            </Link>
          )
        })
      } catch (e) {
        console.error(e)
      }
    }

  return (
    <>
      {contextHolder}
      {!isPreview && (
        <div className='absolute top-4 right-6'>
          <Switch
            checkedChildren='Edit'
            unCheckedChildren='View'
            defaultChecked={false}
            disabled={isSchemaOutdated}
            size='default'
            onChange={setShowEditControls}
          />
        </div>
      )}
      <div className='flex flex-row justify-between items-center mb-8'>
        <Title
          className='[&>button]:text-sm flex flex-1 flex-row items-baseline gap-x-1 !mb-0'
          level={3}
          editable={
            showEditControls
              ? {
                  onChange: (value: string) => {
                    setReportCopyTitle(value)
                    setTransientReport(prev => ({
                      ...prev,
                      title: value
                    }))
                  }
                }
              : undefined
          }
        >
          {transientReport.title || 'Untitled Report'}
        </Title>

        <div className='flex gap-x-2 items-center'>
          {showEditControls && (
            <div className='flex gap-x-2'>
              <Button
                onClick={() => setShowDatasetsDrawer(true)}
                className='text-xs font-medium'
              >
                Modify datasets
              </Button>

              <SaveReportTemplate
                reportId={reportId}
                isDirty={isReportDirty}
                isSchemaOutdated={isSchemaOutdated}
              />
              <Popconfirm
                title='Save as new Report?'
                placement='topRight'
                overlayInnerStyle={{ width: '400px' }}
                overlayClassName='[&_.ant-popconfirm-message-text]:flex-1 [&_.ant-popconfirm-description]:flex-1'
                description={
                  <div className='py-2'>
                    <label className='w-full flex items-center gap-x-2'>
                      <span className='font-medium'>Title:</span>
                      <div className='flex-1'>
                        <Input
                          onChange={e => setReportCopyTitle(e.target.value)}
                          onBlur={e => {
                            setReportCopyTitle(
                              e.target.value || `${title} (Copy)`
                            )
                          }}
                          value={reportCopyTitle}
                        />
                      </div>
                    </label>
                  </div>
                }
                onConfirm={makeHandleSaveReport(true)}
              >
                <Button
                  disabled={!isReportDirty || isSchemaOutdated}
                  className='text-xs font-medium'
                >
                  Save as new report
                </Button>
              </Popconfirm>
              <Button
                type='primary'
                onClick={makeHandleSaveReport()}
                className='text-xs font-medium'
                disabled={!isReportDirty || isSchemaOutdated}
              >
                Save
              </Button>
              {isDebug && (
                <Button
                  onClick={() => setShowJsonModal(true)}
                  size='small'
                  variant='filled'
                  color='default'
                  className='text-xs font-semibold text-gray-500'
                >
                  Show JSON
                </Button>
              )}
            </div>
          )}
        </div>
        <Modal
          title='Report JSON'
          open={showJsonModal}
          onCancel={() => setShowJsonModal(false)}
          footer={null}
          centered
        >
          <div className='max-h-[80vh] max-w-[80vw] overflow-auto p-4 bg-gray-100 rounded-md'>
            <pre className='text-xxs'>
              {JSON.stringify(transientReport, null, 2)}
            </pre>
          </div>
        </Modal>
      </div>

      {transientDatasetIds != null && (
        <>
          <div
            className={cn('grid gap-8', {
              'grid-cols-1': chartItemConfigs.length === 1,
              'sm:grid-cols-2': chartItemConfigs.length > 1,
              'xl:grid-cols-3': chartItemConfigs.length > 2
            })}
          >
            {isSchemaOutdated && (
              <Alert
                className='mb-4 col-span-full'
                banner
                type='warning'
                message='This report uses an outdated schema.'
                description='We recommend rebuilding this report to ensure proper functionality.'
              />
            )}
            {chartItemConfigs.map(({ config, title, id }, index) => {
              if (config.type !== VisualizationType.Chart) return null

              return (
                <EditableReportChart
                  chartId={id}
                  key={index}
                  config={config}
                  datasetIds={transientDatasetIds}
                  organizationId={organizationId}
                  title={title}
                  subTitle={
                    isDebug
                      ? `Metric Type: ${
                          InsightsChartTypeLabels[config.metricType]
                        }`
                      : undefined
                  }
                  workspaceId={workspaceId}
                  showEditControls={showEditControls}
                />
              )
            })}
          </div>
          <div className='flex flex-col'>
            {tableItemConfigs.map(({ config, title, id }, index) => {
              if (config.type !== VisualizationType.Table) return null
              return (
                <ReportTable
                  key={index}
                  tableId={id}
                  datasetIds={transientDatasetIds}
                  workspaceId={workspaceId}
                  organizationId={organizationId}
                  config={config}
                  title={title}
                  showEditControls={showEditControls}
                />
              )
            })}
          </div>
        </>
      )}
      <Drawer
        title='Modify Datasets'
        placement='bottom'
        onClose={() => setShowDatasetsDrawer(false)}
        open={showDatasetsDrawer}
        destroyOnClose
        getContainer={false}
        className={cn(
          '[&_.ant-drawer-close]:absolute',
          '[&_.ant-drawer-close]:right-5'
        )}
        height='600px'
      >
        <EditableReportTable
          reportId={reportId}
          config={tableItemConfigs[0]?.config as TableConfig}
        />
      </Drawer>
    </>
  )
}
