export function _getDatasetHasMultipleCycles(rawData: any[]): boolean {
  const allHaveSingleCycle = rawData.every(
    (data: any) => data.cycles && data.cycles.length === 1
  )
  const cycleNumbersMatch =
    allHaveSingleCycle &&
    rawData.every(
      (data: any, _, array) =>
        data.cycles[0].cycle_number === array[0].cycles[0].cycle_number
    )
  const hasMultipleCycles =
    rawData.some((data: any) => data.cycles && data.cycles.length > 1) ||
    (allHaveSingleCycle && !cycleNumbersMatch)

  return hasMultipleCycles
}
