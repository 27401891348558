import { ChartLineData, ChevronDown } from '@carbon/icons-react'
import {
  Grid2,
  Grid2Props,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled
} from '@mui/material'
import { useAtom } from 'jotai'

import {
  InsightsChartTypeLabels,
  InsightsChartTypes
} from '@/insights/charts/types'
import { chartStatesFamily } from '@/insights/jotai/charts.atoms'

export function Chart_Metric_Type_Select({ chartId }: { chartId: string }) {
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )

  const handle_active_chart_change = (e: SelectChangeEvent) => {
    setChartState({
      metricType: e.target.value as InsightsChartTypes
    })
  }

  return (
    <Select
      tab-index={0}
      sx={{
        border: 0,
        boxShadow: 'none',
        width: '100%',
        padding: 0,
        '& .MuiSelect-icon': {
          top: 'auto',
          color: theme => theme.palette.gray['500'],
          width: '1em',
          height: '1em'
        },
        '& .MuiSelect-select': {
          paddingInline: theme => theme.spacing(1),
          '&:hover': {
            backgroundColor: theme => theme.palette.grey[100]
          }
        }
      }}
      IconComponent={ChevronDown}
      value={chartState.metricType}
      renderValue={value => {
        return (
          <Select_Value>
            <ChartLineData width={18} height={18} />
            Chart: {InsightsChartTypeLabels[value as InsightsChartTypes]}
          </Select_Value>
        )
      }}
      onChange={handle_active_chart_change}
      MenuProps={{
        slotProps: { paper: { elevation: 4 } }
      }}
    >
      {Object.entries(InsightsChartTypes).map(([key, value]) => (
        <Menu_Item_Label
          key={key}
          value={value}
          selected={chartState.metricType === value}
        >
          Chart: {InsightsChartTypeLabels[value]}
        </Menu_Item_Label>
      ))}
    </Select>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))

const Select_Value = styled((props: Grid2Props) => (
  <Grid2
    {...props}
    columnGap={1}
    display='flex'
    direction='row'
    alignItems='center'
  />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  // gap: '0.5rem',
  flex: 1,
  fontSize: theme.typography.sm
}))
