import { Non_Absolute_Time_Byterat_Property } from '../../charts/models/byterat_properties.model'
import { SelectorOption } from '../../types'

/**
 * Get axis limit value based on property type
 * - For time scale properties, limits are provided in hours but raw data is in milliseconds
 */
export const _getAxisLimitValue = (
  property: SelectorOption,
  value: number | null | undefined
) => {
  if (value === undefined || value === null) return undefined

  // Absolute times come from the date picker component and are in milliseconds
  // Non-absolute times come from the numeric filter component and are in hours
  const _isTimeScale = Non_Absolute_Time_Byterat_Property.includes(property.key)

  // Convert from hours to milliseconds if time scale
  return _isTimeScale ? value * 1000 * 60 * 60 : value
}
