import { DataCategorical } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'

import { Select } from '@/components'
import { Property_Option } from '@/gql_generated/graphql'
import { InsightsChartTypes } from '@/insights/charts/types'
import { SelectorOption } from '@/insights/types'
import { mapListKeysToValues } from '@/utils'

import {
  AGGREGATION_TYPE_NONE,
  AggregationType
} from '../../../../../../charts'
import {
  VisualizationType,
  visualizationAtomFamily
} from '../../../../../store/report.molecule'
import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelGroupByProps = {
  metricType: InsightsChartTypes
  groupByOptions: Property_Option[]
  aggregateByOptions: SelectorOption[]
  loading?: boolean
}

/**
 * PanelYAxis component renders the Y-Axis configuration panel for a chart visualization.
 *
 * @param {PanelGroupBy} props - The properties for the PanelYAxis component.
 */
export function PanelGroupBy(props: PanelGroupByProps) {
  const {
    metricType,
    groupByOptions,
    aggregateByOptions,
    loading = true
  } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Chart) return null

  const { groupByProperty, aggregateByProperty } = config

  /**
   * Handles the change event for the Group By select input.
   *
   * @param {string} value - The selected value for the Group By property.
   */
  const onGroupByChange = (value: Nullable<string>) => {
    if (groupByOptions == null) return

    if (value == null) {
      updateVisualizationConfig({ groupByProperty: null })
      return
    }

    const groupByProperty = groupByOptions.find(({ key }) => key === value)
    if (groupByProperty == null) return

    updateVisualizationConfig({ groupByProperty })
  }

  /**
   * Handles the change event for the aggregate by property.
   *
   * @param value - The new value for the aggregate by property.
   */
  const onAggregateByChange = (value: AggregationType) => {
    const newAggregateByProperty = aggregateByOptions.find(
      ({ key }) => key === value
    )
    updateVisualizationConfig({ aggregateByProperty: newAggregateByProperty })
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <DataCategorical />
            Group By
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          <MenuItemControl
            label='Group By'
            inputId='group-by'
            loading={loading}
            emphasize
          >
            <Select
              allowClear
              aria-labelledby='group-by'
              id='group-by'
              options={mapListKeysToValues(groupByOptions)}
              placeholder='Select Metric'
              showSearch
              className='flex-1'
              value={groupByProperty}
              onChange={onGroupByChange}
            />
          </MenuItemControl>
          <MenuItemControl
            label='Aggregate By'
            inputId='aggregate-by'
            loading={loading}
          >
            <Select
              allowClear={false}
              aria-labelledby='aggregate-by'
              id='aggregate-by'
              options={mapListKeysToValues(aggregateByOptions)}
              placeholder='Select Aggregation'
              showSearch
              className='flex-1'
              value={
                metricType === InsightsChartTypes.CYCLE_METRICS
                  ? aggregateByProperty
                  : AGGREGATION_TYPE_NONE
              }
              onChange={onAggregateByChange}
              defaultValue={AGGREGATION_TYPE_NONE}
              disabled={
                groupByProperty == null ||
                metricType !== InsightsChartTypes.CYCLE_METRICS
              }
            />
          </MenuItemControl>
        </div>
      </div>
    </div>
  )
}
