import { PayloadAction, createSelector } from '@reduxjs/toolkit'
import { ApexOptions } from 'apexcharts'
import { uniqBy } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'

import {
  Cycle_Summaries,
  Filter,
  Filter_Option,
  Filter_Type,
  Normalize_Type,
  Property_Option,
  Property_Type
} from '../../../gql_generated/graphql'
import { create_app_slice } from '../../../state/redux/create_app_slice'
import { reset_insights } from '../../../state/redux/store'
import {
  PLOTTABLE_PROPERTY_TYPES,
  PropertyFilterWithValues,
  SelectorOption
} from '../../types'
import {
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  AGGREGATION_TYPE_NONE,
  CHART_COLORS,
  CHART_TYPE,
  DEFAULT_AREA_OPACITY,
  DEFAULT_LINE_STROKE_WIDTH,
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  MARKER_SHAPE_SIZE,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types,
  OnAxesRangeChange,
  VALID_MARKER_SHAPES,
  create_chart_options
} from '../chart_options'
import {
  Absolute_Time_Byterat_Property,
  Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '../models/byterat_properties.model'
import { format_axis_tick, format_property_label } from '../utils'

export interface Normalize_By_Option extends SelectorOption {
  type: Normalize_Type
  group?: string
}

const NORMALIZE_BY_INDEX_VALUES: Normalize_By_Option[] = [
  {
    key: 0,
    label: 'Initial Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 1,
    label: 'Second Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 2,
    label: 'Third Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 3,
    label: 'Fourth Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 4,
    label: 'Fifth Cycle',
    type: Normalize_Type.NthCycle
  }
]

export interface Cycle_Summaries_Slice {
  plottable_properties: SelectorOption[]
  group_by_properties: SelectorOption[]
  normalize_by_options: Normalize_By_Option[]
  selected_y_cycle_summaries_properties: SelectorOption[]
  selected_x_cycle_summaries_property: SelectorOption
  x_property_range: [number | null, number | null]
  y_property_ranges: Record<string, [number | null, number | null]> | null
  selected_group_by_property: SelectorOption | null
  selected_aggregate_by_property: SelectorOption
  selected_normalize_by_option: Normalize_By_Option | null
  cycle_summaries?: Cycle_Summaries
  chart_type: CHART_TYPE
  property_filters: Record<string, PropertyFilterWithValues>
}

const initial_state: Cycle_Summaries_Slice = {
  plottable_properties: [],
  group_by_properties: [],
  normalize_by_options: [],
  selected_y_cycle_summaries_properties: [
    { key: 'energy_efficiency', label: 'Energy Efficiency' }
  ],
  selected_x_cycle_summaries_property: {
    key: 'cycle_number',
    label: 'Cycle Number'
  },
  x_property_range: [null, null],
  y_property_ranges: null,
  selected_group_by_property: null,
  selected_aggregate_by_property: AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  selected_normalize_by_option: null,
  chart_type: CHART_TYPE.LINE,
  property_filters: {}
}

export const cycle_summaries_chart_slice = create_app_slice({
  name: 'cycle_summaries_chart',
  initialState: initial_state,
  reducers: create => ({
    set_cycle_summaries: create.reducer(
      (state, { payload }: PayloadAction<Cycle_Summaries>) => {
        state.cycle_summaries = payload
      }
    ),
    set_selected_y_cycle_summaries_properties: create.reducer(
      (state, { payload }: PayloadAction<SelectorOption[]>) => {
        state.selected_y_cycle_summaries_properties = payload
      }
    ),
    set_selected_x_cycle_summaries_property: create.reducer(
      (state, { payload }: PayloadAction<SelectorOption>) => {
        state.selected_x_cycle_summaries_property = payload
      }
    ),
    set_chart_ranges: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          x_range: [number | null, number | null]
          y_ranges: Record<string, [number | null, number | null]> | null
        }>
      ) => {
        state.x_property_range = payload.x_range
        state.y_property_ranges = payload.y_ranges
      }
    ),
    set_x_property_range: create.reducer(
      (state, { payload }: PayloadAction<[number | null, number | null]>) => {
        state.x_property_range = payload
      }
    ),
    set_y_property_ranges: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<Record<string, [number | null, number | null]> | null>
      ) => {
        state.y_property_ranges = payload
      }
    ),
    set_chart_type: create.reducer(
      (state, { payload }: PayloadAction<CHART_TYPE>) => {
        state.chart_type = payload
      }
    ),
    set_selected_group_by_property: create.reducer(
      (state, { payload }: PayloadAction<SelectorOption | null>) => {
        state.selected_group_by_property = payload
      }
    ),
    set_selected_aggregate_by_property: create.reducer(
      (state, { payload }: PayloadAction<SelectorOption>) => {
        state.selected_aggregate_by_property = payload
      }
    ),
    set_selected_normalize_by_option: create.reducer(
      (state, { payload }: PayloadAction<Normalize_By_Option | null>) => {
        state.selected_normalize_by_option = payload
      }
    ),
    set_property_options: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          cycle_summary_properties: Property_Option[]
          dataset_properties: Property_Option[]
        }>
      ) => {
        state.plottable_properties = payload.cycle_summary_properties
          .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
          .map(({ key, label }) => ({ key, label }))

        state.group_by_properties = payload.dataset_properties.map(
          ({ key, label }) => ({ key, label })
        )

        state.normalize_by_options = NORMALIZE_BY_INDEX_VALUES.concat(
          payload.dataset_properties
            .filter(
              ({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type)
            )
            .map(({ key, label }) => ({
              key,
              label,
              type: Normalize_Type.DatasetProperty,
              group: 'Dataset Metrics'
            }))
        )
      }
    ),
    create_property_filter: create.reducer(state => {
      const filter_id = uuidv4()

      state.property_filters[filter_id] = {
        property: null,
        values: [],
        filter_type: Filter_Type.IsAnyOf
      }
    }),
    delete_property_filter: create.reducer(
      (state, { payload }: PayloadAction<{ filter_id: string }>) => {
        delete state.property_filters[payload.filter_id]
      }
    ),
    delete_all_property_filters: create.reducer(state => {
      state.property_filters = {}
    }),
    set_property_filter_values: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{ filter_id: string; values: Filter_Option[] }>
      ) => {
        state.property_filters[payload.filter_id].values = payload.values
        if (
          typeof payload.values === 'number' &&
          state.property_filters[payload.filter_id].filter_type ===
            Filter_Type.IsAnyOf
        ) {
          state.property_filters[payload.filter_id].filter_type =
            Filter_Type.NumericEquals
        }
      }
    ),
    set_property_filter_property: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          filter_id: string
          property: Filter_Option | null
          filter_type?: Filter_Type
        }>
      ) => {
        state.property_filters[payload.filter_id] = {
          property: payload.property,
          values: [],
          filter_type: Numeric_Property_Types.includes(
            payload.property?.type as Property_Type
          )
            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
        }
      }
    ),
    set_property_filter_operator: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          filter_id: string
          filter_type: Filter_Type
        }>
      ) => {
        state.property_filters[payload.filter_id].filter_type =
          payload.filter_type || Filter_Type.IsAnyOf
      }
    )
  }),
  extraReducers: builder => {
    builder.addCase(reset_insights, () => initial_state)
  },
  selectors: {
    select_selected_y_cycle_summaries_properties: slice_state =>
      slice_state.selected_y_cycle_summaries_properties,
    select_selected_x_cycle_summaries_property: slice_state =>
      slice_state.selected_x_cycle_summaries_property,
    select_chart_type: slice_state => slice_state.chart_type,
    select_x_property_range: slice_state => slice_state.x_property_range,
    select_y_property_ranges: slice_state => slice_state.y_property_ranges,
    select_selected_group_by_property: slice_state =>
      slice_state.selected_group_by_property,
    select_selected_aggregate_by_property: slice_state =>
      slice_state.selected_aggregate_by_property,
    select_selected_normalize_by_option: slice_state =>
      slice_state.selected_normalize_by_option,
    select_selected_normalize_by_arg: slice_state =>
      slice_state.selected_normalize_by_option,
    select_cycle_summaries: slice_state => slice_state.cycle_summaries,
    select_plottable_property_options: slice_state =>
      slice_state.plottable_properties,
    select_normalize_by_options: slice_state =>
      slice_state.normalize_by_options,
    select_group_by_property_options: slice_state =>
      slice_state.group_by_properties,
    select_property_filters: slice_state => slice_state.property_filters,
    select_property_filter_keys: slice_state =>
      Object.values(slice_state.property_filters)
        .map(({ property }) => property?.key)
        .filter(is_string)
  }
})

// Action creators are generated for each case reducer function.
export const {
  set_selected_y_cycle_summaries_properties,
  set_selected_x_cycle_summaries_property,
  set_chart_ranges,
  set_x_property_range,
  set_y_property_ranges,
  set_selected_group_by_property,
  set_selected_aggregate_by_property,
  set_selected_normalize_by_option,
  set_cycle_summaries,
  set_chart_type,
  set_property_options,
  create_property_filter,
  delete_property_filter,
  delete_all_property_filters,
  set_property_filter_values,
  set_property_filter_property,
  set_property_filter_operator
} = cycle_summaries_chart_slice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  select_selected_y_cycle_summaries_properties,
  select_selected_x_cycle_summaries_property,
  select_selected_group_by_property,
  select_selected_aggregate_by_property,
  select_selected_normalize_by_option,
  select_cycle_summaries,
  select_chart_type,
  select_x_property_range,
  select_y_property_ranges,
  select_plottable_property_options,
  select_normalize_by_options,
  select_group_by_property_options,
  select_property_filters,
  select_property_filter_keys
} = cycle_summaries_chart_slice.selectors

// Memoized selectors derived from slice state
export const select_normalize_by_arg = createSelector(
  select_selected_normalize_by_option,
  normalize_by_option => {
    if (normalize_by_option) {
      return {
        value: normalize_by_option.key,
        normalize_type: normalize_by_option.type
      }
    }
  }
)

// Memoized selectors derived from slice state
export const select_filters_for_query = createSelector(
  [
    select_selected_x_cycle_summaries_property,
    // select_x_property_range,
    select_property_filters
  ],
  (
    selected_x_observation_property,
    // x_property_range,
    property_filters
  ) => {
    const filters: Filter[] = []

    // if (x_property_range) {
    //   filters.push({
    //     key: selected_x_observation_property.key,
    //     value: x_property_range,
    //     filter_type: Filter_Type.Range
    //   })
    // }

    Object.values(property_filters).forEach(property_filter => {
      if (property_filter.property) {
        if (Array.isArray(property_filter.values)) {
          if (property_filter.values.length !== 0) {
            filters.push({
              key: property_filter.property.key,
              value: property_filter.values.map(value => value.key),
              filter_type: property_filter.filter_type
            })
          }
        } else if (typeof property_filter.values === 'number') {
          filters.push({
            key: property_filter.property.key,
            value: property_filter.values,
            filter_type: property_filter.filter_type
          })
        }
      }
    })

    return filters
  }
)

const select_x_axis = createSelector(
  [select_cycle_summaries, select_x_property_range],
  (cycle_summaries, x_property_range) => {
    const x_property = cycle_summaries?.x_property

    return {
      key: x_property?.key,
      label: format_property_label(x_property?.label, x_property?.units),
      min: x_property_range[0] === null ? undefined : x_property_range[0],
      max: x_property_range[1] === null ? undefined : x_property_range[1]
    }
  }
)

export const select_y_axes = createSelector(
  select_cycle_summaries,
  cycle_summaries => {
    const normalize_label = format_property_label(
      cycle_summaries?.normalize_by_property?.label,
      cycle_summaries?.normalize_by_property?.units
    )

    return (
      cycle_summaries?.y_properties.reduce(
        (
          acc: Record<
            string,
            {
              label: string
              marker: MarkerShapeOptions
              dash_array: number
            }
          >,
          y_property,
          i
        ) => {
          const y_property_label = format_property_label(
            y_property.label,
            y_property.units
          )
          acc[y_property.key] = {
            label: normalize_label
              ? `${y_property_label} / ${normalize_label}`
              : y_property_label,
            marker: VALID_MARKER_SHAPES[i % VALID_MARKER_SHAPES.length],
            dash_array: i * 2
          }
          return acc
        },
        {}
      ) || {}
    )
  }
)

export const select_series_groups = createSelector(
  select_cycle_summaries,
  cycle_summaries => {
    const series_groups: Record<string, { color: string; label: string }> = {}

    cycle_summaries?.summary_groups.forEach(({ group_id }, i) => {
      const color = CHART_COLORS[i % CHART_COLORS.length]
      series_groups[group_id] = { color, label: group_id }
    })

    return series_groups
  }
)

export const select_series_groups_aggregate_by_none = createSelector(
  [
    select_cycle_summaries,
    select_selected_group_by_property,
    select_selected_aggregate_by_property
  ],
  (
    cycle_summaries,
    selected_group_by_property,
    selected_aggregate_by_property
  ) => {
    /**
     * Analogous to select_series_groups but for Aggregation By None
     *
     * @remarks
     * Built so series data is preserved when there are multiple series in a single group
     * Used to build the color mappings for both individual series and groups in the legend
     *
     * @returns
     * series_groups: Record set of each group extracted from series data for building chart legend component
     * series: Record set of each individual series extracted from series data for building chart configuration
     */

    const series_groups: Record<string, { color: string; label: string }> = {}
    const series: Record<string, { color: string }> = {}

    const is_aggregate_by_none =
      selected_group_by_property != null &&
      selected_aggregate_by_property === AGGREGATION_TYPE_NONE

    if (!is_aggregate_by_none) {
      return {
        series,
        series_groups
      }
    }

    let i = 0
    let aggregate_groups = new Set()

    // Confusing verbiage here, group_id corresponds to an individual series returned from the graph
    // aggregate_by_none_group is the group that series belongs to when Aggregate By None option is selected
    cycle_summaries?.summary_groups.forEach(
      ({ group_id, aggregate_by_none_group }) => {
        if (!aggregate_groups.has(aggregate_by_none_group)) {
          const color = CHART_COLORS[i++ % CHART_COLORS.length]

          aggregate_groups.add(aggregate_by_none_group)
          series_groups[aggregate_by_none_group] = {
            color,
            label: aggregate_by_none_group
          }
        }

        series[group_id] = {
          color: series_groups[aggregate_by_none_group].color
        }
      }
    )

    return {
      series,
      series_groups
    }
  }
)

const select_series_by_y_property = createSelector(
  [select_cycle_summaries],
  cycle_summaries => {
    const series_by_y_property: Record<
      string,
      {
        group: string
        name: string
        data: { x: number; y: number | null; range?: [number, number] }[]
      }[]
    > = {}

    if (!cycle_summaries) return series_by_y_property

    const { summary_groups, y_properties, x_property, normalize_by_property } =
      cycle_summaries

    const normalize_label = format_property_label(
      normalize_by_property?.label,
      normalize_by_property?.units
    )

    summary_groups?.forEach(({ group_id, cycle_summary_data }) => {
      y_properties.forEach(y_property => {
        const y_property_label = format_property_label(
          y_property.label,
          y_property.units
        )

        const y_label = normalize_by_property
          ? `${y_property_label} / ${normalize_label}`
          : y_property_label

        const name = `${group_id} ${y_label}`

        const data: {
          x: number
          y: number | null
          range?: [number, number]
        }[] = []

        cycle_summary_data.forEach(cycle => {
          const x_value = cycle.filter(
            property => property.key === x_property.key
          )[0].value as number | null

          if (x_value === null) return

          const y_property_data = cycle.filter(
            property => property.key === y_property.key
          )[0]
          const y_value = y_property_data.value as number

          const y_range: [number, number] | undefined =
            y_property_data.standard_deviation === null ||
            y_property_data.standard_deviation === undefined
              ? undefined
              : [
                  y_value - y_property_data.standard_deviation,
                  y_value + y_property_data.standard_deviation
                ]

          data.push({ x: x_value, y: y_value, range: y_range })
        })

        /**
         * TOFIX: Due to a bug in Apex Charts, we need to plot only unique
         * x-values. Once the bug is fixed in Apex we can plot all data with overlapping
         * x-values.
         * https://github.com/apexcharts/apexcharts.js/issues/4560
         */
        const unique_x_value_data = uniqBy(data, 'x')
        if (unique_x_value_data.length === 1) {
          unique_x_value_data[1] = unique_x_value_data[0]
        }

        if (data.length === 1) {
          data[1] = data[0]
        }

        if (series_by_y_property[y_property.key] === undefined) {
          series_by_y_property[y_property.key] = [
            { data: unique_x_value_data, name, group: group_id }
          ]
        } else {
          series_by_y_property[y_property.key].push({
            data: unique_x_value_data,
            name,
            group: group_id
          })
        }
      })
    })
    return series_by_y_property
  }
)

export const select_chart_data = createSelector(
  select_series_by_y_property,
  series_by_y_property => {
    const chart_data: {
      data: {
        x: number
        y: [number, number] | number | null
      }[]
      name: string
      type: 'line' | 'rangeArea'
    }[] = []

    Object.values(series_by_y_property)
      .flat()
      .forEach(series => {
        chart_data.push({
          name: series.name,
          data: series.data.map(({ x, y }) => ({ x, y })),
          type: 'line'
        })

        if (series.data.some(({ range }) => range !== undefined)) {
          chart_data.push({
            name: series.name + ' SD',
            data: series.data.map(({ x, range }) => ({ x, y: range || null })),
            type: 'rangeArea'
          })
        }
      })

    return chart_data
  }
)

const select_is_range_chart = createSelector(
  select_series_by_y_property,
  series_by_y_property => {
    const flat_series = Object.values(series_by_y_property).flat()

    if (!flat_series.length) return false

    return flat_series[0].data.some(data => data.range !== undefined)
  }
)

export const select_apex_chart_options = createSelector(
  [
    select_x_axis,
    select_y_axes,
    select_y_property_ranges,
    select_series_groups,
    select_chart_type,
    select_is_range_chart,
    select_selected_group_by_property,
    select_selected_aggregate_by_property,
    select_series_groups_aggregate_by_none,
    (_, on_axes_range_change: (ranges: OnAxesRangeChange) => void) => ({
      on_axes_range_change
    })
  ],
  (
    x_axis,
    y_axes,
    y_property_ranges,
    series_groups,
    chart_type,
    is_range_chart,
    selected_group_by_property,
    selected_aggregate_by_property,
    series_groups_aggregate_by_none,
    rest
  ): { options: ApexOptions; type: 'line' | 'rangeArea' } => {
    const { on_axes_range_change } = rest

    const apex_x_axis: ApexXAxis = {
      title: { text: x_axis?.label },
      min: x_axis?.min,
      max: x_axis?.max,
      labels: get_apex_axis_labels<AxisLabel>(
        x_axis?.key
      ) as ApexXAxis['labels'],
      type: Absolute_Time_Byterat_Property.includes(
        x_axis?.key as Byterat_Property
      )
        ? 'datetime'
        : 'numeric'
    }

    const apex_y_axes: ApexYAxis[] = []
    const colors: string[] = []
    const stroke_dashes: number[] = []
    const marker_shapes: MarkerShapeOptions[] = []
    const marker_sizes: number[] = []
    const strokes: number[] = []
    const opacities: number[] = []

    const aggregate_by_none =
      selected_group_by_property != null &&
      selected_aggregate_by_property === AGGREGATION_TYPE_NONE

    Object.keys(y_axes).forEach((y_axis_key, y_axis_count) => {
      const y_axis = y_axes[y_axis_key]
      const group_keys = Object.keys(series_groups)
      const default_group_key = group_keys[0]

      group_keys.forEach((group_key, group_count) => {
        // Case 1 - Aggregate By None: Extract color from series_groups_aggregate_by_none
        // Case 2 - All other charts: Extract color from series_groups
        const group =
          aggregate_by_none && series_groups_aggregate_by_none
            ? series_groups_aggregate_by_none.series[group_key]
            : series_groups[group_key]

        colors.push(group.color)
        marker_shapes.push(y_axis.marker)
        marker_sizes.push(MARKER_SHAPE_SIZE[y_axis.marker])
        stroke_dashes.push(y_axis.dash_array)
        strokes.push(DEFAULT_LINE_STROKE_WIDTH)
        opacities.push(1)
        apex_y_axes.push({
          title: { text: y_axis?.label },
          opposite: y_axis_count !== 0,
          show: group_count === 0,
          seriesName: `${default_group_key} ${y_axis.label}`,
          min:
            y_property_ranges?.[y_axis_count]?.[0] ||
            y_property_ranges?.[y_axis_key]?.[0] ||
            undefined,
          max:
            y_property_ranges?.[y_axis_count]?.[1] ||
            y_property_ranges?.[y_axis_key]?.[1] ||
            undefined,
          labels: get_apex_axis_labels<AxisLabel>(
            y_axis_key
          ) as ApexYAxis['labels']
        })

        if (is_range_chart) {
          colors.push(group.color)
          marker_shapes.push(y_axis.marker)
          marker_sizes.push(0)
          stroke_dashes.push(y_axis.dash_array)
          strokes.push(0)
          opacities.push(DEFAULT_AREA_OPACITY)
          apex_y_axes.push({
            title: { text: y_axis?.label },
            opposite: y_axis_count !== 0,
            show: false,
            seriesName: `${default_group_key} ${y_axis.label}`,
            labels: get_apex_axis_labels<AxisLabel>(
              y_axis_key
            ) as ApexYAxis['labels']
          })
        }
      })
    })

    return {
      options: create_chart_options({
        x_axis: apex_x_axis,
        y_axes: apex_y_axes,
        colors,
        marker_shapes,
        marker_sizes,
        dashes: stroke_dashes,
        strokes: strokes,
        opacities: opacities,
        chart_type,
        is_range_chart,
        on_axes_range_change: on_axes_range_change
      }),
      type: is_range_chart ? 'rangeArea' : 'line'
    }
  }
)

export type AxisLabel = (ApexXAxis['labels'] | ApexYAxis['labels']) & {
  formatter: (value: number) => string
}

const get_apex_axis_labels = <T extends AxisLabel>(
  property_key?: string
): T => {
  return {
    hideOverlappingLabels: true,
    showDuplicates: false,
    rotate: 0,
    formatter: Absolute_Time_Byterat_Property.includes(
      property_key as Byterat_Property
    )
      ? undefined
      : Non_Absolute_Time_Byterat_Property.includes(
          property_key as Byterat_Property
        )
      ? function (val: number) {
          return format_axis_tick(val / (1000 * 60 * 60))
        }
      : format_axis_tick
  } as T
}

function is_string(value: string | undefined): value is string {
  return typeof value === 'string'
}
