import { ReactNode } from 'react'

import { Apollo_Provider } from '../../apollo/Apollo_Provider'
import { Auth_Provider } from '../../auth/Auth_Provider'
import { Redux_Provider } from '../../state/redux/Redux_Provider'
import { Theme_Provider } from '../../theme/Theme_Provider'
import './dayjs'

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <Redux_Provider>
      <Auth_Provider>
        <Apollo_Provider>
          <Theme_Provider>{children}</Theme_Provider>
        </Apollo_Provider>
      </Auth_Provider>
    </Redux_Provider>
  )
}
