import { OverflowMenuHorizontal } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { isEqual, omit } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'

import { Button, Popover } from '@/components'

import { use_app_selector } from '../../state/redux/hooks'
import { InsightsChart } from '../__components/InsightsChart'
import {
  select_datasets,
  select_properties,
  select_staged_dataset_table_rows
} from '../home/insights_slice'
import { chartStatesFamily, initialChartState } from '../jotai/charts.atoms'
import { AddChartToLibrary } from './AddChartToLibrary'
import { AddChartToReport } from './AddChartToReport'
import { ApplyChartTemplate } from './ApplyChartTemplate'

export const InsightsChartView = () => {
  // Get all datasets
  const datasets = use_app_selector(select_datasets)
  const datasetColumns = use_app_selector(select_properties)
  const stagedDatasetTableRows = use_app_selector(
    select_staged_dataset_table_rows
  )

  const [popoverOpen, setPopoverOpen] = useState(false)

  const [chartId] = useState(nanoid())
  const chartState = useAtomValue(chartStatesFamily({ id: chartId }))

  const stagedDatasetIds = useMemo(
    () => stagedDatasetTableRows.map(row => row.id),
    [stagedDatasetTableRows]
  )

  // We must also compare the keys of the xAxisProperty and yAxisProperties array, because the keys are the unique identifiers
  // but _initialChartState will not know about most of the properties of the xAxisProperty and yAxisProperties objects, as
  // they are placeholders until the backend returns a full descriptor for the x/y properties (including units, __typename, etc.)
  const isInitialState = useMemo(() => {
    // We need to omit the datasetColumns and datasets from the comparison of knowing if the chart is in its initial state,
    // because the chart state will have the datasetColumns and datasets, but the initial state will not
    const baseState = { ...initialChartState, id: chartId }
    const _initialChartState = omit(baseState, 'datasetColumns', 'datasets')
    const _currentChartState = omit(chartState, 'datasetColumns', 'datasets')

    return isEqual(_initialChartState, _currentChartState)
  }, [chartId, chartState])

  return (
    <>
      <div className='absolute top-4 right-6 flex'>
        <div className='hidden md:flex gap-x-2'>
          <ApplyChartTemplate
            chartId={chartId}
            className='text-xxs lg:text-xs'
          />
          <AddChartToLibrary
            chartState={chartState}
            isInitialState={isInitialState}
            className='text-xxs lg:text-xs'
          />
          <AddChartToReport
            chartId={chartId}
            isInitialState={isInitialState}
            className='text-xxs lg:text-xs'
          />
        </div>
        <div className='flex gap-x-2 md:hidden'>
          <Popover
            content={
              <div className='flex flex-col gap-y-2'>
                <AddChartToReport
                  chartId={chartId}
                  isInitialState={isInitialState}
                  className='border-none shadow-none'
                  variant='filled'
                  color='primary'
                />
                <ApplyChartTemplate
                  chartId={chartId}
                  className='border-none shadow-none'
                  variant='text'
                  color='default'
                />
                <AddChartToLibrary
                  chartState={chartState}
                  isInitialState={isInitialState}
                  className='border-none shadow-none'
                  variant='text'
                  color='default'
                />
              </div>
            }
            onOpenChange={(nextState: boolean) => {
              setPopoverOpen(nextState)
            }}
            open={popoverOpen}
            placement='bottom'
            arrow={{ pointAtCenter: true }}
          >
            <Button shape='circle' className='text-sm'>
              <OverflowMenuHorizontal size={24} />
            </Button>
          </Popover>
        </div>
      </div>
      <InsightsChart
        chartId={chartId}
        datasets={datasets}
        datasetColumns={datasetColumns}
        datasetIds={stagedDatasetIds}
      />
    </>
  )
}
