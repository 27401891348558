import { Close } from '@carbon/icons-react'
import {
  Button,
  ButtonProps,
  IconButton,
  Popover as MuiPopover,
  PopoverProps,
  styled
} from '@mui/material'
import { FC, MouseEvent, ReactNode, useId, useRef, useState } from 'react'

const Styled_Button = styled(Button)`
  height: fit-content;
  margin: auto 0;
`

type Popover_Props = {
  show_close_icon?: boolean
  trigger_contents: ReactNode
  trigger_props?: ButtonProps
  popover_id?: string
  popover_contents: ReactNode
  popover_props?: Omit<PopoverProps, 'open'>
}

export const LegacyPopover: FC<Popover_Props> = ({
  show_close_icon = false,
  trigger_contents,
  trigger_props,
  popover_id,
  popover_contents,
  popover_props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const defaultId = useId()
  const triggerRef = useRef(null)
  const id = popover_id || defaultId

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? id : undefined

  return (
    <div>
      <Styled_Button
        aria-describedby={popoverId}
        onClick={handleClick}
        {...trigger_props}
        ref={triggerRef}
      >
        {trigger_contents}
      </Styled_Button>
      <MuiPopover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...popover_props}
      >
        <Popover_Content_Wrapper>
          {show_close_icon && (
            <Styled_Icon_Button onClick={handleClose}>
              <Close />
            </Styled_Icon_Button>
          )}
          {popover_contents}
        </Popover_Content_Wrapper>
      </MuiPopover>
    </div>
  )
}

const Popover_Content_Wrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  position: 'relative'
}))

const Styled_Icon_Button = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  borderRadius: '100%'
}))
