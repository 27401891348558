import { useMutation } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { cloneDeep } from 'lodash-es'
import { nanoid } from 'nanoid'
import { title } from 'process'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Alert, Button, Input, Popconfirm, notification } from '@/components'
import { Iris_Report_Template } from '@/gql_generated/graphql'
import { cn, omitNullishOrEmptyValues } from '@/utils'

import { SAVE_REPORT_TEMPLATE } from '../queries/reportTemplates'
import { GET_REPORTS, LOAD_REPORT } from '../queries/reports'
import { fullReportAtomFamily } from '../store/report.molecule'
import { FullReportAtom } from '../types'

type ReportTemplateMetadata = Pick<
  Iris_Report_Template,
  'title' | 'description'
>

type SaveReportTemplateProps = {
  reportId: string
  isDirty: boolean
  isSchemaOutdated: boolean
}

function makeDefaultTempalteTitle(title: string) {
  return `[Template] ${title}`
}

export const SaveReportTemplate = (props: SaveReportTemplateProps) => {
  const { reportId, isDirty, isSchemaOutdated } = props

  const [api, contextHolder] = notification.useNotification()

  const [saveReportTemplate, { loading: savingReportTemplate }] =
    useMutation(SAVE_REPORT_TEMPLATE)

  const fullReport = useAtomValue(fullReportAtomFamily(reportId))
  const saveableReport = omitNullishOrEmptyValues(fullReport) as FullReportAtom

  const [reportTemplateMetadata, setReportTemplateMetadata] =
    useState<ReportTemplateMetadata>({
      title: makeDefaultTempalteTitle(saveableReport.title)
    })

  const handleSaveReportTemplate = async () => {
    // Prevent saving the report if it's already saving or if the full report is null
    if (savingReportTemplate || saveableReport == null) return

    // Clone the full report to avoid mutating the original
    const reportToSave = cloneDeep(saveableReport)

    // We will not store the datasetIds in the template
    reportToSave.datasetIds = []

    // We need to set a new id for each visualization being saved, so we have no conflicts
    reportToSave.visualizations.forEach(visualization => {
      // Set a new id for the visualization being saved
      visualization.id = nanoid()
    })

    // Set a new id for the report
    reportToSave.id = uuidv4()
    // Set the new title
    reportToSave.title = reportTemplateMetadata.title || `[Template] ${title}`
    try {
      await saveReportTemplate({
        variables: {
          id: reportToSave.id,
          report_template: JSON.stringify(reportToSave),
          title: reportToSave.title,
          workspace_id: reportToSave.workspaceId,
          organization_id: reportToSave.organizationId,
          schema_version: reportToSave.schemaVersion
        },
        refetchQueries: [GET_REPORTS, LOAD_REPORT],
        awaitRefetchQueries: true
      })
      api.success({
        message: 'Report saved as template'
        // description: (
        //   <Link to={`../te/${reportToSave.id}`}>
        //     <div className='font-mono'>{reportToSave.title}</div>
        //   </Link>
        // )
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      {contextHolder}

      <Popconfirm
        title='Save as report template?'
        placement='topRight'
        overlayInnerStyle={{ width: '400px' }}
        overlayClassName='[&_.ant-popconfirm-message-text]:flex-1 [&_.ant-popconfirm-description]:flex-1'
        description={
          <div className='py-2 flex flex-col gap-2'>
            <div>
              This will save the report as a template for future use.
              <div className='text-xs flex gap-x-1 my-2'>
                <div className='font-bold'>Note:</div>
                <div>
                  Stored templates
                  <span className='font-medium italic mx-1'>do not</span>
                  store or preselect datasets.
                </div>
              </div>
            </div>
            <label className='w-full flex flex-col gap-1'>
              <span className='font-medium'>Title:</span>
              <div className='flex-1'>
                <Input
                  autoFocus
                  onChange={e =>
                    setReportTemplateMetadata({ title: e.target.value })
                  }
                  onBlur={e => {
                    setReportTemplateMetadata({
                      title: e.target.value || `[Template] ${title}`
                    })
                  }}
                  value={reportTemplateMetadata.title}
                />
              </div>
            </label>
            <label className='w-full flex flex-col gap-1'>
              <div className='font-medium'>Description:</div>
              <Input.TextArea
                className='text-[13px]'
                onChange={e =>
                  setReportTemplateMetadata(prev => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                onBlur={e => {
                  setReportTemplateMetadata(prev => ({
                    ...prev,
                    description: e.target.value || ''
                  }))
                }}
                placeholder='Enter a brief description of the report template'
                value={reportTemplateMetadata.description ?? ''}
                style={{
                  minHeight: `${
                    Math.max(
                      2,
                      reportTemplateMetadata.description?.split('\n').length ??
                        0
                    ) * 2.25
                  }em`
                }}
              />
            </label>
            {isDirty && (
              <Alert
                showIcon
                type='info'
                message={
                  <div className='font-semibold text-sm'>Unsaved changes</div>
                }
                description='This report has unsaved changes which will be saved in the template.'
                className={cn(
                  'text-xs p-3 pt-2 my-2',
                  '[&_.ant-alert-icon]:h-5 [&_.ant-alert-icon]:text-base [&_.ant-alert-icon]:mr-2'
                )}
              />
            )}
          </div>
        }
        onConfirm={handleSaveReportTemplate}
      >
        <Button className='text-xs font-medium' disabled={isSchemaOutdated}>
          Save as template
        </Button>
      </Popconfirm>
    </div>
  )
}
