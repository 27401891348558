import { cloneDeep, sortBy } from 'lodash-es'

import { CHART_COLORS } from '../../charts'
import { InsightsChartTypes } from '../../charts/types'
import { InsightsChartState } from '../charts.atoms'
import { isAggregateByNone } from './shared'
import { ApexChartSeries, ApexChartSeriesLegendItem } from './types'

export const _getSummarySeries = ({
  rawData,
  xAxisProperty,
  yAxisProperties,
  groupByProperty,
  aggregateByProperty
}: Pick<
  InsightsChartState,
  | 'rawData'
  | 'xAxisProperty'
  | 'yAxisProperties'
  | 'groupByProperty'
  | 'aggregateByProperty'
>) => {
  const colors = cloneDeep(CHART_COLORS)
  const dashArray: number[] = []
  const series: ApexChartSeries[] = []
  const _yPropertySeriesNames: { [yPropertyIndex: number]: string[] } = {}

  // handle empty states
  if (rawData.length === 0 || !rawData[0].cycle_summary_data) {
    yAxisProperties.forEach(yProperty => {
      series.push({
        name: yProperty.label,
        id: yProperty.key,
        data: []
      })
    })
  }

  const _validYProperties = yAxisProperties.filter(({ key }) => !!key)

  let colorIndex = 0
  let seriesLegendItems: ApexChartSeriesLegendItem[] = []
  const _isAggregateByNone = isAggregateByNone(
    InsightsChartTypes.CYCLE_METRICS,
    groupByProperty,
    aggregateByProperty
  )

  rawData.forEach(dataset => {
    if (Array.isArray(dataset.cycle_summary_data)) {
      _validYProperties
        .filter(({ key }) => !!key)
        .forEach((yProperty, yPropertyIndex) => {
          const data: {
            x: number
            y: number | null
            range?: [number, number]
            annotations?: string[]
          }[] = []

          dataset.cycle_summary_data.forEach((cycle: any) => {
            const x_value = cycle?.find(
              (property: any) => property.key === xAxisProperty.key
            )?.value as number | null

            if (x_value === null) return

            const y_property_data = cycle?.find(
              (property: any) => property.key === yProperty.key
            )
            const y_value = y_property_data?.value as number

            const y_range: [number, number] | undefined =
              y_property_data?.standard_deviation === null ||
              y_property_data?.standard_deviation === undefined
                ? undefined
                : [
                    y_value - y_property_data?.standard_deviation,
                    y_value + y_property_data?.standard_deviation
                  ]

            if (x_value !== null && y_value !== null) {
              data.push({
                x: x_value,
                y: y_value,
                range: y_range,
                annotations: y_property_data?.annotations
              })
            }
          })

          // If no data, return, because we don't want empty series being added to the chart
          // and it ends up skipping colors etc
          if (data.length === 0) return

          if (yPropertyIndex !== 0) {
            dashArray.push(5)
          } else {
            dashArray.push(0)
          }

          // Get series names, colors and legend names depending on aggregation type
          if (_isAggregateByNone) {
            // Create a new series for each aggregation group
            // aggregate_by_none_group is the grouped property value from the backend used as the new series name
            const seriesGroupName = dataset.aggregate_by_none_group
            const existingSeriesGroup = seriesLegendItems.find(
              item => item.name === seriesGroupName
            )

            if (!existingSeriesGroup) {
              // Only add a new series group if it doesn't already exist
              const color =
                CHART_COLORS[seriesLegendItems.length % CHART_COLORS.length]
              seriesLegendItems.push({
                name: seriesGroupName,
                color
              })

              colors[colorIndex] = color

              // Save series name for y property
              _yPropertySeriesNames[yPropertyIndex] = [
                ...(_yPropertySeriesNames[yPropertyIndex] || []),
                seriesGroupName
              ]
            } else {
              const color = existingSeriesGroup.color
              colors[colorIndex] = color
            }

            series.push({
              name: seriesGroupName,
              id: seriesGroupName,
              data: sortBy(data, 'x')
            })
          } else {
            // Add a new series and series legend item for each rawData group
            if (_validYProperties.length > 1) {
              colors[colorIndex] = CHART_COLORS[Math.floor(colorIndex / 2)]
            }

            // Create a new series for each rawData group
            const _seriesName = `${dataset.group_id} ${
              yProperty.labelOverride ?? yProperty.label
            }`
            const _seriesLegendName = `${dataset.group_id}`
            seriesLegendItems.push({
              name: _seriesLegendName,
              color: colors[colorIndex]
            })
            series.push({
              name: _seriesName,
              id: dataset.group_id,
              data: sortBy(data, 'x')
            })

            // Save series name for y property
            _yPropertySeriesNames[yPropertyIndex] = [
              ...(_yPropertySeriesNames[yPropertyIndex] || []),
              _seriesName
            ]
          }

          // Increment color index
          colorIndex++
        })
    }
  })

  return {
    colors,
    dashArray,
    series,
    seriesLegendItems,
    yPropertySeriesNames: _yPropertySeriesNames
  }
}
