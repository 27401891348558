import { Close } from '@carbon/icons-react'
import { Button, SelectProps, Space } from 'antd'
import { FC, FocusEvent } from 'react'

import { Select } from '@/components'
import { emptyObject } from '@/constants'
import { Filter_Type } from '@/gql_generated/graphql'
import { cn, mapListKeysToValues } from '@/utils'

import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS
} from '../../../../charts'
import Numeric_Filter from '../../../../controls/filters/Numeric_Filter'

interface FilterPairProps {
  leftInputProps?: SelectProps
  centerOperatorProps?: SelectProps
  rightInputProps?: SelectProps
  filterType?: Filter_Type
  onClickX?: () => void
}

export const FilterPair: FC<FilterPairProps> = props => {
  const {
    leftInputProps = emptyObject as SelectProps,
    centerOperatorProps = emptyObject as SelectProps,
    rightInputProps = emptyObject as SelectProps,
    filterType = Filter_Type.IsAnyOf,
    onClickX
  } = props

  const isNumeric = NUMERIC_PROPERTY_OPERATOR_OPTIONS.find(
    operator => operator.key === filterType
  )
  const operatorOptions = isNumeric
    ? mapListKeysToValues(NUMERIC_PROPERTY_OPERATOR_OPTIONS)
    : mapListKeysToValues(KEYWORD_PROPERTY_OPERATOR_OPTIONS)

  const hasOnClickX = onClickX != null

  return (
    <Space.Compact className='w-full'>
      <Select
        {...leftInputProps}
        showSearch
        className={cn('w-5/12', leftInputProps.className)}
      />
      <Select
        {...centerOperatorProps}
        disabled={!isNumeric}
        allowClear={false}
        value={operatorOptions.find(({ value }) => value === filterType)}
        options={operatorOptions}
        className={cn('w-2/12', centerOperatorProps.className)}
      />
      <div className='w-5/12'>
        {isNumeric ? (
          <Numeric_Filter
            onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
              rightInputProps?.onChange?.(event.target.value, {})
            }}
            key={leftInputProps?.value?.key}
            value={rightInputProps?.value}
            containerSx={{
              flex: 1,
              display: 'flex',
              '& .MuiFormControl-root': {
                flex: 1,
                '&:hover,&:focus,&:active,&:focus-within': {
                  '& .MuiInputBase-root': {
                    marginRight: 0,
                    borderColor: '#4096ff'
                  }
                }
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: hasOnClickX ? 0 : undefined,
                borderTopLeftRadius: !hasOnClickX ? 0 : undefined,
                borderBottomLeftRadius: !hasOnClickX ? 0 : undefined,
                fontSize: '0.75rem',
                marginRight: '-1px',
                height: 32,
                fontWeight: 400
              },
              '.MuiInputBase-input': { fontSize: '0.75rem' }
            }}
          />
        ) : (
          <Select
            {...rightInputProps}
            showSearch
            className={cn('-mr-[2px]', rightInputProps.className)}
          />
        )}
      </div>
      {hasOnClickX && (
        <Button className='bg-gray-50 text-gray-400 p-1.5' onClick={onClickX}>
          <Close size={16} />
        </Button>
      )}
    </Space.Compact>
  )
}
