import { Grid2, Grid2Props, styled } from '@mui/material'
import { ReactNode } from 'react'

interface Filter_Grid_Props extends Grid2Props {
  children: ReactNode
}

export const Filter_Grid = ({ children, ...rest }: Filter_Grid_Props) => {
  return (
    <Styled_Grid
      container
      justifyContent={'space-between'}
      alignItems={'top'}
      columnGap={2}
      padding={0}
      size='grow'
      {...rest}
    >
      {children}
    </Styled_Grid>
  )
}

export const Filter_Selectors_Grid = ({
  children,
  ...rest
}: Filter_Grid_Props) => {
  return (
    <Styled_Grid container gap={1} padding={0} flexWrap={'nowrap'} {...rest}>
      {children}
    </Styled_Grid>
  )
}

export const Filter_Grid_Item = ({ children, ...rest }: Filter_Grid_Props) => {
  return (
    <Styled_Grid_Item padding={0} flexWrap={'nowrap'} {...rest}>
      {children}
    </Styled_Grid_Item>
  )
}

const Styled_Grid = styled(Grid2)`
  fieldset {
    border-radius: 1rem;
  }
`

const Styled_Grid_Item = styled(Styled_Grid)`
  height: fit-content;
  min-height: 40px;
`
