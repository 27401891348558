import { OverflowMenuHorizontal } from '@carbon/icons-react'
import { GridApiPro } from '@mui/x-data-grid-pro'
import { useRef, useState } from 'react'

import { Button, Popover } from '@/components'

import { InsightsPanelTableColumns } from './InsightsPanelTableColumns'
import { InsightsPanelTableGroupBy } from './InsightsPanelTableGroupBy'
import { InsightsTableFilterPanelRoot } from './InsightsTableFilterPanelRoot'
import { Menu_Nav_Provider, Menu_Panel } from './menus'
import { Menu_Panel_Id } from './menus/constants'

type InsightsTableFiltersProps = {
  tableId: string
  tableRef?: React.MutableRefObject<GridApiPro>
}

export const InsightsTableFilters = (props: InsightsTableFiltersProps) => {
  const { tableId, tableRef } = props
  // Popover state
  const [popoverOpen, setPopoverOpen] = useState(false)
  const popoverRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <Popover
        content={
          <div ref={popoverRef}>
            <Menu_Nav_Provider>
              <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
                <InsightsTableFilterPanelRoot
                  tableId={tableId}
                  tableTitle='Table configuration'
                  tableRef={tableRef}
                />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.DATASET_COLUMNS}>
                <InsightsPanelTableColumns tableId={tableId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.GROUP_BY}>
                <InsightsPanelTableGroupBy tableId={tableId} />
              </Menu_Panel>
            </Menu_Nav_Provider>
          </div>
        }
        trigger={['click']}
        placement='bottomRight'
        overlayInnerStyle={{ padding: '0px 8px' }}
        open={popoverOpen}
      >
        <Button
          shape='circle'
          onClick={() => {
            setPopoverOpen(!popoverOpen)
          }}
          className='-mb-2'
        >
          <OverflowMenuHorizontal size={24} />
        </Button>
      </Popover>

      {popoverOpen && (
        <div
          className='fixed top-0 left-0 z-[1000] w-full h-full'
          onClick={() => {
            setPopoverOpen(false)
          }}
        />
      )}
    </>
  )
}
