import { cloneDeep, isEqual, omit } from 'lodash-es'

import {
  ChartConfig,
  FullReportAtom,
  TableConfig,
  isChart,
  isTable
} from '../../types'

function calculateIsChartConfigDirty(
  baseConfig: ChartConfig,
  transientConfig: ChartConfig
) {
  if (baseConfig == null || transientConfig == null) return false

  const baseDirtyCheck = !isEqual(baseConfig, transientConfig)

  // If the base config is not, we don't need to do a deep comparison
  if (!baseDirtyCheck) return false

  // Clone the configs to avoid mutating the original
  const tempConfig = cloneDeep(transientConfig)

  const {
    xAxisProperty,
    yAxisProperties,
    cycleFilters,
    aggregateByProperty,
    groupByProperty
  } = baseConfig

  const {
    xAxisProperty: tempXAxisProperty,
    yAxisProperties: tempYAxisProperties,
    cycleFilters: tempCycleFilters,
    aggregateByProperty: tempAggregateByProperty,
    groupByProperty: tempGroupByProperty
  } = tempConfig

  // If the base config is dirty, we need to do a deep comparison
  // and overwrite the temp config with the base config if the keys are equal
  // Such that we can then compare the temp config with the base config,
  // and determine if the base config is *actually* dirty
  if (isEqual(xAxisProperty.key, tempXAxisProperty.key)) {
    tempConfig.xAxisProperty = xAxisProperty
  }

  yAxisProperties.forEach((yAxis, i) => {
    if (isEqual(yAxis.key, tempYAxisProperties[i].key)) {
      tempYAxisProperties[i] = yAxis
    }
  })

  if (isEqual(cycleFilters, tempCycleFilters)) {
    tempConfig.cycleFilters = cycleFilters
  }

  if (isEqual(aggregateByProperty.key, tempAggregateByProperty.key)) {
    tempConfig.aggregateByProperty = aggregateByProperty
  }

  if (isEqual(groupByProperty?.key, tempGroupByProperty?.key)) {
    tempConfig.groupByProperty = groupByProperty
  }

  return !isEqual(baseConfig, tempConfig)
}
const isTableConfigDirty = (
  baseConfig: TableConfig,
  transientConfig: TableConfig
) => {
  if (baseConfig == null || transientConfig == null) return false

  const baseDirtyCheck = !isEqual(baseConfig, transientConfig)

  return baseDirtyCheck
}
export function calculateIsReportDirty(
  baseReport: FullReportAtom,
  transientReport: FullReportAtom
) {
  if (baseReport == null || transientReport == null) return false

  const baseDirtyCheck =
    !isEqual(
      baseReport.datasetIds?.sort(),
      transientReport.datasetIds?.sort()
    ) &&
    !isEqual(
      omit(baseReport, 'visualizations', 'datasetIds'),
      omit(transientReport, 'visualizations', 'datasetIds')
    )

  const baseChartConfigs = baseReport.visualizations
    .map(c => c.config)
    .filter(isChart)

  const transientChartConfigs = transientReport.visualizations
    .map(c => c.config)
    .filter(isChart)

  const baseTableConfigs = baseReport.visualizations
    .map(c => c.config)
    .filter(isTable)

  const transientTableConfigs = transientReport.visualizations
    .map(c => c.config)
    .filter(isTable)

  const chartDirtyCheck = baseChartConfigs.some((baseConfig, i) =>
    calculateIsChartConfigDirty(baseConfig, transientChartConfigs[i])
  )

  const tableDirtyCheck = baseTableConfigs.some((baseConfig, i) =>
    isTableConfigDirty(baseConfig, transientTableConfigs[i])
  )

  return baseDirtyCheck || chartDirtyCheck || tableDirtyCheck
}
