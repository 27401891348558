import { PropsWithChildren, createContext, useContext, useState } from 'react'

export const Menu_Nav_Base = {
  ROOT: 'root'
} as const

type Menu_Nav_Base_Type = typeof Menu_Nav_Base
type Menu_Nav_Panel_Name = keyof Menu_Nav_Base_Type | string

type Menu_Nav_Context_Type = {
  create_set_selected_panel_id: (panel_id: string) => () => void
  return_to_root: () => void
  selected_panel_id: Menu_Nav_Panel_Name
  set_selected_panel_id: (id: Menu_Nav_Panel_Name) => void
}

const default_value: Menu_Nav_Context_Type = {
  create_set_selected_panel_id: () => () => {},
  return_to_root: () => {},
  selected_panel_id: Menu_Nav_Base.ROOT,
  set_selected_panel_id: () => {}
}

const MenuNavContext = createContext(default_value)

export const use_menu_nav = () => {
  // Ignoring rules of hooks as the linter doesn't recognize use_ as
  // a valid hook prefix.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(MenuNavContext)
  if (!context) {
    throw new Error('useMenuNav must be used within a MenuNavProvider')
  }
  return context
}

type Menu_Nav_Provider_Props = PropsWithChildren
export const Menu_Nav_Provider = (props: Menu_Nav_Provider_Props) => {
  const { children } = props
  const [selected_panel_id, set_selected_panel_id] =
    useState<Menu_Nav_Panel_Name>(Menu_Nav_Base.ROOT)

  const create_set_selected_panel_id = (panel_id: string) => () => {
    set_selected_panel_id(panel_id)
  }

  const return_to_root = create_set_selected_panel_id(Menu_Nav_Base.ROOT)

  return (
    <MenuNavContext.Provider
      value={{
        create_set_selected_panel_id,
        return_to_root,
        selected_panel_id,
        set_selected_panel_id
      }}
    >
      {children}
    </MenuNavContext.Provider>
  )
}
