/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { use_app_dispatch } from '../../state/redux/hooks'
import { reset_insights } from '../../state/redux/store'

export const use_workspace_or_organization_change_listener = () => {
  const { organization_key, workspace_key } = useParams()
  const dispatch = use_app_dispatch()

  useEffect(() => {
    dispatch(reset_insights())
  }, [organization_key, workspace_key, dispatch])
}
