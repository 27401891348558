import { TabsProps } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { BaseLayout, Tabs, Typography } from '@/components'

import {
  ChartTemplateGrid,
  ReportTemplateGrid,
  TableTemplateGrid
} from './components'

const { Title } = Typography

const items: TabsProps['items'] = [
  {
    key: 'charts',
    label: (
      <Title level={5} className='px-2 py-0 !mb-0'>
        Charts
      </Title>
    ),
    children: <ChartTemplateGrid />
  },
  {
    key: 'tables',
    label: (
      <Title level={5} className='px-2 py-0 !mb-0'>
        Tables
      </Title>
    ),
    children: <TableTemplateGrid />
  },
  {
    key: 'reports',
    label: (
      <Title level={5} className='px-2 py-0 !mb-0'>
        Reports
      </Title>
    ),
    children: <ReportTemplateGrid />
  }
]

export const TemplateLibrary = () => {
  const { tabId } = useParams()
  const [activeTab, setActiveTab] = useState(tabId)
  const navigate = useNavigate()

  useEffect(() => {
    if (tabId) {
      setActiveTab(tabId)
    } else {
      navigate(`./${items[0].key}`)
    }
  }, [tabId, navigate])

  const onTabChange = (key: string) => {
    navigate(`../${key}`)
  }

  return (
    <BaseLayout className='py-4 flex flex-col gap-y-2'>
      <Title level={3} className='!mb-0'>
        Template Library
      </Title>
      <Tabs
        defaultActiveKey={items[0].key}
        items={items}
        destroyInactiveTabPane
        onChange={onTabChange}
        activeKey={activeTab}
      />
    </BaseLayout>
  )
}
