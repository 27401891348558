import { gql } from '../../../../gql_generated'

export const GET_CYCLE_OBSERVATION_FILTER_CONFIGS = gql(`
  query get_observation_filter_configs(
    $organization_id: String!
    $workspace_ids: [String!]!
    $dataset_ids: [String!]!
    $dataset_filters: [Filter!]!
    $additional_filter_keys: [String!]!
  ) {
    get_observation_filter_configs(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      dataset_ids: $dataset_ids
      dataset_filters: $dataset_filters
      additional_filter_keys: $additional_filter_keys
    ) {
      filter_property
      filter_type
      options{
        label
        key
        type
        units
      }
    }
  }
`)
