export const JupyterBridgeId = 'jupyter-iframe-bridge'

export enum JupyterBridgeMessageType {
  Ready = 'ready',
  Ack = 'ack',
  Token = 'token',
  Error = 'error'
}

export type JupyterBridgeMessageEvent = MessageEvent<{
  source: string
  type: JupyterBridgeMessageType | string
  payload?: string
}>
