import { Grid2, Skeleton, styled } from '@mui/material'

import { Search_Input } from '../../components/Search_Input/Search_Input'
import { use_app_dispatch, use_app_selector } from '../../state/redux/hooks'
import { Styled_ChevronRight } from '../controls/menus/shared_styled_components'
import {
  select_query_string,
  select_staged_dataset_table_rows,
  set_query_string
} from '../home/insights_slice'
import { Insights_Datasets_Table_Filter_Panel } from './Insights_Datasets_Table_Filter_Panel'

type Insights_Datasets_Table_Header_Props = {
  row_count: number
  loading?: boolean
}
export function Insights_Datasets_Table_Header(
  props: Insights_Datasets_Table_Header_Props
) {
  const { row_count, loading } = props
  const dispatch = use_app_dispatch()

  const query_string = use_app_selector(select_query_string)
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  const on_search = (new_value: string) => {
    dispatch(set_query_string(new_value))
  }

  const selected_row_count = staged_dataset_table_rows.length

  return (
    <>
      <Grid2 container>
        <Grid2 size={{ xs: 12, sm: 3 }}>
          <Search_Input default_value={query_string} on_change={on_search} />
        </Grid2>
        <Filter_Panel_Wrapper size={{ xs: 12, sm: 'grow' }}>
          <Insights_Datasets_Table_Filter_Panel />
        </Filter_Panel_Wrapper>
      </Grid2>
      <Data_Info size={12}>
        {loading ? <Skeleton width={150} /> : `${row_count} matching datasets`}
        {selected_row_count > 0 && (
          <>
            <Styled_ChevronRight />
            {selected_row_count} selected
          </>
        )}
      </Data_Info>
    </>
  )
}

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0
  }
}))

const Data_Info = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  fontSize: theme.typography.xs,
  color: theme.palette.gray[500]
}))
