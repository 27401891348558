import { DEFAULT_ICON_COLOR } from './constants'

interface Line_Icon_Props {
  dash_array: number
  color?: string
}

export const Line_Icon = ({
  dash_array,
  color = DEFAULT_ICON_COLOR
}: Line_Icon_Props) => {
  return (
    <svg width='30' height='10' viewBox='0 0 30 10'>
      <line
        x1='0'
        y1='5'
        x2='30'
        y2='5'
        stroke={color}
        strokeWidth='2'
        strokeDasharray={dash_array}
      />
    </svg>
  )
}
