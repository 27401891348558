import { Providers } from './providers/Providers'
import { Navigation } from '../navigation/Navigation'

export const Base = () => {
  return (
    <Providers>
      <Navigation />
    </Providers>
  )
}
