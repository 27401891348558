import { CHART_SIG_FIGS } from './chart_options'

/**
 * Adjust given chart bounds to ensure the difference between the min and max values is rounded up to the nearest ten.
 */
export const adjusted_chart_bounds = (min: number, max: number) => {
  let range = max - min
  let extra = 10 - (range % 10)
  if (extra === 10) extra = 0
  const adjusted_max = max + extra

  return { min, adjusted_max }
}

/**
 * Creates an empty map of arrays of a given type, with keys from the given properties.
 */
export const properties_to_empty_array_map = <T>(
  properties: { key: string }[]
) => {
  return properties.reduce((acc: Record<string, T[]>, { key }) => {
    acc[key] = []
    return acc
  }, {})
}

/**
 * Returns a formatted string as 'label [units]' or 'label' if units is not defined.
 */
export const format_property_label = (
  label?: string,
  units?: string | null
) => {
  const formatted_label = label?.trim() || ''
  const formatted_units = units ? ` [${units}]` : ''
  if (formatted_label.endsWith(formatted_units)) {
    return formatted_label
  }
  return `${formatted_label}${formatted_units}`
}

export const format_axis_tick = (value: number) => {
  if (value === undefined || value === null || !value.toFixed) {
    return value
  }

  return `${parseFloat(value.toFixed(CHART_SIG_FIGS))}`
}

export const format_key_to_label = (key: string) => {
  return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}
