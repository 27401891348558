import { useQuery } from '@apollo/client'
import { Checkbox, Grid2, LinearProgress, lighten, styled } from '@mui/material'
import {
  COMPACT_DENSITY_FACTOR,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridSlots,
  GridSortModel,
  GridValidRowModel
} from '@mui/x-data-grid'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  GET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
  GET_DATASETS_FOR_INSIGHTS_DASHBOARD
} from '../../datasets/queries/get_datasets_for_insights_dashboard'
import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { use_app_dispatch, use_app_selector } from '../../state/redux/hooks'
import { customTheme } from '../../theme/Theme_Provider'
import {
  Dataset,
  Dataset_Grid_Row,
  add_staged_dataset_table_rows,
  remove_staged_dataset_table_rows,
  select_grid_column_visibility_model,
  select_grid_columns,
  select_grid_rows,
  select_pagination,
  select_properties_filter,
  select_properties_sort,
  select_query_string,
  select_staged_dataset_table_rows,
  set_datasets,
  set_pagination,
  set_properties,
  set_properties_filter_from_grid_filter_model,
  set_properties_sort_from_grid_sort_model
} from '../home/insights_slice'
import { Insights_Datasets_Table_Filter_Panel } from './Insights_Datasets_Table_Filter_Panel'
import { Insights_Datasets_Table_Header } from './Insights_Datasets_Table_Header'
import { Select_Column_Header } from './column_headers/Select_Column_Header'

const GRID_ROW_HEIGHT = 36
const COLUMN_HEADER_HEIGHT = 80
const PINNED_ROW_BUFFER = 450
const PINNED_ROWS_HIGHLIGHT = customTheme.palette.primary.light

const PAGE_SIZE_OPTIONS = [25, 50, 100]

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    row_count: number
  }
}

function Dataset_Datagrid() {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )
  const pagination = use_app_selector(select_pagination)
  const query_string = use_app_selector(select_query_string)
  const properties_sort = use_app_selector(select_properties_sort)
  const properties_filter = use_app_selector(select_properties_filter)
  const dataset_columns = use_app_selector(select_grid_columns)
  const dataset_rows = use_app_selector(select_grid_rows)
  const column_visibility_model = use_app_selector(
    select_grid_column_visibility_model
  )

  const handle_stage_datasets = useCallback(
    (rows: Dataset_Grid_Row[]) => {
      dispatch(add_staged_dataset_table_rows(rows))
    },
    [dispatch]
  )
  const handle_unstage_dataset = useCallback(
    (row: any) => {
      dispatch(remove_staged_dataset_table_rows(row))
    },
    [dispatch]
  )
  function handle_pagination_change(pagination_model: GridPaginationModel) {
    const size = pagination_model.pageSize
    const from = pagination_model.page * size
    dispatch(set_pagination({ size, from }))
  }
  function handle_sort_model_change(sort_model: GridSortModel) {
    dispatch(set_properties_sort_from_grid_sort_model(sort_model))
  }
  function handle_filter_model_change(filter_model: GridFilterModel) {
    dispatch(set_properties_filter_from_grid_filter_model(filter_model))
  }

  const pagination_model = {
    pageSize: pagination.size,
    page: pagination.from / pagination.size
  }

  const { data: datasets_data, loading } = useQuery(
    GET_DATASETS_FOR_INSIGHTS_DASHBOARD,
    {
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        page_size: pagination.size,
        page_from: pagination.from,
        query_string,
        properties_sort,
        properties_filter
      },
      skip: !organization_id || !workspace_id,
      onCompleted: data => {
        const valid_datasets = (data.get_datasets_list.rows?.filter(
          row => typeof row.id === 'string'
        ) || []) as Dataset[]
        dispatch(set_datasets(valid_datasets))
      }
    }
  )

  useQuery(GET_COLUMNS_FOR_INSIGHTS_DASHBOARD, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string]
    },
    skip: !organization_id || !workspace_id,
    onCompleted: data => {
      dispatch(set_properties(data.get_datasets_list.columns || []))
    }
  })

  // Need to ensure row_count is not set to undefined between queries
  // otherwise the pagination model gets reset to page 0.
  const total_row_count =
    datasets_data?.get_datasets_list.result_count || undefined
  const rowCountRef = useRef(total_row_count || 0)
  const row_count = useMemo(() => {
    if (total_row_count !== undefined) {
      rowCountRef.current = total_row_count
    }
    return rowCountRef.current
  }, [total_row_count])

  const pinned_rows: any = useMemo(
    () => ({ top: staged_dataset_table_rows, bottom: [] }),
    [staged_dataset_table_rows]
  )

  const columns: GridColDef<GridValidRowModel>[] = useMemo(
    () => [
      {
        field: 'Select',
        type: 'actions',
        width: 50,
        renderHeader: Select_Column_Header,
        getActions: ({ row }: any) => {
          const is_pinned =
            pinned_rows.top.find((r: any) => r.id === row.id) !== undefined
          return [
            <GridActionsCellItem
              label={is_pinned ? 'Remove Dataset' : 'Add Dataset'}
              icon={<Checkbox checked={is_pinned} />}
              onClick={() =>
                is_pinned
                  ? handle_unstage_dataset(row)
                  : handle_stage_datasets([row])
              }
            />
          ]
        }
      },
      ...dataset_columns
    ],
    [
      dataset_columns,
      handle_unstage_dataset,
      handle_stage_datasets,
      pinned_rows
    ]
  )

  // Derive initial column visibility model from Redux state
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(column_visibility_model)

  useEffect(() => {
    setColumnVisibilityModel(column_visibility_model)
  }, [column_visibility_model])

  return (
    <Styled_Dataset_Datagrid
      key={workspace_id}
      loading={loading}
      pagination
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      pinnedRows={pinned_rows}
      disableDensitySelector
      disableRowSelectionOnClick
      initialState={{ pinnedColumns: { left: ['Select'] } }}
      rows={dataset_rows || []}
      rowCount={row_count}
      columnHeaderHeight={COLUMN_HEADER_HEIGHT}
      paginationModel={pagination_model}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      density='compact'
      paginationMode='server'
      onPaginationModelChange={handle_pagination_change}
      sortingMode='server'
      onSortModelChange={handle_sort_model_change}
      filterMode='server'
      hideFooterSelectedRowCount
      onFilterModelChange={handle_filter_model_change}
      filterDebounceMs={500}
      slots={{
        toolbar: Insights_Datasets_Table_Header as GridSlots['toolbar'],
        loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
        noResultsOverlay: () => null,
        noRowsOverlay: () => null,
        filterPanel: () => (
          <Filter_Panel_Wrapper>
            <Insights_Datasets_Table_Filter_Panel />
          </Filter_Panel_Wrapper>
        )
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          row_count,
          loading
        },
        basePopper: {
          sx: {
            boxShadow: theme => theme.shadows[6]
          }
        },
        pagination: {
          rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
            value: size,
            label: `${size} rows per page`
          })),
          labelRowsPerPage: null
        }
      }}
    />
  )
}

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  maxWidth: 420
}))

export const Insights_Datasets_Table = () => {
  return (
    <Grid2 container>
      <Datasets_Table_Item size='grow'>
        <Dataset_Datagrid />
      </Datasets_Table_Item>
    </Grid2>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(
  ({ theme, pinnedRows }) => ({
    border: 'none',
    minHeight: '80vh',
    height: `${
      pinnedRows?.top?.length
        ? pinnedRows?.top?.length * GRID_ROW_HEIGHT + PINNED_ROW_BUFFER
        : 0
    }px`,
    '.MuiDataGrid-topContainer': {
      backgroundColor: theme.palette.gray[100]
    },
    '.MuiDataGrid-overlayWrapper': {
      top: `${
        pinnedRows?.top?.length
          ? pinnedRows?.top?.length * GRID_ROW_HEIGHT +
            COLUMN_HEADER_HEIGHT * COMPACT_DENSITY_FACTOR
          : COLUMN_HEADER_HEIGHT * COMPACT_DENSITY_FACTOR
      }px`,
      '.MuiDataGrid-overlayWrapperInner': {
        height: '0px !important'
      }
    },
    '.MuiDataGrid-main': {
      border: `1px solid ${theme.palette.divider}`
    },
    '.MuiDataGrid-footerContainer': {
      border: `1px solid ${theme.palette.divider}`,
      borderTop: 'none',
      marginBottom: theme.spacing(2)
    },
    '.MuiDataGrid-pinnedRows': {
      '.MuiDataGrid-row, .MuiDataGrid-row:hover': {
        backgroundColor: lighten(PINNED_ROWS_HIGHLIGHT, 0.94)
      }
    },
    '.MuiDataGrid-pinnedColumnHeaders': {
      boxShadow: 'none'
    },
    '.MuiDataGrid-row': {
      ':hover': {
        backgroundColor: 'none',
        '.MuiDataGrid-cell--pinnedLeft': {
          backgroundColor: `${theme.palette.blue[100]}!important`
        }
      }
    },
    '.quick_filter': {
      input: {
        padding: '0px'
      },
      fieldset: {
        '&:focus-visible': {
          border: 'none',
          boxShadow: 'none'
        }
      }
    },
    '.MuiTablePagination-root': {
      width: '100%',
      '.MuiTablePagination-toolbar': {
        '.MuiInputBase-root': {
          border: 0,
          background: 'transparent',
          boxShadow: 'none',
          margin: 0
        },
        '.MuiSelect-select': {
          border: 0
        },
        '.MuiTablePagination-spacer': {
          display: 'none'
        },
        '.MuiTablePagination-displayedRows': {
          flex: 1,
          textAlign: 'right'
        }
      }
    }
  })
)

const Datasets_Table_Item = styled(Grid2)`
  max-width: 100%;
`
