import {
  ChartLineData,
  Copy,
  DataTable,
  TrashCan,
  WarningHexFilled
} from '@carbon/icons-react'
import { ButtonProps } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'

import { Button, Popconfirm, Typography } from '@/components'
import { AddChartToLibrary } from '@/insights/charts/AddChartToLibrary'
import { mapChartConfigToChartState } from '@/insights/charts/transform'
import { AddTableToLibrary } from '@/insights/tables/AddTableToLibrary'
import { Identifiable } from '@/types'

import {
  VisualizationType,
  visualizationAtomFamily
} from '../../../store/report.molecule'
import { VisualizationItemProvider } from './VisualizationContext'
import { ConfigureChart, SelectMetricType } from './charts'
import { ConfigureTable } from './tables/ConfigureTable'

const { Title } = Typography

type VisualizationTitleProps = Identifiable & {
  onEditStart?: () => void
}
// Component to render the title of the visualization
export const VisualizationTitle: FC<VisualizationTitleProps> = props => {
  const { id, onEditStart } = props
  const [visualization, setVisualization] = useAtom(
    visualizationAtomFamily({ id })
  )
  const { title, type } = visualization

  // Function to update the title of the visualization
  const updateTitle = (newTitle: string) => {
    if (newTitle === '') return
    setVisualization(prev => ({ ...prev, title: newTitle }))
  }

  // Determine the icon based on the visualization type
  const Icon = type === VisualizationType.Chart ? ChartLineData : DataTable

  return (
    <Title
      level={5}
      className='[&>button]:text-sm !mb-0 flex flex-row items-center gap-x-2'
      ellipsis
      editable={{
        onChange: updateTitle,
        onStart: onEditStart
      }}
    >
      <Icon className='text-lg' />
      {title}
    </Title>
  )
}

type VisualizationItemProps = Identifiable & {
  canDuplicate?: boolean
  onDelete: () => void
  onDuplicate: () => void
}
// Component to render each visualization item
export const VisualizationItem: FC<VisualizationItemProps> = ({
  id,
  canDuplicate = false,
  onDelete,
  onDuplicate
}) => {
  const visualization = useAtomValue(visualizationAtomFamily({ id }))

  if (id == null) {
    return null
  }

  return (
    <VisualizationItemProvider visualizationId={id}>
      <div className='flex justify-between mb-4'>
        <div className='w-1/5'>
          {visualization.type === VisualizationType.Chart && (
            <SelectMetricType />
          )}
        </div>
        <div className='flex gap-x-1'>
          {visualization.type === VisualizationType.Chart ? (
            <AddChartToLibrary
              chartState={mapChartConfigToChartState(visualization.config)}
              isInitialState={false}
              isReportBuilder
            />
          ) : (
            <AddTableToLibrary
              tableState={visualization.config}
              isInitialState={false}
              isReportBuilder
            />
          )}
          {visualization.type === VisualizationType.Chart && (
            <ActionButton onClick={onDuplicate} disabled={!canDuplicate}>
              <Copy /> Duplicate Chart
            </ActionButton>
          )}
          <Popconfirm
            key='delete'
            placement='topRight'
            title='Are you sure you want to delete this visualization?'
            onConfirm={onDelete}
            okText='Yes'
            cancelText='No'
            icon={
              <WarningHexFilled size={22} className='mr-1 text-amber-500' />
            }
          >
            <ActionButton>
              <TrashCan /> Remove{' '}
              {visualization.type === VisualizationType.Chart
                ? 'Chart'
                : 'Table'}
            </ActionButton>
          </Popconfirm>
        </div>
      </div>
      {visualization.type === VisualizationType.Chart ? (
        <ConfigureChart />
      ) : (
        <ConfigureTable />
      )}
    </VisualizationItemProvider>
  )
}

const ActionButton = (props: ButtonProps) => (
  <Button
    type='text'
    className='text-xs font-medium text-gray-500 px-2'
    {...props}
  />
)
