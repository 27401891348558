// This file serves as a central hub for re-exporting pre-typed Redux hooks.
// These imports are restricted elsewhere to ensure consistent
// usage of typed hooks throughout the application.
// We disable the ESLint rule here because this is the designated place
// for importing and re-exporting the typed versions of hooks.
/* eslint-disable @typescript-eslint/no-restricted-imports */
import { useDispatch, useSelector } from 'react-redux'
import type { App_Dispatch, Root_State } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const use_app_dispatch = useDispatch.withTypes<App_Dispatch>()
export const use_app_selector = useSelector.withTypes<Root_State>()
