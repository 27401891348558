import { PayloadAction } from '@reduxjs/toolkit'

import { create_app_slice } from '../state/redux/create_app_slice'

export interface User_Slice_State {
  access_token?: Nullable<string>
  email?: Nullable<string>
  propelauth_id?: Nullable<string>
}

const initial_state: User_Slice_State = {}

export const user_slice = create_app_slice({
  name: 'user',
  initialState: initial_state,
  reducers: create => ({
    set_access_token: create.reducer(
      (state, { payload }: PayloadAction<Nilable<string>>) => {
        state.access_token = payload
      }
    ),
    set_email: create.reducer(
      (state, { payload }: PayloadAction<Nilable<string>>) => {
        state.email = payload
      }
    ),
    set_propelauth_id: create.reducer(
      (state, { payload }: PayloadAction<Nilable<string>>) => {
        state.propelauth_id = payload
      }
    ),
    reset_user: create.reducer(state => {
      state = initial_state
    })
  }),
  selectors: {
    select_access_token: slice_state => slice_state.access_token,
    select_user_email: slice_state => slice_state.email,
    select_user_id: slice_state => slice_state.propelauth_id
  }
})

// Action creators are generated for each case reducer function.
export const { set_access_token, set_email, set_propelauth_id, reset_user } =
  user_slice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { select_user_email, select_access_token, select_user_id } =
  user_slice.selectors
