import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight
} from '@carbon/icons-react'
import {
  Button,
  ButtonProps,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DatePicker,
  DateValue,
  Dialog,
  Group,
  Heading,
  Label,
  Popover
} from 'react-aria-components'

import { cn } from '@/utils'

export default function DateFilter({
  placeholder = '',
  value,
  onChange
}: {
  placeholder?: string
  value: DateValue | null
  onChange: (date: DateValue) => void
}) {
  return (
    <DatePicker
      aria-label='Calendar Date'
      className='group flex flex-col gap-1 w-[150px] border border-slate-300 rounded-md focus-visible:ring-2 ring-black'
    >
      <Group className='flex rounded-lg bg-white/90 focus-within:bg-white group-open:bg-white transition pl-1 focus-visible:ring-2 ring-black'>
        <Label
          className={cn(
            'flex flex-1 p-1.5',
            value ? 'text-black' : 'text-gray-400'
          )}
        >
          {value ? value.toString() : placeholder}
        </Label>
        <Button className='outline-none px-1.5 flex items-center text-gray-700 transition border-solid border-l bg-transparent'>
          <CalendarIcon />
        </Button>
      </Group>
      <Popover className='overflow-auto rounded-lg drop-shadow-lg ring-1 ring-black/10 bg-white'>
        <Dialog className='p-6'>
          <Calendar value={value} onChange={onChange}>
            <header className='flex items-center gap-1 pb-4 px-1 w-full'>
              <Heading className='flex-1 font-semibold text-2xl ml-2' />
              <RoundedButton slot='previous'>
                <ChevronLeft />
              </RoundedButton>
              <RoundedButton slot='next'>
                <ChevronRight />
              </RoundedButton>
            </header>
            <CalendarGrid className='border-spacing-1 border-separate'>
              <CalendarGridHeader>
                {day => (
                  <CalendarHeaderCell className='text-xs text-gray-500 font-semibold'>
                    {day}
                  </CalendarHeaderCell>
                )}
              </CalendarGridHeader>
              <CalendarGridBody>
                {date => (
                  <CalendarCell
                    date={date}
                    className='w-9 h-9 outline-none cursor-default rounded-full flex items-center justify-center hover:bg-gray-100 pressed:bg-gray-200'
                  />
                )}
              </CalendarGridBody>
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </DatePicker>
  )
}

function RoundedButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className='w-9 h-9 outline-none cursor-default bg-transparent text-gray-600 border-0 rounded-full flex items-center justify-center hover:bg-gray-100'
    />
  )
}
