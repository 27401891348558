import { DataAnalytics } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { useParams } from 'react-router'

import { InsightsChartStateless } from '@/insights/__components/InsightsChartStateless'
import { InsightsChartTypes } from '@/insights/charts/types'
import { ReportRouteParams } from '@/insights/reports/types'

import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../../store/report.molecule'
import { MenuHeader } from '../../controls'
import { useVisualizationItem } from '../VisualizationContext'
import { MenuCycleChart } from './MenuCycleChart'
import { MenuDatasetChart } from './MenuDatasetChart'
import { MenuInCycleChart } from './MenuInCycleChart'

export const ConfigureChart = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { datasetIds, workspaceId, organizationId } = report

  const { visualizationId } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, title } = visualization

  if (config.type !== VisualizationType.Chart) return null
  const { metricType } = config

  let ChartSettingsComponent = null
  switch (metricType) {
    case InsightsChartTypes.IN_CYCLE_METRICS:
      ChartSettingsComponent = MenuInCycleChart
      break
    case InsightsChartTypes.CYCLE_METRICS:
      ChartSettingsComponent = MenuCycleChart
      break
    case InsightsChartTypes.CELL_METRICS:
      ChartSettingsComponent = MenuDatasetChart
      break
    default:
      ChartSettingsComponent = MenuCycleChart
      break
  }

  return (
    <div className='grid grid-cols-1 gap-8 lg:grid-cols-[7fr_6fr]'>
      <div className='flex flex-col flex-start'>
        <div className='grid grid-cols-2 gap-x-4 gap-y-8'>
          <ChartSettingsComponent />
        </div>
      </div>
      <div className='lg:border-l border-gray-200 lg:px-8'>
        <MenuHeader
          title={
            <div className='flex flex-row gap-x-2 items-center'>
              <DataAnalytics />
              Preview
            </div>
          }
        />
        <div className='bg-white p-4'>
          <InsightsChartStateless
            chartId={visualizationId}
            config={config}
            datasetIds={datasetIds}
            organizationId={organizationId}
            workspaceId={workspaceId}
            title={title}
            showFilterControls={false}
          />
        </div>
      </div>
    </div>
  )
}
