import { useMutation } from '@apollo/client'
import { Copy } from '@carbon/icons-react'
import { ButtonProps } from 'antd'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button, Input, Popconfirm, notification } from '@/components'
import { SAVE_CHART } from '@/insights/charts/queries/charts'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'
import { select_user_id } from '@/user/user_slice'
import { cn } from '@/utils'

import { InsightsChartState } from '../jotai/charts.atoms'
import {
  makeChartDescriptionFromChartState,
  makeChartTitleFromChartState,
  mapChartStateToChartTemplate
} from './transform'

type AddChartToLibraryProps = ButtonProps & {
  chartState: InsightsChartState
  isInitialState?: boolean
  isReportBuilder?: boolean // UI specs are different for playground and report builder
}

export const AddChartToLibrary = (props: AddChartToLibraryProps) => {
  const {
    chartState,
    className,
    isInitialState = true,
    isReportBuilder,
    ...buttonProps
  } = props
  const [api, contextHolder] = notification.useNotification()

  const userId = use_app_selector(select_user_id)
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [chartTitle, setChartTitle] = useState<string>('')
  const [chartDescription, setChartDescription] = useState<string>('')

  const [saveChartToLibrary, { loading: savingChart }] = useMutation(
    SAVE_CHART,
    {
      onCompleted: data => {
        api.success({
          message: 'Saved chart to library',
          description: data.save_chart.title
        })
      }
    }
  )

  const updateMetadataFromChartState = (chartState: InsightsChartState) => {
    setChartTitle(makeChartTitleFromChartState(chartState))
    setChartDescription(makeChartDescriptionFromChartState(chartState))
  }

  const addChartToLibrary = async () => {
    if (isInitialState || savingChart) return
    if (organization_id == null || workspace_id == null || userId == null) {
      return
    }
    const chartId = uuidv4()

    await saveChartToLibrary({
      variables: {
        id: chartId,
        title: chartTitle,
        description: chartDescription,
        chart_config: JSON.stringify(mapChartStateToChartTemplate(chartState)),
        organization_id: organization_id,
        workspace_id: workspace_id,
        owner_user_id: userId
      },
      refetchQueries: ['get_charts'],
      awaitRefetchQueries: true
    })

    updateMetadataFromChartState(chartState)
  }

  useEffect(() => {
    updateMetadataFromChartState(chartState)
  }, [chartState])

  return (
    <>
      {contextHolder}
      <Popconfirm
        title='Save chart template to library?'
        placement='topRight'
        overlayInnerStyle={{ width: '500px' }}
        overlayClassName='[&_.ant-popconfirm-message-text]:flex-1 [&_.ant-popconfirm-description]:flex-1'
        description={
          <div className='py-2 flex flex-col gap-2'>
            <div>
              This will save the chart as a template for future use.
              <div className='text-xs flex gap-x-1 my-2'>
                <div className='font-bold'>Note:</div>
                <div>
                  Stored templates
                  <span className='font-medium italic mx-1'>do not</span>store
                  or preselect datasets.
                </div>
              </div>
            </div>
            <label className='w-full flex flex-col gap-1'>
              <div className='font-medium'>Title:</div>
              <Input
                onChange={e => setChartTitle(e.target.value)}
                onBlur={e => {
                  setChartTitle(
                    e.target.value || makeChartTitleFromChartState(chartState)
                  )
                }}
                value={chartTitle}
              />
            </label>
            <label className='w-full flex flex-col gap-1'>
              <div className='font-medium'>Description:</div>
              <Input.TextArea
                className='text-[13px]'
                onChange={e => setChartDescription(e.target.value)}
                onBlur={e => {
                  setChartDescription(
                    e.target.value ||
                      makeChartDescriptionFromChartState(chartState)
                  )
                }}
                value={chartDescription}
                style={{
                  minHeight: `${
                    Math.max(2, chartDescription.split('\n').length) * 2.25
                  }em`
                }}
              />
            </label>
          </div>
        }
        onConfirm={addChartToLibrary}
      >
        {isReportBuilder ? (
          <Button
            type='text'
            className='text-xs font-medium text-gray-500 px-2'
            {...buttonProps}
          >
            <Copy /> Add Chart To Library
          </Button>
        ) : (
          <Button
            className={cn('text-xs font-medium', className)}
            disabled={isInitialState}
            {...buttonProps}
          >
            Save chart
          </Button>
        )}
      </Popconfirm>
    </>
  )
}
