import { AnnotationRequestDataPoint, DataPointSelectionOpts } from '../../types'
import { AugmentedSelectorOption } from '../charts.atoms'
import { ApexChartSeries } from './types'

export const _getAnnotations = (
  opts: DataPointSelectionOpts,
  series: ApexChartSeries[],
  xProperty: AugmentedSelectorOption,
  yProperties: AugmentedSelectorOption[]
): AnnotationRequestDataPoint[] => {
  let _selectedDataPoints: AnnotationRequestDataPoint[] = []

  opts.selectedDataPoints.forEach((seriesPoints, seriesIndex) => {
    const datasetKey = series[seriesIndex]?.id
    const _series = series[seriesIndex]

    seriesPoints.forEach(point => {
      const xPropertyValue = _series.data[point].x
      const yPropertyValue = _series.data[point].y

      const _yProperty = yProperties.find(yProperty =>
        _series.name.includes(yProperty.label)
      )

      if (datasetKey && _yProperty) {
        _selectedDataPoints.push({
          datasetKey,
          xProperty,
          xPropertyValue,
          yProperty: _yProperty,
          yPropertyValue
        })
      }
    })
  })

  return _selectedDataPoints
}
