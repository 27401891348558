import { gql } from '@/gql_generated'

export const SAVE_ANNOTATIONS = gql(`
  mutation save_annotations($organization_id: String!, $workspace_id: String!, $chart_type: String!, $data_points: [Iris_Annotation_Data_Point!]!, $column_name: String!, $comment: String!) {
    save_annotations(
      organization_id: $organization_id,
      workspace_id: $workspace_id,
      chart_type: $chart_type,
      data_points: $data_points,
      column_name: $column_name,
      comment: $comment
    ) {
      annotations {
        id
      }
    }
  }
`)
