import { gql } from '@/gql_generated'

export const SAVE_REPORT_TEMPLATE = gql(`
  mutation save_report_template($id: String!, $title: String!, $organization_id: String!, $workspace_id: String!, $report_template: String!, $description: String, $schema_version: Int) {
    save_report_template(
      id: $id, 
      title: $title, 
      organization_id: $organization_id, 
      workspace_id: $workspace_id, 
      report_template: $report_template,
      description: $description,
      schema_version: $schema_version
    ) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_template
    }
  }
`)

export const GET_REPORT_TEMPLATES = gql(`
  query get_report_templates($organization_id: String!, $workspace_id: String!, $page: Float!, $page_size: Float!) {
    get_report_templates(organization_id: $organization_id, workspace_id: $workspace_id, page: $page, page_size: $page_size) {
      data {
        id
        title
        description
        schema_version
        created_at
        updated_at
        report_template
      }
      total
      page
      page_size     
    }
  }
`)

export const LOAD_REPORT_TEMPLATE = gql(`
  query load_report_template($id: String!) {
    load_report_template(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_template
    }
  }
`)

export const DELETE_REPORT_TEMPLATE = gql(`
  mutation delete_report_template($id: String!) {
    delete_report_template(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      report_template
    }
  }
`)
