import { useQuery } from '@apollo/client'
import { isEqual, uniqBy } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Virtuoso } from 'react-virtuoso'

import { Button, Divider, Spin, Tooltip } from '@/components'
import { NoResults } from '@/components/NoResults'
import { Iris_Report_Template } from '@/gql_generated/graphql'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { Pagination } from '@/types'

import { GET_REPORT_TEMPLATES } from '../../queries/reportTemplates'

type VirtualizedReportTemplateListProps = {
  onItemClick?: (item: Partial<Iris_Report_Template>) => void
  showItemTooltips?: boolean
}

export const VirtualizedReportTemplateList = (
  props: VirtualizedReportTemplateListProps
) => {
  const { onItemClick, showItemTooltips = true } = props
  const navigate = useNavigate()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [loadedOnce, setLoadedOnce] = useState(false)
  const [allItems, setAllItems] = useState<Partial<Iris_Report_Template>[]>([])
  const [paginationModel, setPaginationModel] = useState<Pagination>({
    pageSize: 100,
    page: 0
  })

  const queryVariables = useMemo(
    () => ({
      organization_id: organization_id as string,
      workspace_id: workspace_id as string,
      page: paginationModel.page + 1, // The backend indexes from 1
      page_size: paginationModel.pageSize
    }),
    [organization_id, workspace_id, paginationModel]
  )

  const { data, previousData, loading } = useQuery(GET_REPORT_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !organization_id || !workspace_id,
    onCompleted: () => {
      setLoadedOnce(true)
    }
  })

  const totalRowCount =
    data?.get_report_templates.total ??
    previousData?.get_report_templates.total ??
    0

  useEffect(() => {
    if (data == null) return
    const rawRows: Partial<Iris_Report_Template>[] =
      data.get_report_templates.data ?? []

    setAllItems(prev => {
      const next = uniqBy([...prev, ...rawRows], 'id')
      return isEqual(prev, next) ? prev : next
    })
  }, [data])

  return (
    <div className='min-h-[50px] h-[40vh] min-w-80 py-2 flex flex-col gap-2'>
      <>
        <div className='flex justify-between'>
          {/* <div className='px-4 font-medium'>Create Report from Template</div> */}
          <div>
            <Button
              variant='filled'
              color='primary'
              size='small'
              className='text-xs font-medium'
              onClick={() => {
                navigate('../template-library/reports')
              }}
            >
              Manage
            </Button>
          </div>
          <div className='text-xs font-medium text-gray-500 self-end'>
            {totalRowCount} report templates
          </div>
        </div>
        <Divider className='!m-0' />
        <Spin
          tip='Loading...'
          wrapperClassName='h-full relative [&_.ant-spin-container]:h-full'
          spinning={loading}
        >
          {totalRowCount === 0 && loadedOnce ? (
            <NoResults />
          ) : (
            <Virtuoso
              data={allItems}
              totalCount={totalRowCount}
              className='overflow-x-hidden'
              itemContent={(_, item) => (
                <div className='pb-2 min-h-7'>
                  <Tooltip
                    placement='left'
                    // Setting title to empty string to prevent the tooltip from showing up on hover
                    title={
                      showItemTooltips ? 'Create report with this template' : ''
                    }
                  >
                    <Button
                      variant='text'
                      color='default'
                      key={item.id}
                      className='text-xs font-medium h-auto w-full overflow-hidden [&:focus]:bg-gray-100'
                      onClick={() => onItemClick?.(item)}
                    >
                      <div className='flex flex-1 flex-col w-full overflow-hidden items-start gap-y-0.5'>
                        <div className='text-xs text-ellipsis text-left overflow-hidden w-full'>
                          {item.title}
                        </div>
                        <pre className='text-xxs text-ellipsis text-gray-500 text-left'>
                          {item.description ?? (
                            <span className='italic'>
                              No description provided
                            </span>
                          )}
                        </pre>
                      </div>
                    </Button>
                  </Tooltip>
                </div>
              )}
              endReached={() => {
                setPaginationModel(prev => {
                  const next = { ...prev, page: prev.page + 1 }
                  return isEqual(prev, next) ? prev : next
                })
              }}
            />
          )}
        </Spin>
      </>
    </div>
  )
}
