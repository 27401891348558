import { LicenseInfo } from '@mui/x-license'
import { createRoot } from 'react-dom/client'

import { Base } from './base/Base'
import './index.css'
import reportWebVitals from './reportWebVitals'

LicenseInfo.setLicenseKey(
  '49956b82edbdcdb9ff94f9e2395bab27Tz04MTA5OSxFPTE3MzQ5ODAyNzAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<Base />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
