export enum Byterat_Property {
  // Cycle observations
  'cycle_time' = 'cycle_time',
  'dataset_time' = 'dataset_time',
  'test_time' = 'test_time',
  'start_time' = 'start_time',
  'time' = 'time',
  // Cycle summaries
  'absolute_end_time' = 'absolute_end_time',
  'absolute_start_time' = 'absolute_start_time',
  'test_end_time' = 'test_end_time',
  'test_start_time' = 'test_start_time'
}

export const Excluded_Byterat_Property = [
  Byterat_Property.cycle_time,
  Byterat_Property.dataset_time,
  Byterat_Property.test_time,
  Byterat_Property.time
]

export const Absolute_Time_Byterat_Property = [
  Byterat_Property.absolute_end_time,
  Byterat_Property.absolute_start_time,
  Byterat_Property.start_time,
  Byterat_Property.time
]

export const Non_Absolute_Time_Byterat_Property = [
  Byterat_Property.cycle_time,
  Byterat_Property.test_time,
  Byterat_Property.test_end_time,
  Byterat_Property.test_start_time,
  Byterat_Property.dataset_time
]

export const Any_Time_Byterat_Property = [
  ...Absolute_Time_Byterat_Property,
  ...Non_Absolute_Time_Byterat_Property
]
