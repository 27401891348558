type ObjectWithKeyProp = {
  key: string
  [key: string]: any
}

export function mapObjectKeyToValue<T extends ObjectWithKeyProp>(
  object: T
): T & { value: string } {
  return { value: object.key, ...object }
}

export function mapListKeysToValues<T extends ObjectWithKeyProp>(
  objects: T[]
): (T & { value: string })[] {
  return objects.map(mapObjectKeyToValue)
}
