import { useQuery } from '@apollo/client'
import { ChevronDown } from '@carbon/icons-react'
import { GridPaginationModel } from '@mui/x-data-grid'
import { uniqBy } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { Button, Popover } from '@/components'
import { NoResults } from '@/components/NoResults'
import { Iris_Chart } from '@/gql_generated/graphql'
import { GET_CHARTS } from '@/insights/charts/queries/charts'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { ChartConfig, Visualization } from '../reports/types'

type AddChartTemplateToReportProps = {
  onAddToReport?: (viz: Partial<Visualization>) => void
  className?: string
  disabled?: boolean
}

export const AddChartTemplateToReport = (
  props: AddChartTemplateToReportProps
) => {
  const { className, onAddToReport, disabled } = props
  const [popoverOpen, setPopoverOpen] = useState(false)

  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0
  })
  const [allItems, setAllItems] = useState<Partial<Iris_Chart>[]>([])

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_id: workspace_id as string,
    page: paginationModel.page + 1, // The backend indexes from 1
    page_size: paginationModel.pageSize
  }

  const { data, previousData } = useQuery(GET_CHARTS, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id
  })

  const totalRowCount =
    data?.get_charts.total ?? previousData?.get_charts.total ?? 0

  useEffect(() => {
    if (data == null) return
    const rawRows: Partial<Iris_Chart>[] = data.get_charts.data ?? []

    setAllItems(prev => uniqBy([...prev, ...rawRows], 'id'))
  }, [data])

  return (
    <Popover
      arrow={false}
      trigger='click'
      placement='bottomRight'
      overlayInnerStyle={{ padding: 8, width: '33vw' }}
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      content={
        <div className='min-h-[50px] h-[40vh] min-w-80 py-2 flex flex-col gap-2'>
          {totalRowCount === 0 ? (
            <NoResults />
          ) : (
            <Virtuoso
              data={allItems}
              totalCount={totalRowCount}
              className='overflow-x-hidden max-h-[40vh]'
              itemContent={(idx, item) => {
                return (
                  <div className='pb-2 min-h-7'>
                    <Button
                      variant='text'
                      color='default'
                      key={item.id}
                      className='text-xs font-medium h-auto w-full overflow-hidden'
                      onClick={() => {
                        if (item?.chart_config == null) return

                        const mappedReportChartConfig = JSON.parse(
                          item.chart_config
                        ) as ChartConfig

                        onAddToReport?.({
                          title: item.title,
                          config: {
                            ...mappedReportChartConfig,
                            id: nanoid() // Generate a new ID for the chart, in case of multi-instances of the same ID
                          }
                        })
                      }}
                    >
                      <div className='flex flex-1 flex-col w-full overflow-hidden items-start gap-y-0.5'>
                        <div className='text-xs text-ellipsis text-left overflow-hidden w-full'>
                          {item.title}
                        </div>
                        <pre className='text-xxs text-ellipsis text-gray-500 text-left'>
                          {item.description ?? (
                            <span className='italic'>
                              No description provided
                            </span>
                          )}
                        </pre>
                      </div>
                    </Button>
                  </div>
                )
              }}
              endReached={() =>
                setPaginationModel(prev => ({ ...prev, page: prev.page + 1 }))
              }
            />
          )}
        </div>
      }
    >
      <Button className={className} disabled={disabled}>
        <ChevronDown />
      </Button>
    </Popover>
  )
}
