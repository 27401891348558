import { gql } from '../../gql_generated'

export const GET_ORGANIZATIONS = gql(`
  query get_organizations {
    get_current_user_organizations {
      id
      name
      key
      workspaces {
        id
        key
        name
        organization_id
      }
    }
  }
`)
