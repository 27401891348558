import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { v4 as uuidv4 } from 'uuid'

import { idEquality } from '@/utils/idEquality'

import {
  Filter_Type,
  Iris_Metric_Target_Limits_Input,
  Normalize_Type
} from '../../gql_generated/graphql'
import { AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD, CHART_TYPE } from '../charts'
import { InsightsChartTypes } from '../charts/types'
import {
  AnnotationRequestDataPoint,
  CycleFilterWithValues,
  PropertyFilterWithValues,
  SelectorOption
} from '../types'

export type AugmentedSelectorOption = SelectorOption & {
  labelOverride?: string
}

export type NormalizeByOption = SelectorOption & {
  type: Normalize_Type
  group?: string
}

export type InsightsPropertyFilters = Record<string, PropertyFilterWithValues>
export type InsightsCycleFilters = Record<string, CycleFilterWithValues>
export type InsightsNormalizeByProperties = Record<string, NormalizeByOption>

export type NormalizeByProperty = {
  type: Normalize_Type
  value?: string
}

export type InsightsChartStateTemplate = {
  aggregateByProperty: SelectorOption
  chartType: CHART_TYPE
  cycleFilters: InsightsCycleFilters // only used for in-cycle metrics
  groupByProperty: Nullable<SelectorOption>
  metricType: InsightsChartTypes
  normalizeByProperties: InsightsNormalizeByProperties
  propertyFilters: InsightsPropertyFilters
  xAxisProperty: AugmentedSelectorOption
  xPropertyRange: NullableArrayItems<[number, number]>
  yAxisProperties: AugmentedSelectorOption[]
  yPropertyRanges: NullableArrayItems<[number, number]>[]
}

export type InsightsChartState = InsightsChartStateTemplate & {
  rawData: any[]
  datasets: any[]
  datasetColumns: any[]
  datasetIds: string[]
  metricTargetLimits: Iris_Metric_Target_Limits_Input[]
  metricTargetsApplied: string[]
  normalizeOptions: NormalizeByOption[]
  id: string // Unique ID for each chart
}

export type InsightsTableState = {
  columns?: Nullable<string[]>
  groupByProperty?: Nullable<SelectorOption>
}

const sharedChartDefaults: Omit<
  InsightsChartStateTemplate,
  'xAxisProperty' | 'yAxisProperties'
> = {
  aggregateByProperty: AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  cycleFilters: {},
  chartType: CHART_TYPE.LINE,
  groupByProperty: null,
  metricType: InsightsChartTypes.CYCLE_METRICS,
  normalizeByProperties: {},
  propertyFilters: {},
  xPropertyRange: [null, null] as [number | null, number | null],
  yPropertyRanges: [[null, null]]
}

const cycleSummaryChartDefaults: InsightsChartStateTemplate = {
  ...sharedChartDefaults,
  metricType: InsightsChartTypes.CYCLE_METRICS,
  xAxisProperty: { key: 'dataset_cycle', label: 'Dataset Cycle' },
  yAxisProperties: [
    { key: 'discharge_capacity_ah', label: 'Discharge Capacity [Ah]' }
  ]
}

const inCycleChartDefaults: InsightsChartStateTemplate = {
  ...sharedChartDefaults,
  metricType: InsightsChartTypes.IN_CYCLE_METRICS,
  xAxisProperty: {
    key: 'cycle_time',
    label: 'Time - Since Cycle Began',
    units: 'h'
  },
  yAxisProperties: [
    {
      key: 'current',
      label: 'Current',
      units: 'A'
    },
    {
      key: 'voltage',
      label: 'Voltage',
      units: 'V'
    }
  ],
  cycleFilters: {
    [uuidv4()]: {
      property: { key: 'cycle_number', label: 'Cycle Number' },
      filter_type: Filter_Type.NumericEquals,
      values: 1
    }
  }
}

const cellMetricsChartDefaults: InsightsChartStateTemplate = {
  ...sharedChartDefaults,
  metricType: InsightsChartTypes.CELL_METRICS,
  chartType: CHART_TYPE.LINE_MARKER,
  xAxisProperty: { key: 'cycle_count', label: 'Cycle Count' },
  yAxisProperties: [
    { key: 'discharge_capacity_cycle_1', label: 'Discharge Capacity Cycle 1' }
  ]
}

export const defaultChartStates = {
  [InsightsChartTypes.CYCLE_METRICS]: cycleSummaryChartDefaults,
  [InsightsChartTypes.IN_CYCLE_METRICS]: inCycleChartDefaults,
  [InsightsChartTypes.CELL_METRICS]: cellMetricsChartDefaults
}

// Initial states for each chart type
export const initialChartState: InsightsChartState = {
  id: '',
  cycleFilters: {}, // only used for in-cycle metrics
  xPropertyRange: [null, null] as NullableArrayItems<[number, number]>,
  yPropertyRanges: [[null, null]],
  chartType: CHART_TYPE.LINE,
  metricType: InsightsChartTypes.CYCLE_METRICS,
  metricTargetLimits: [],
  metricTargetsApplied: ['upper_limit', 'lower_limit'],
  propertyFilters: {},
  groupByProperty: null,
  aggregateByProperty: AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  normalizeByProperties: {},
  rawData: [],
  datasets: [],
  datasetColumns: [],
  datasetIds: [],
  normalizeOptions: [] as NormalizeByOption[],
  // Defaults from cycle summary chart
  xAxisProperty: { key: 'dataset_cycle', label: 'Dataset Cycle' },
  yAxisProperties: [
    { key: 'discharge_capacity_ah', label: 'Discharge Capacity [Ah]' }
  ]
}

// Atom family for managing individual chart states
export const chartStatesFamily = atomFamily(
  ({ id = '', ...rest }: Partial<InsightsChartState>) =>
    atom(
      { ...initialChartState, id, ...rest },
      // Write function to handle updates
      (
        get,
        set,
        update:
          | InsightsChartState
          | Pick<InsightsChartState, 'metricType'> // It's only okay to not provide the full state when changing the metric type
          | ((prev: InsightsChartState) => InsightsChartState)
      ) => {
        const prevState = get(chartStatesFamily({ id }))
        const newState =
          typeof update === 'function' ? update(prevState) : update

        if (prevState.metricType !== newState.metricType) {
          set(chartStatesFamily({ id }), {
            ...prevState,
            ...(newState.metricType === InsightsChartTypes.CYCLE_METRICS
              ? cycleSummaryChartDefaults
              : newState.metricType === InsightsChartTypes.IN_CYCLE_METRICS
                ? inCycleChartDefaults
                : cellMetricsChartDefaults),
            ...newState // When applying a template which will change the metric type, we still want to apply the template...
          })
        } else {
          set(chartStatesFamily({ id }), { ...prevState, ...newState })
        }
      }
    ),
  idEquality
)

export const chartSelectedAnnotationsAtom = atom<AnnotationRequestDataPoint[]>(
  []
)
