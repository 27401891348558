import {
  Autocomplete,
  AutocompleteProps,
  Grid2Props,
  TextField,
  styled
} from '@mui/material'
import { AutoTextSize } from 'auto-text-size'
import { differenceWith } from 'lodash-es'

import { Property_Type } from '../../../gql_generated/graphql'
import { SelectorOption } from '../../types'
import { Filter_Grid_Item } from './Filter_Grid'

export const AUTOCOMPLETE_CLASS_NAME = 'autocomplete-filter-grid'

export interface Autocomplete_Filter_Props<
  Value extends SelectorOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, false>,
    'renderInput'
  > {
  label?: string
  placeholder?: string
  multiple?: Multiple
  disableClearable?: DisableClearable
  grid_item_size?: Grid2Props['size']
  type?: Property_Type
}

export const Autocomplete_Filter = <
  Value extends SelectorOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  options,
  value,
  label,
  placeholder,
  multiple,
  grid_item_size,
  ...rest
}: Autocomplete_Filter_Props<Value, Multiple, DisableClearable>) => {
  const non_selected_options = differenceWith(
    options,
    value ? (Array.isArray(value) ? value : [value]) : [],
    (a, b) => a.key === b.key
  )

  return (
    <Filter_Grid_Item size={grid_item_size} className={AUTOCOMPLETE_CLASS_NAME}>
      <Autocomplete
        size='small'
        options={non_selected_options}
        value={value}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            size='small'
          />
        )}
        renderOption={(props, option) => (
          <li {...props} style={{ paddingInline: 8 }}>
            <Text maxFontSizePx={14} minFontSizePx={11}>
              {option.label}
            </Text>
          </li>
        )}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        {...rest}
      />
    </Filter_Grid_Item>
  )
}

const Text = styled(AutoTextSize)`
  align-self: center;
  overflow: hidden;
`
