import { CurrentSchemaVersions } from '@/constants'

import { Normalize_Type } from '../../gql_generated/graphql'
import { InsightsChartTypes } from '../charts/types'
import { defaultChartStates } from '../jotai/charts.atoms'
import { SelectorOption } from '../types'
import { VisualizationType } from './store/report.molecule'
import { ChartConfig, TableConfig } from './types'
import { MenuNavBase } from './views/ReportBuilder/controls/MenuNavContext'

export const MenuPanelId = {
  ...MenuNavBase,
  xAxis: 'x-axis',
  yAxis: 'y-axis',
  datasetFilters: 'dataset-filters',
  cycleFilters: 'cycle-filters',
  cycleMetricsFilters: 'cycle-metrics-filters',
  inCycleFilters: 'in-cycle-filters',
  export: 'export',
  groupBy: 'group-by'
} as const

export type NormalizeByOption = SelectorOption & {
  type: Normalize_Type
  group?: string
}

export const NORMALIZE_BY_INDEX_VALUES: NormalizeByOption[] = [
  {
    key: 0,
    label: 'Initial Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 1,
    label: 'Second Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 2,
    label: 'Third Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 3,
    label: 'Fourth Cycle',
    type: Normalize_Type.NthCycle
  },
  {
    key: 4,
    label: 'Fifth Cycle',
    type: Normalize_Type.NthCycle
  }
]

export const DEFAULT_TABLE_CONFIG: TableConfig = {
  type: VisualizationType.Table
}

export const DEFAULT_CHART_CONFIG_BY_TYPE: Record<
  InsightsChartTypes,
  ChartConfig
> = {
  [InsightsChartTypes.IN_CYCLE_METRICS]: {
    ...defaultChartStates[InsightsChartTypes.IN_CYCLE_METRICS],
    type: VisualizationType.Chart,
    schemaVersion: CurrentSchemaVersions.Chart
  },
  [InsightsChartTypes.CYCLE_METRICS]: {
    ...defaultChartStates[InsightsChartTypes.CYCLE_METRICS],
    type: VisualizationType.Chart,
    schemaVersion: CurrentSchemaVersions.Chart
  },
  [InsightsChartTypes.CELL_METRICS]: {
    ...defaultChartStates[InsightsChartTypes.CELL_METRICS],
    type: VisualizationType.Chart,
    schemaVersion: CurrentSchemaVersions.Chart
  }
}
