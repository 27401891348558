import { ArrowLeft } from '@carbon/icons-react'
import { ReactNode } from 'react'

import { Button, Typography } from '../../../../../components'

const { Text } = Typography

type MenuHeaderProps = {
  title: ReactNode
  onGoBack?: () => void
}

export function MenuHeader(props: MenuHeaderProps) {
  const { title, onGoBack } = props

  const showBackButton = onGoBack != null
  return (
    <div className='pb-1 mb-1 border-b flex flex-row gap-x-2 p-1 items-center'>
      {showBackButton && (
        <Button
          type='text'
          shape='circle'
          icon={<ArrowLeft />}
          onClick={onGoBack}
        />
      )}
      <Text className='font-bold flex flex-1'>{title}</Text>
    </div>
  )
}
