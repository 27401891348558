import { gql } from '../../gql_generated'

export const GET_DATASETS_FOR_INSIGHTS_DASHBOARD = gql(`
  query get_datasets_for_insights_dashboard($organization_id: String!, $workspace_ids: [String!]!, $page_from: Float!, $page_size: Float!, $query_string: String, $properties_sort: [Property_Sort!]!, $properties_filter: Property_Filters) {
    get_datasets_list(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      page_from: $page_from
      page_size: $page_size
      query_string: $query_string 
      properties_sort: $properties_sort
      properties_filter: $properties_filter
    ) {
      result_count
      page_size
      page_from
      rows {
        id
        annotations
        properties {
          key
          label
          value
        }
      }
    }
  }
`)

export const GET_COLUMNS_FOR_INSIGHTS_DASHBOARD = gql(`
  query get_columns_for_insights_dashboard($organization_id: String!, $workspace_ids: [String!]!) {
    get_datasets_list(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      page_from: 0
      page_size: 1
    ) {
      columns{
        key
        pinned
        property_type
        units
      }
    }
  }
`)

export const GET_DATASETS_LIST_FOR_REPORT = gql(`
  query get_datasets_list_for_report(
    $organization_id: String!, 
    $workspace_ids: [String!]!, 
    $dataset_ids: [String!]!,
    $page_from: Float!,
    $page_size: Float!,
    $properties_sort: [Property_Sort!]!,
    $group_by: String!,
    $aggregate_by: String!
  ) {
    get_datasets_list_for_report(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      dataset_ids: $dataset_ids
      page_from: $page_from
      page_size: $page_size
      properties_sort: $properties_sort
      group_by: $group_by
      aggregate_by: $aggregate_by
    ) {
      result_count
      rows {
        id
        properties {
          key
          label
          value
        }
      }
      columns{
        key
        pinned
        property_type
        units
      }
    }
  }
`)
