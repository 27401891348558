import { ChevronRight } from '@carbon/icons-react'
import { Box, IconButton, MenuItem, styled } from '@mui/material'

export const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  justifyContent: 'space-between',
  paddingBlock: theme.spacing(1)
}))

export const Rounded_Icon_Button = styled(IconButton)({
  borderRadius: '100%'
})

export const Styled_Menu_Item = styled(MenuItem)({
  height: 40,
  justifyContent: 'space-between'
})

export const Menu_Item_Label = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.text.primary,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  fontWeight: 700,
  gap: '0.5rem'
}))

export const Menu_Item_Right = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.gray['400']
}))

export const Styled_ChevronRight = styled(ChevronRight)(({ theme }) => ({
  color: theme.palette.gray['500'],
  width: '1rem',
  height: '1rem'
}))
