import { Filter_Option, Filter_Type } from '../../gql_generated/graphql'
import { CHART_TYPE } from './chart_options'

export type Property_Filter_Value_Triplet = {
  property: Nullable<string>
  values: Filter_Option[]
  filterType: Filter_Type
}

export type Range = [number, number] // [min, max]

export type Chart = {
  id: string
  title: string
  metricType: InsightsChartTypes
  chartType: CHART_TYPE
  xPropertyRange: NullableArrayItems<Range>
  yPropertyRanges: Nullable<Record<string, NullableArrayItems<Range>>>
  xAxisProperty: string
  yAxisProperties: string[]
}

export enum InsightsChartTypes {
  IN_CYCLE_METRICS = 'cycler_series_chart',
  CYCLE_METRICS = 'cycler_cycle_chart',
  CELL_METRICS = 'dataset_metrics_chart'
}

export enum InsightsChartTypeLabels {
  'cycler_series_chart' = 'In-Cycle Metrics',
  'cycler_cycle_chart' = 'Cycle Metrics',
  'dataset_metrics_chart' = 'Cell Metrics'
}
