import { isEmpty, omit } from 'lodash-es'
import numbro from 'numbro'

import { CurrentSchemaVersions } from '@/constants'

import {
  AugmentedSelectorOption,
  InsightsChartState,
  InsightsTableState
} from '../jotai/charts.atoms'
import { VisualizationType } from '../reports/store/report.molecule'
import { ChartConfig } from '../reports/types'
import {
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  CHART_AGGREGATION_TYPES,
  CHART_TYPE
} from './chart_options'
import { format_key_to_label, format_property_label } from './utils'

/**
 * Returns the default title for a chart based on the given chart state.
 */
export function makeChartTitleFromChartState(chartState: InsightsChartState) {
  const { xAxisProperty, yAxisProperties, rawData } = chartState
  const { label: xLabel, units: xUnits } = xAxisProperty

  const hasSingleCycle = rawData.every(
    (data: any) => data.cycles && data.cycles.length === 1
  )
  const singleCycleNumber = rawData[0]?.cycles?.[0]?.cycle_number

  const xAxisTitle = xLabel ? format_property_label(xLabel, xUnits) : 'X Axis'
  const yAxisTitles = yAxisProperties
    .map(({ label, units }, index) =>
      label ? format_property_label(label, units) : `Y Axis ${index + 1}`
    )
    .join(' / ')
    .trim()

  let title = `${yAxisTitles} vs ${xAxisTitle}`

  if (hasSingleCycle && singleCycleNumber != null) {
    title = `${title} — Cycle Number ${numbro(singleCycleNumber).format({
      thousandSeparated: true
    })}`
  }

  return title
}

/**
 * Returns the default description for a chart based on the given chart state.
 */
export function makeChartDescriptionFromChartState(
  chartState: InsightsChartState
) {
  const { xAxisProperty, yAxisProperties, normalizeByProperties, chartType } =
    chartState
  const { label: xLabel, units: xUnits } = xAxisProperty

  const descriptionLines = [
    `X-Axis Property: ${format_property_label(xLabel, xUnits)} `
  ]

  if (!isEmpty(normalizeByProperties)) {
    const xNormalization = normalizeByProperties[xAxisProperty.key]
    if (xNormalization) {
      descriptionLines.push(
        `X-Axis Normalization: ${format_property_label(
          xNormalization.label,
          xNormalization.units
        )}`
      )
    }
  }

  yAxisProperties.forEach((property, index) => {
    descriptionLines.push(
      `Y-Axis ${index + 1} Property: ${format_property_label(
        property.label,
        property.units
      )}`
    )

    if (!isEmpty(normalizeByProperties)) {
      const normalizedProperty = normalizeByProperties[property.key]
      if (normalizedProperty) {
        descriptionLines.push(
          `Y-Axis ${
            index + 1
          } Normalization: ${property.label.trim()} by ${format_property_label(
            normalizedProperty.label,
            normalizedProperty.units
          )}`
        )
      }
    }
  })

  let chartTypeDescription: string

  switch (chartType) {
    case CHART_TYPE.LINE_MARKER:
      chartTypeDescription = 'Scatterplot'
      break
    case CHART_TYPE.LINE:
      chartTypeDescription = 'Line'
      break
    default:
      chartTypeDescription = 'Unknown'
      break
  }

  descriptionLines.push(`Chart Type: ${chartTypeDescription}`)

  return descriptionLines.join('\n')
}

export function makeTableDescriptionFromTableState(
  tableState: InsightsTableState
) {
  const { columns, groupByProperty } = tableState

  const descriptionLines: string[] = []
  if (columns && columns.length > 0) {
    descriptionLines.push(
      `Columns: ${columns.map(c => format_key_to_label(c)).join(', ')}`
    )
  }
  if (groupByProperty) {
    descriptionLines.push(`Group By: ${groupByProperty.label}`)
  }

  return descriptionLines.join('\n')
}

export function makeAxisTitle(
  property: AugmentedSelectorOption,
  normalizeByProperties?: Record<string, Nullable<AugmentedSelectorOption>>
) {
  const propertyLabel = format_property_label(property.label, property.units)

  const normalizeByProperty = normalizeByProperties?.[property.key]
  const normalizeLabel = format_property_label(
    normalizeByProperty?.label,
    normalizeByProperty?.units
  )
  return `${propertyLabel}${normalizeByProperty ? ` / ${normalizeLabel}` : ''}`
}

/**
 * Convert InsightsChartState to ChartTemplate
 */
export function mapChartStateToChartTemplate(
  chartState: InsightsChartState
): ChartConfig {
  return {
    type: VisualizationType.Chart,
    schemaVersion: CurrentSchemaVersions.Chart,
    ...omit(
      chartState,
      'rawData',
      'datasets',
      'datasetColumns',
      'datasetIds',
      'normalizeOptions'
    )
  }
}

export const mapChartConfigV0ToV2 = (config: any): ChartConfig => {
  const { propertyFilters = {}, cycleFilters = {} } = config

  const processFilters = (filters: Record<string, any>) =>
    Object.keys(filters).reduce((acc: Record<string, any>, _filterId) => {
      const filter = filters[_filterId]
      acc[_filterId] = {
        ...filter,
        filter_type: filter.filterType,
        property:
          typeof filter.property === 'string'
            ? { key: filter.property }
            : filter.property
      }
      return acc
    }, {})

  return {
    type: VisualizationType.Chart,
    chartType: config.chartType,
    xPropertyRange: config.xPropertyRange,
    yPropertyRanges: Object.values(config.yPropertyRanges ?? []),
    xAxisProperty: { key: config.xAxisProperty, label: '' },
    yAxisProperties: config.yAxisProperties.map((key: string) => ({
      key,
      label: ''
    })),
    metricType: config.metricType,
    groupByProperty: { key: config.groupByProperty, label: '' },
    aggregateByProperty:
      CHART_AGGREGATION_TYPES.find(
        ({ key }) => key === config.aggregateByProperty
      ) ?? AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
    cycleFilters: processFilters(cycleFilters),
    propertyFilters: processFilters(propertyFilters),
    normalizeByProperties: config.normalizeByProperties ?? {},
    schemaVersion: CurrentSchemaVersions.Chart
  }
}

export const mapChartConfigToChartState = (
  config: ChartConfig
): InsightsChartState => {
  return {
    ...config,
    rawData: [],
    datasets: [],
    datasetColumns: [],
    datasetIds: [],
    metricTargetLimits: [],
    metricTargetsApplied: [],
    normalizeOptions: [],
    id: config.id ?? ''
  }
}
