import {
  Filter_Option,
  Filter_Type,
  Normalize_Type
} from '@/gql_generated/graphql'

import { InsightsChartStateTemplate } from '../jotai/charts.atoms'
import { SelectorOption } from '../types'
import { VisualizationType } from './store/report.molecule'

export type NormalizeByOption = SelectorOption & {
  type: Normalize_Type
  group?: string
}

export type PropertyFilter = {
  filterId: string
  filterType?: Filter_Type
  property: Nullable<Filter_Option>
}

export type PropertyKeyOption = {
  key: string
  value: string
  label: string
  type?: Nullable<string>
}
export type TableConfig = {
  id?: string
  type: VisualizationType.Table
  columns?: string[]
  groupByProperty?: Nullable<SelectorOption>
  schemaVersion?: Nullable<number>
}

export type ChartConfig = InsightsChartStateTemplate & {
  id?: string
  type: VisualizationType.Chart
  schemaVersion: Nullable<number>
}

export const isChart = (
  config: TableConfig | ChartConfig
): config is ChartConfig => config.type === VisualizationType.Chart

export const isTable = (
  config: TableConfig | ChartConfig
): config is TableConfig => config.type === VisualizationType.Table

export type ReportAtom = {
  id: string
  organizationId: string
  workspaceId: string
  title: string
  datasetIds: string[]
  visualizationIds: string[]
  createdAt?: string
  updatedAt?: string
  schemaVersion?: Nullable<number>
}

export type ReportRouteParams = {
  reportId: string
}

export type TableVisualization = {
  type: VisualizationType.Table
  id: string
  title: string
  config: TableConfig
}

export type ChartVisualization = {
  type: VisualizationType.Chart
  id: string
  title: string
  config: ChartConfig
}

export type Visualization = TableVisualization | ChartVisualization

export type FullReportAtom = Omit<ReportAtom, 'visualizationIds'> & {
  visualizations: Visualization[]
}
