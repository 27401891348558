import { useMutation, useQuery } from '@apollo/client'
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useMemo, useRef, useState } from 'react'

import {
  DELETE_REPORT_TEMPLATE,
  GET_REPORT_TEMPLATES
} from '@/insights/reports/queries/reportTemplates'
import { VisualizationType } from '@/insights/reports/store/report.molecule'
import { FullReportAtom } from '@/insights/reports/types'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { PAGE_SIZE_OPTIONS, TemplateLibraryKeys } from '../constants'
import { TemplateGrid } from './_TemplateGrid'
import { getColumnDefs } from './_getColumnDefs'

export function ReportTemplateGrid() {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [loadedOnce, setLoadedOnce] = useState(false)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_id: workspace_id as string,
    page: paginationModel.page + 1, // MUI Datagrid indexes pags from 0, but the backend indexes from 1
    page_size: paginationModel.pageSize
  }

  const { data, previousData, loading } = useQuery(GET_REPORT_TEMPLATES, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id,
    onCompleted: () => {
      setLoadedOnce(true)
    }
  })

  const [deleteMutation] = useMutation(DELETE_REPORT_TEMPLATE, {
    refetchQueries: [
      { query: GET_REPORT_TEMPLATES, variables: queryVariables }
    ],
    awaitRefetchQueries: true
  })

  const totalRowCount = data?.get_report_templates.total
  const rowCountRef = useRef(totalRowCount ?? 0)
  const rowCount = useMemo(() => {
    if (totalRowCount != null) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  const previousRows = previousData?.get_report_templates.data ?? []
  const currentRows = data?.get_report_templates.data ?? []
  const rowsToRender = loading ? previousRows : currentRows

  const columnDefs = useMemo(() => {
    const columnDefs = getColumnDefs('report template', (id: string) => {
      deleteMutation({ variables: { id } })
    })

    const visualizationCol: GridColDef = {
      field: 'report_template',
      headerName: 'Visualizations',
      minWidth: 200,
      flex: 1,
      disableReorder: true,
      sortable: true,
      renderCell: ({ value, row }) => {
        if (value == null) return value
        const report = JSON.parse(value) as FullReportAtom
        const chartCount = report.visualizations.filter(
          v => v.type === VisualizationType.Chart
        ).length
        const tableCount = report.visualizations.filter(
          v => v.type === VisualizationType.Table
        ).length

        return (
          <span>
            {chartCount !== 0 && (
              <>
                {chartCount} Chart{chartCount !== 1 ? 's' : ''}
              </>
            )}

            {tableCount !== 0 && (
              <>
                {chartCount !== 0 && ', '}
                {tableCount} Table{tableCount !== 1 ? 's' : ''}
              </>
            )}
          </span>
        )
      }
    }

    const idColumnIndex = columnDefs.findIndex(
      col => col.field === TemplateLibraryKeys.Id
    )
    if (idColumnIndex !== -1) {
      columnDefs.splice(idColumnIndex, 0, visualizationCol)
    }

    return columnDefs
  }, [deleteMutation])

  return (
    <TemplateGrid
      columnDefs={columnDefs}
      loadedOnce={loadedOnce}
      loading={loading}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
      rowCount={rowCount}
      rowsToRender={rowsToRender}
      title='Report Templates'
    />
  )
}
