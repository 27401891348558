import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ReactNode } from 'react'

import { store } from '../state/redux/store'

const byterat_iris_apollo_gql_host_protocol =
  process.env.REACT_APP_byterat_iris_apollo_gql_host_protocol
const byterat_iris_apollo_gql_host =
  process.env.REACT_APP_byterat_iris_apollo_gql_host
const byterat_iris_apollo_gql_port =
  process.env.REACT_APP_byterat_iris_apollo_gql_port

const http_link = createHttpLink({
  uri: `${byterat_iris_apollo_gql_host_protocol}://${byterat_iris_apollo_gql_host}:${byterat_iris_apollo_gql_port}/graphql`
})

export const Apollo_Provider = ({ children }: { children: ReactNode }) => {
  // get access token dynamically from redux store when request is made
  const auth_link = setContext((_, { headers }) => {
    const access_token = store.getState().user.access_token
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${access_token}`
      }
    }
  })

  const client = new ApolloClient({
    link: auth_link.concat(http_link),
    cache: new InMemoryCache(),
    connectToDevTools: true
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
