import { useMutation, useQuery } from '@apollo/client'
import { GridPaginationModel } from '@mui/x-data-grid'
import { useMemo, useRef, useState } from 'react'

import { DELETE_TABLE, GET_TABLES } from '@/insights/tables/queries/table'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { PAGE_SIZE_OPTIONS } from '../constants'
import { TemplateGrid } from './_TemplateGrid'
import { getColumnDefs } from './_getColumnDefs'

export function TableTemplateGrid() {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [loadedOnce, setLoadedOnce] = useState(false)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_id: workspace_id as string,
    page: paginationModel.page + 1, // MUI Datagrid indexes pags from 0, but the backend indexes from 1
    page_size: paginationModel.pageSize
  }

  const { data, previousData, loading } = useQuery(GET_TABLES, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id,
    onCompleted: () => {
      setLoadedOnce(true)
    }
  })

  const [deleteMutation] = useMutation(DELETE_TABLE, {
    refetchQueries: [{ query: GET_TABLES, variables: queryVariables }],
    awaitRefetchQueries: true
  })

  const totalRowCount = data?.get_tables.total
  const rowCountRef = useRef(totalRowCount ?? 0)
  const rowCount = useMemo(() => {
    if (totalRowCount != null) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  const previousRows = previousData?.get_tables.data ?? []
  const currentRows = data?.get_tables.data ?? []
  const rowsToRender = loading ? previousRows : currentRows

  const columnDefs = useMemo(
    () =>
      getColumnDefs('table', (id: string) => {
        deleteMutation({ variables: { id } })
      }),
    [deleteMutation]
  )

  return (
    <TemplateGrid
      columnDefs={columnDefs}
      loadedOnce={loadedOnce}
      loading={loading}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
      rowCount={rowCount}
      rowsToRender={rowsToRender}
      title='Tables'
    />
  )
}
