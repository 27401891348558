import { gql } from '@/gql_generated'

export const SAVE_TABLE = gql(`
  mutation save_table($id: String!, $title: String!, $organization_id: String!, $workspace_id: String!, $owner_user_id: String!, $table_config: String!, $description: String, $schema_version: Int) {
    save_table(
      id: $id, 
      title: $title, 
      organization_id: $organization_id, 
      workspace_id: $workspace_id, 
      owner_user_id: $owner_user_id,
      table_config: $table_config,
      description: $description,
      schema_version: $schema_version
    ) {
      id
      title
      description
      schema_version
      table_config
      owner_user_id
      created_at
      updated_at
    }
  }
`)

export const GET_TABLES = gql(`
  query get_tables($organization_id: String!, $workspace_id: String!, $page: Float!, $page_size: Float!) {
    get_tables(organization_id: $organization_id, workspace_id: $workspace_id, page: $page, page_size: $page_size) {
      data {
        id
        title
        description
        schema_version
        created_at
        updated_at
        table_config
        owner_user_id
      }
      total
      page
      page_size      
    }
  }
`)

export const LOAD_TABLE = gql(`
  query load_table($id: String!) {
    load_table(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      table_config
      owner_user_id
    }
  }
`)

export const DELETE_TABLE = gql(`
  mutation delete_table($id: String!) {
    delete_table(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      table_config
      owner_user_id
    }
  }
`)
