import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  alpha,
  darken,
  emphasize
} from '@mui/material'
import { ReactNode } from 'react'

import {
  NAV_BACKGROUND_COLOR,
  NAV_FOREGROUND_COLOR
} from '../Left_Nav.constants'

type Left_Nav_List_Item_Props = {
  icon: ReactNode
  label: string
  is_left_nav_open: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  selected?: boolean
}

export const Left_Nav_List_Item = ({
  icon,
  label,
  is_left_nav_open,
  onClick,
  selected
}: Left_Nav_List_Item_Props) => {
  return (
    <ListItem
      disablePadding
      sx={{
        display: 'block',
        marginBottom: theme => theme.spacing(0.5)
      }}
    >
      <Tooltip
        title={label}
        placement='right'
        disableHoverListener={is_left_nav_open}
      >
        <ListItemButton
          sx={{
            px: theme => theme.spacing(1.5),
            minHeight: 48,
            borderRadius: theme => theme.shape.borderRadius / 2,
            justifyContent: is_left_nav_open ? 'initial' : 'center',
            '& .MuiTypography-root': {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            },
            '&:hover': {
              backgroundColor: alpha(NAV_FOREGROUND_COLOR, 0.2)
            },
            '&.Mui-selected': {
              backgroundColor: darken(NAV_BACKGROUND_COLOR, 0.15),
              '&:hover': {
                backgroundColor: emphasize(NAV_BACKGROUND_COLOR, 0.2)
              }
            }
          }}
          onClick={onClick}
          selected={selected}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: is_left_nav_open ? 2 : 'auto',
              justifyContent: 'center',
              flex: is_left_nav_open ? undefined : 1
            }}
          >
            {icon}
          </ListItemIcon>
          {is_left_nav_open && (
            <ListItemText
              primary={label}
              disableTypography
              sx={{
                color: NAV_FOREGROUND_COLOR,
                fontWeight: 600,
                opacity: is_left_nav_open ? 1 : 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            />
          )}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  )
}
