import type { Action, ThunkAction } from '@reduxjs/toolkit'
import { combineSlices, configureStore, createAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { cycler_observations_chart_slice } from '../../insights/charts/cycle_observations/cycler_observations_chart_slice'
import { cycle_summaries_chart_slice } from '../../insights/charts/cycle_summaries/cycle_summaries_chart_slice'
import { dataset_metrics_chart_slice } from '../../insights/charts/dataset_metrics/dataset_metrics_chart_slice'
import { insights_slice } from '../../insights/home/insights_slice'
import { user_slice } from '../../user/user_slice'

export const reset_insights = createAction('reset_insights')

const root_reducer = combineSlices(
  insights_slice,
  dataset_metrics_chart_slice,
  cycler_observations_chart_slice,
  cycle_summaries_chart_slice,
  user_slice
)
export type Root_State = ReturnType<typeof root_reducer>

export const make_store = (preloadedState?: Partial<Root_State>) => {
  const store = configureStore({
    reducer: root_reducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware()
    },
    preloadedState
  })
  setupListeners(store.dispatch)
  return store
}

export const store = make_store()

export type App_Store = typeof store
export type App_Dispatch = App_Store['dispatch']
export type App_Thunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  Root_State,
  unknown,
  Action
>
