/* eslint-disable react-hooks/rules-of-hooks */
import { useLogoutFunction } from '@propelauth/react'
import { use_app_dispatch } from '../../state/redux/hooks'
import { reset_insights } from '../../state/redux/store'
import { reset_user } from '../../user/user_slice'
import { useApolloClient } from '@apollo/client'

export const use_logout = () => {
  const dispatch = use_app_dispatch()
  const propel_auth_logout = useLogoutFunction()
  const apollo_client = useApolloClient()

  return () => {
    apollo_client.clearStore()
    dispatch(reset_insights())
    dispatch(reset_user())
    propel_auth_logout(false)
  }
}
