import { useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import { useState } from 'react'

import { Button, Popover } from '@/components'
import { Iris_Report_Template } from '@/gql_generated/graphql'

import {
  reportAtomFamily,
  visualizationAtomFamily
} from '../../store/report.molecule'
import { FullReportAtom, Visualization } from '../../types'
import { VirtualizedReportTemplateList } from './VirtualizedReportTemplateList'

type ApplyTemplateToReportProps = {
  reportId: string
}
export const ApplyTemplateToReport = (props: ApplyTemplateToReportProps) => {
  const { reportId } = props

  const setReport = useSetAtom(reportAtomFamily({ id: reportId }))

  const [popoverOpen, setPopoverOpen] = useState(false)

  const applyTemplateToReport = (item: Partial<Iris_Report_Template>) => {
    const reportTemplate = JSON.parse(
      item.report_template ?? '{}'
    ) as FullReportAtom

    reportTemplate.visualizations.forEach((viz: Visualization) => {
      const vizId = nanoid()

      // Yes, these are mutations, but we're in a parsed report that has no ripple effects,
      // so it's safe to mutate the object with the new id
      viz.id = vizId
      viz.config.id = vizId

      visualizationAtomFamily(viz)
    })

    setReport(prev => ({
      ...prev,
      visualizationIds: reportTemplate.visualizations.map(viz => viz.id)
    }))
  }

  return (
    <Popover
      arrow={false}
      trigger='click'
      placement='bottomRight'
      overlayInnerStyle={{ padding: 8, width: '33vw', minWidth: '25rem' }}
      open={popoverOpen}
      destroyTooltipOnHide
      onOpenChange={(nextState: boolean) => {
        setPopoverOpen(nextState)
      }}
      content={
        <VirtualizedReportTemplateList
          onItemClick={item => {
            applyTemplateToReport(item)
            setPopoverOpen(false)
          }}
          showItemTooltips={false}
        />
      }
    >
      <Button type='primary'>Apply Template</Button>
    </Popover>
  )
}
