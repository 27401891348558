import { PayloadAction, createSelector } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'

import {
  Filter,
  Filter_Option,
  Filter_Type,
  Observations,
  Property_Type
} from '../../../gql_generated/graphql'
import { create_app_slice } from '../../../state/redux/create_app_slice'
import { reset_insights } from '../../../state/redux/store'
import { PropertyFilterWithValues } from '../../types'
import {
  CHART_COLORS,
  CHART_SIG_FIGS,
  CHART_TYPE,
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  MARKER_SHAPE_SIZE,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types,
  OnAxesRangeChange,
  VALID_MARKER_SHAPES,
  create_chart_options
} from '../chart_options'
import {
  Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '../models/byterat_properties.model'
import { format_axis_tick, format_property_label } from '../utils'

export interface Cycler_Observations_Slice {
  cycler_series_observations?: Observations
  cycle_options: Filter_Option[]
  selected_y_observation_properties: Filter_Option[]
  selected_x_observation_property: Filter_Option
  x_property_range: [number | null, number | null]
  y_property_ranges: Record<string, [number | null, number | null]> | null
  chart_type: CHART_TYPE
  property_filters: Record<string, PropertyFilterWithValues>
  cycle_filters: Record<
    string,
    {
      property: Filter_Option | null
      values: Filter_Option[]
      filter_type: Filter_Type
    }
  >
}

const initial_state: Cycler_Observations_Slice = {
  cycle_options: [],
  selected_x_observation_property: {
    key: 'cycle_time',
    label: 'Time - Since Cycle Began'
  },
  selected_y_observation_properties: [{ key: 'current', label: 'Current' }],
  x_property_range: [null, null],
  y_property_ranges: null,
  chart_type: CHART_TYPE.LINE,
  property_filters: {},
  cycle_filters: {}
}

type Extended_Apex_Y_Axis = ApexYAxis & { group: string }

export const cycler_observations_chart_slice = create_app_slice({
  name: 'cycler_observations_chart',
  initialState: initial_state,
  reducers: create => ({
    set_cycler_observations: create.reducer(
      (state, { payload }: PayloadAction<Observations | undefined>) => {
        state.cycler_series_observations = payload
      }
    ),
    set_cycle_filters: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<
          Record<
            string,
            {
              property: Filter_Option | null
              filter_type: Filter_Type
              values: Filter_Option[]
            }
          >
        >
      ) => {
        state.cycle_filters = payload
      }
    ),
    set_cycle_options: create.reducer(
      (state, { payload }: PayloadAction<Filter_Option[]>) => {
        state.cycle_options = payload
      }
    ),
    set_selected_y_observation_properties: create.reducer(
      (state, { payload }: PayloadAction<Filter_Option[]>) => {
        state.selected_y_observation_properties = payload
        state.y_property_ranges = null
      }
    ),
    set_selected_x_observation_property: create.reducer(
      (state, { payload }: PayloadAction<Filter_Option>) => {
        state.selected_x_observation_property = payload
        state.x_property_range = [null, null]
      }
    ),
    set_chart_ranges: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          x_range: [number | null, number | null]
          y_ranges: Record<string, [number | null, number | null]> | null
        }>
      ) => {
        state.x_property_range = payload.x_range
        state.y_property_ranges = payload.y_ranges
      }
    ),
    set_x_property_range: create.reducer(
      (state, { payload }: PayloadAction<[number | null, number | null]>) => {
        state.x_property_range = payload
      }
    ),
    set_y_property_ranges: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<Record<string, [number | null, number | null]> | null>
      ) => {
        state.y_property_ranges = payload
      }
    ),
    set_chart_type: create.reducer(
      (state, { payload }: PayloadAction<CHART_TYPE>) => {
        state.chart_type = payload
      }
    ),
    create_property_filter: create.reducer(state => {
      const filter_id = uuidv4()

      state.property_filters[filter_id] = {
        property: null,
        values: [],
        filter_type: Filter_Type.IsAnyOf
      }
    }),
    delete_property_filter: create.reducer(
      (state, { payload }: PayloadAction<{ filter_id: string }>) => {
        delete state.property_filters[payload.filter_id]
      }
    ),
    delete_all_property_filters: create.reducer(state => {
      state.property_filters = {}
    }),
    set_property_filter_values: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{ filter_id: string; values: Filter_Option[] }>
      ) => {
        state.property_filters[payload.filter_id].values = payload.values
        if (
          typeof payload.values === 'number' &&
          state.property_filters[payload.filter_id].filter_type ===
            Filter_Type.IsAnyOf
        ) {
          state.property_filters[payload.filter_id].filter_type =
            Filter_Type.NumericEquals
        }
      }
    ),
    set_property_filter_property: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          filter_id: string
          property: Filter_Option | null
          filter_type?: Filter_Type
        }>
      ) => {
        state.property_filters[payload.filter_id] = {
          property: payload.property,
          values: [],
          filter_type: Numeric_Property_Types.includes(
            payload.property?.type as Property_Type
          )
            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
        }
      }
    ),
    set_property_filter_operator: create.reducer(
      (
        state,
        {
          payload
        }: PayloadAction<{
          filter_id: string
          filter_type: Filter_Type
        }>
      ) => {
        state.property_filters[payload.filter_id].filter_type =
          payload.filter_type || Filter_Type.IsAnyOf
      }
    )
  }),
  extraReducers: builder => {
    builder.addCase(reset_insights, () => initial_state)
  },
  selectors: {
    select_cycle_options: slice_state => slice_state.cycle_options,
    select_selected_y_observation_properties: slice_state =>
      slice_state.selected_y_observation_properties,
    select_selected_x_observation_property: slice_state =>
      slice_state.selected_x_observation_property,
    select_chart_type: slice_state => slice_state.chart_type,
    select_x_property_range: slice_state => slice_state.x_property_range,
    select_y_property_ranges: slice_state => slice_state.y_property_ranges,
    select_property_filters: slice_state => slice_state.property_filters,
    select_cycle_filters: slice_state => slice_state.cycle_filters,
    select_property_filter_keys: slice_state =>
      Object.values(slice_state.property_filters)
        .map(({ property }) => property?.key)
        .filter(is_string),
    select_cycler_series_observations: slice_state =>
      slice_state.cycler_series_observations
  }
})

// Action creators are generated for each case reducer function.
export const {
  set_cycler_observations,
  set_cycle_filters,
  set_cycle_options,
  set_selected_y_observation_properties,
  set_selected_x_observation_property,
  set_chart_ranges,
  set_x_property_range,
  set_y_property_ranges,
  set_chart_type,
  create_property_filter,
  delete_property_filter,
  delete_all_property_filters,
  set_property_filter_values,
  set_property_filter_property,
  set_property_filter_operator
} = cycler_observations_chart_slice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  select_cycle_options,
  select_cycler_series_observations,
  select_selected_y_observation_properties,
  select_selected_x_observation_property,
  select_x_property_range,
  select_y_property_ranges,
  select_property_filters,
  select_cycle_filters,
  select_property_filter_keys,
  select_chart_type
} = cycler_observations_chart_slice.selectors

// Memoized selectors derived from slice state
export const select_filters_for_query = createSelector(
  [
    select_selected_x_observation_property,
    select_x_property_range,
    select_property_filters
  ],
  (selected_x_observation_property, x_property_range, property_filters) => {
    const filters: Filter[] = []

    if (x_property_range) {
      filters.push({
        key: selected_x_observation_property.key,
        value: x_property_range,
        filter_type: Filter_Type.Range
      })
    }

    Object.values(property_filters).forEach(property_filter => {
      if (property_filter.property) {
        if (Array.isArray(property_filter.values)) {
          if (property_filter.values.length !== 0) {
            filters.push({
              key: property_filter.property.key,
              value: property_filter.values.map(value => value.key),
              filter_type: property_filter.filter_type
            })
          }
        } else if (typeof property_filter.values === 'number') {
          filters.push({
            key: property_filter.property.key,
            value: property_filter.values,
            filter_type: property_filter.filter_type
          })
        }
      }
    })

    return filters
  }
)

export const select_cycle_filters_for_query = createSelector(
  [select_cycle_filters],
  cycle_filters => {
    const filters: Filter[] = []

    Object.values(cycle_filters).forEach(cycle_filter => {
      if (cycle_filter.property) {
        if (Array.isArray(cycle_filter.values)) {
          if (cycle_filter.values.length !== 0) {
            filters.push({
              key: cycle_filter.property.key,
              value: cycle_filter.values.map(value => value.key),
              filter_type: cycle_filter.filter_type
            })
          }
        } else if (typeof cycle_filter.values === 'number') {
          filters.push({
            key: cycle_filter.property.key,
            value: cycle_filter.values,
            filter_type: cycle_filter.filter_type
          })
        }
      }
    })

    return filters
  }
)

const select_x_axis = createSelector(
  [select_cycler_series_observations, select_x_property_range],
  (observations, x_property_range) => {
    const x_property = observations?.x_property
    return {
      key: x_property?.key,
      label: format_property_label(x_property?.label, x_property?.units),
      min: x_property_range[0] === null ? undefined : x_property_range[0],
      max: x_property_range[1] === null ? undefined : x_property_range[1]
    }
  }
)

export const select_y_axes = createSelector(
  select_cycler_series_observations,
  observations => {
    return (
      observations?.y_properties.reduce(
        (
          acc: Record<
            string,
            {
              label: string
              marker: MarkerShapeOptions
              dash_array: number
            }
          >,
          y_property,
          i
        ) => {
          acc[y_property.key] = {
            label: format_property_label(y_property.label, y_property.units),
            marker: VALID_MARKER_SHAPES[i % VALID_MARKER_SHAPES.length],
            dash_array: i * 2
          }
          return acc
        },
        {}
      ) || {}
    )
  }
)

export const select_series_groups = createSelector(
  select_cycler_series_observations,
  observations => {
    const series_groups: Record<string, { color: string; label: string }> = {}

    let cycle_count = 0
    observations?.datasets?.forEach(({ dataset_id, cycles }) =>
      cycles.forEach(({ cycle_number }) => {
        const color = CHART_COLORS[cycle_count % CHART_COLORS.length]
        const name = get_group_name(dataset_id, cycle_number)
        series_groups[name] = { color, label: name }
        cycle_count += 1
      })
    )

    return series_groups
  }
)

const select_series_by_y_property = createSelector(
  [select_cycler_series_observations],
  observations => {
    const series_by_y_property: Record<
      string,
      {
        group: string
        name: string
        data: { x: number; y: number | null }[]
      }[]
    > = {}

    if (!observations) return series_by_y_property

    const { datasets, y_properties, x_property } = observations
    datasets?.forEach(({ dataset_id, cycles }) =>
      cycles.forEach(({ series, cycle_number }) => {
        const group = get_group_name(dataset_id, cycle_number)

        y_properties.forEach(y_property => {
          const name = `${group} ${format_property_label(
            y_property.label,
            y_property.units
          )}`

          const data =
            series
              .filter(
                y_property_series =>
                  y_property_series.y_property === y_property.key
              )[0]
              ?.observation_data.map(({ observation_properties }) => {
                const x_value = observation_properties.filter(
                  property => property.key === x_property.key
                )[0].value

                let y_value: number | null = observation_properties.filter(
                  property => property.key === y_property.key
                )[0].value

                return { x: x_value, y: y_value }
              }) || []

          /**
           * TOFIX: Due to a bug in Apex Charts, we need to plot only unique
           * x-values. Once the bug is fixed in Apex we can plot all data with overlapping
           * x-values.
           * https://github.com/apexcharts/apexcharts.js/issues/4560
           */
          const unique_x_value_data = uniqBy(data, 'x')
          if (unique_x_value_data.length === 1) {
            unique_x_value_data[1] = unique_x_value_data[0]
          }

          if (series_by_y_property[y_property.key] === undefined) {
            series_by_y_property[y_property.key] = [
              { data: unique_x_value_data, name, group }
            ]
          } else {
            series_by_y_property[y_property.key].push({
              data: unique_x_value_data,
              name,
              group
            })
          }
        })
      })
    )

    return series_by_y_property
  }
)

export const select_chart_data = createSelector(
  select_series_by_y_property,
  series_by_y_property => {
    return Object.values(series_by_y_property).flat()
  }
)

export const select_apex_chart_options = createSelector(
  [
    select_x_axis,
    select_y_axes,
    select_y_property_ranges,
    select_series_groups,
    select_chart_type,
    (
      _,
      on_zoom_change: (
        x_range: [number | null, number | null],
        y_ranges: Record<string, [number | null, number | null]> | null
      ) => void,
      on_axes_range_change: (ranges: OnAxesRangeChange) => void
    ) => ({ on_zoom_change, on_axes_range_change })
  ],
  (x_axis, y_axes, y_property_ranges, series_groups, chart_type, rest) => {
    const { on_zoom_change, on_axes_range_change } = rest

    const apex_x_axis: ApexXAxis = {
      title: { text: x_axis?.label },
      labels: get_apex_axis_labels<ApexXAxis['labels']>(x_axis.key),
      type: x_axis.key === 'time' ? 'datetime' : 'numeric',
      decimalsInFloat: CHART_SIG_FIGS,
      min: x_axis?.min,
      max: x_axis?.max
    }
    const apex_y_axes: Extended_Apex_Y_Axis[] = []
    const colors: string[] = []
    const stroke_dashes: number[] = []
    const marker_shapes: MarkerShapeOptions[] = []
    const marker_sizes: number[] = []

    Object.keys(y_axes).forEach((y_axis_key, y_axis_count) => {
      const y_axis = y_axes[y_axis_key]
      const group_keys = Object.keys(series_groups)
      const default_group_key = group_keys[0]
      group_keys.forEach((group_key, group_count) => {
        const group = series_groups[group_key]
        colors.push(group.color)
        marker_shapes.push(y_axis.marker)
        marker_sizes.push(MARKER_SHAPE_SIZE[y_axis.marker])
        stroke_dashes.push(y_axis.dash_array)
        apex_y_axes.push({
          title: { text: y_axis?.label },
          opposite: y_axis_count !== 0,
          show: group_count === 0,
          seriesName: `${default_group_key} ${y_axis.label}`,
          labels: get_apex_axis_labels<ApexYAxis['labels']>(y_axis_key),
          decimalsInFloat: CHART_SIG_FIGS,
          group: y_axis_key,
          min:
            y_property_ranges?.[y_axis_count]?.[0] ||
            y_property_ranges?.[y_axis_key]?.[0] ||
            undefined,
          max:
            y_property_ranges?.[y_axis_count]?.[1] ||
            y_property_ranges?.[y_axis_key]?.[1] ||
            undefined
        })
      })
    })

    return {
      options: create_chart_options({
        x_axis: apex_x_axis,
        y_axes: apex_y_axes,
        colors,
        marker_shapes,
        marker_sizes,
        dashes: stroke_dashes,
        chart_type,
        on_zoom_change,
        on_axes_range_change: on_axes_range_change
      })
    }
  }
)

// Utils
const get_apex_axis_labels = <
  T extends ApexXAxis['labels'] | ApexYAxis['labels']
>(
  property_key?: string
): T => {
  return {
    hideOverlappingLabels: true,
    showDuplicates: false,
    rotate: 0,
    formatter:
      property_key === Byterat_Property.time
        ? undefined
        : Non_Absolute_Time_Byterat_Property.includes(
            property_key as Byterat_Property
          )
        ? function (val: number) {
            return format_axis_tick(val / (1000 * 60 * 60))
          }
        : format_axis_tick
  } as T
}

const get_group_name = (dataset_id: string, cycle_number: number) =>
  `${dataset_id}: Cycle ${cycle_number}`

function is_string(value: string | undefined): value is string {
  return typeof value === 'string'
}
