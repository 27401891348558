import { gql } from '@/gql_generated'

export const SAVE_CHART = gql(`
  mutation save_chart($id: String!, $title: String!, $organization_id: String!, $workspace_id: String!, $owner_user_id: String!, $chart_config: String!, $description: String, $schema_version: Int) {
    save_chart(
      id: $id, 
      title: $title, 
      organization_id: $organization_id, 
      workspace_id: $workspace_id, 
      owner_user_id: $owner_user_id,
      chart_config: $chart_config,
      description: $description,
      schema_version: $schema_version
    ) {
      id
      title
      description
      schema_version
      chart_config
      owner_user_id
      created_at
      updated_at
    }
  }
`)

export const GET_CHARTS = gql(`
  query get_charts($organization_id: String!, $workspace_id: String!, $page: Float!, $page_size: Float!) {
    get_charts(organization_id: $organization_id, workspace_id: $workspace_id, page: $page, page_size: $page_size) {
      data {
        id
        title
        description
        schema_version
        created_at
        updated_at
        chart_config
        owner_user_id
      }
      total
      page
      page_size      
    }
  }
`)

export const LOAD_CHART = gql(`
  query load_chart($id: String!) {
    load_chart(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      chart_config
      owner_user_id
    }
  }
`)

export const DELETE_CHART = gql(`
  mutation delete_chart($id: String!) {
    delete_chart(id: $id) {
      id
      title
      description
      schema_version
      created_at
      updated_at
      chart_config
      owner_user_id
    }
  }
`)
