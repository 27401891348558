import { useQuery } from '@apollo/client'
import { ChevronUp, Search, View, ViewOff } from '@carbon/icons-react'
import { Box, Grid2 } from '@mui/material'
import { Button, Divider, Input } from 'antd'
import Fuse from 'fuse.js'
import { useAtom } from 'jotai'
import { isEmpty, isEqual, startCase } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'

import { GET_COLUMNS_FOR_INSIGHTS_DASHBOARD } from '@/datasets/queries/get_datasets_for_insights_dashboard'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { cn } from '@/utils'

import { visualizationAtomFamily } from '../reports/store/report.molecule'
import { TableConfig, Visualization } from '../reports/types'
import { DatasetColumnItem } from '../reports/views/ReportBuilder/visualizations/tables/DatasetColumnItem'
import {
  AugmentedColumn,
  SelectedDatasetColumns
} from '../reports/views/ReportBuilder/visualizations/tables/SelectedDatasetColumns'
import { Menu_Header, use_menu_nav } from './menus'

export function InsightsPanelTableColumns({ tableId }: { tableId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const [visualization, setVisualization] = useAtom(
    visualizationAtomFamily({ id: tableId })
  )

  const [hideVisibleColumns, setHideVisibleColumns] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>()
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 150)

  const { data, loading: propertiesLoading } = useQuery(
    GET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
    {
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string]
      },
      skip: !organization_id || !workspace_id
    }
  )

  const config = visualization.config as TableConfig

  const datasetProperties: AugmentedColumn[] = useMemo(
    () =>
      (data?.get_datasets_list.columns ?? []).map(({ key, units }) => ({
        key,
        label: startCase(key),
        units,
        visible: config?.columns?.includes(key) ?? false
      })),
    [config?.columns, data?.get_datasets_list.columns]
  )

  const fuse = useMemo(() => {
    return new Fuse(datasetProperties, {
      keys: ['units', 'label', 'key']
    })
  }, [datasetProperties])

  const selectableOptions = useMemo(() => {
    if (debouncedSearchTerm != null && debouncedSearchTerm.length > 0) {
      return fuse.search(debouncedSearchTerm).map(({ item }) => item)
    }
    return datasetProperties
  }, [datasetProperties, fuse, debouncedSearchTerm])

  const selectedColumns = selectableOptions
    .filter(({ visible }) => visible)
    .sort((a, b) => {
      const aIndex = config?.columns?.indexOf(a.key) ?? -1
      const bIndex = config?.columns?.indexOf(b.key) ?? -1
      return aIndex - bIndex
    })
  const unselectedColumns = selectableOptions.filter(({ visible }) => !visible)
  const selectedColumnCount = selectedColumns.length

  const allHidden = datasetProperties.every(({ visible }) => !visible)

  const setVisualizationColumns = (columns: string[]) => {
    setVisualization(prev => {
      const next: Visualization = {
        ...prev,
        config: { ...prev.config, columns } as any
      }
      return isEqual(prev, next) ? prev : next
    })
  }

  const toggleColumnVisibility = (key: string) => {
    const newColumns = config?.columns?.includes(key)
      ? config.columns.filter(columnKey => columnKey !== key)
      : [...(config?.columns ?? []), key]

    setVisualizationColumns(newColumns)
    setHideVisibleColumns(false)
  }

  const makeToggleColumnVisibility = (key: string) => () => {
    toggleColumnVisibility(key)
  }

  const onColumnsReordered = (columns: AugmentedColumn[]) => {
    setVisualizationColumns(columns.map(({ key }) => key))
  }

  return (
    <>
      <Menu_Header title='Columns' on_go_back={return_to_root} />

      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <div className='flex flex-row gap-x-1'>
            <Input
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              prefix={<Search size={16} className='text-gray-500 mr-0.5' />}
              allowClear
            />
            {allHidden ? (
              <Button
                disabled={propertiesLoading}
                variant='text'
                color='primary'
                className='px-1 text-xs font-semibold'
                onClick={() => {
                  setVisualizationColumns(
                    datasetProperties.map(({ key }) => key)
                  )
                }}
              >
                <View /> Show all
              </Button>
            ) : (
              <Button
                disabled={propertiesLoading}
                variant='text'
                color='primary'
                className='px-1 text-xs font-semibold'
                onClick={() => {
                  setVisualizationColumns([])
                }}
              >
                <ViewOff /> Hide all
              </Button>
            )}
          </div>
          <div className='bg-gray-50 p-2 gap-2 rounded-md'>
            <div className='h-80 overflow-y-auto overflow-x-hidden relative gap-y-2'>
              {selectedColumnCount > 0 && (
                <>
                  <div
                    className={cn(
                      'flex flex-col gap-y-1 transition-all ease-in-out duration-300 overflow-hidden',
                      {
                        'max-h-0': hideVisibleColumns,
                        'max-h-[1000px]': !hideVisibleColumns // Set a large enough max height to accommodate the content
                      }
                    )}
                  >
                    <SelectedDatasetColumns
                      columns={selectedColumns}
                      groupByProperty={config.groupByProperty}
                      onColumnsReordered={onColumnsReordered}
                      onToggleVisibility={toggleColumnVisibility}
                    />
                  </div>
                  <Divider className='!my-2'>
                    <Button
                      aria-label={
                        hideVisibleColumns
                          ? 'Show visible columns'
                          : 'Hide visible columns'
                      }
                      variant='filled'
                      color='default'
                      className='flex flex-row gap-x-1 text-xxs font-semibold text-gray-600'
                      size='small'
                      onClick={() => setHideVisibleColumns(prev => !prev)}
                    >
                      Selected columns
                      <ChevronUp
                        size='1.25em'
                        className={cn('ml-0.5 transition-all ease-in-out', {
                          'transform rotate-0': !hideVisibleColumns,
                          'transform rotate-180': hideVisibleColumns
                        })}
                      />
                    </Button>
                  </Divider>
                </>
              )}
              <div className='flex flex-col gap-y-1'>
                {!isEmpty(unselectedColumns) ? (
                  unselectedColumns.map(({ key, label, units, visible }) => (
                    <DatasetColumnItem
                      key={key}
                      visible={visible}
                      label={label}
                      units={units}
                      isGroupKey={config.groupByProperty?.key === key}
                      onToggleVisibility={makeToggleColumnVisibility(key)}
                    />
                  ))
                ) : (
                  <div className='self-center my-1 text-xs font-medium text-gray-500'>
                    🎉 No unselected columns
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid2>
      </Box>
    </>
  )
}
