import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from '@hello-pangea/dnd'

import { SelectorOption } from '@/insights/types'

import { DatasetColumnItem } from './DatasetColumnItem'

export type AugmentedColumn = {
  key: string
  label: string
  units?: Nullable<string>
  visible: boolean
}

type SelectedDatasetColumnsProps = {
  columns: AugmentedColumn[]
  groupByProperty: Nullable<SelectorOption> | undefined
  onColumnsReordered: (columns: AugmentedColumn[]) => void
  onToggleVisibility: (columnKey: string) => void
}

export const SelectedDatasetColumns = (props: SelectedDatasetColumnsProps) => {
  const { columns, groupByProperty, onColumnsReordered, onToggleVisibility } =
    props

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedColumns = Array.from(columns)
    const [movedColumn] = reorderedColumns.splice(result.source.index, 1)
    reorderedColumns.splice(result.destination.index, 0, movedColumn)

    onColumnsReordered(reorderedColumns)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId='columns'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='flex flex-col gap-y-1'
          >
            {columns.map((column, index) => (
              <Draggable
                key={column.key}
                draggableId={column.key}
                index={index}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DatasetColumnItem
                      visible={column.visible}
                      label={column.label}
                      units={column.units}
                      isGroupKey={groupByProperty?.key === column.key}
                      onToggleVisibility={() => onToggleVisibility(column.key)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

// import {
//   DragDropContext,
//   Draggable,
//   DropResult,
//   Droppable
// } from '@hello-pangea/dnd'

// type AugmentedColumn = {
//   key: string
//   label: string
//   units?: Nullable<string>
//   visible: boolean
//   order: number
// }

// type SelectedDatasetColumnsProps = {
//   columns: AugmentedColumn[]
//   onColumnsSorted: (columns: AugmentedColumn[]) => void
// }

// export const SelectedDatasetColumns = (props: SelectedDatasetColumnsProps) => {
//   const { columns, onColumnsSorted } = props
//   const handleOnDragEnd = (result: DropResult) => {
//     if (!result.destination) return

//     const reorderedColumns = Array.from(columns)
//     const [movedColumn] = reorderedColumns.splice(result.source.index, 1)
//     reorderedColumns.splice(result.destination.index, 0, movedColumn)

//     onColumnsSorted(reorderedColumns)
//   }

//   return (
//     <DragDropContext onDragEnd={handleOnDragEnd}>
//       <Droppable droppableId='columns'>
//         {provided => (
//           <div {...provided.droppableProps} ref={provided.innerRef}>
//             {columns.map((column, index) => (
//               <Draggable
//                 key={column.key}
//                 draggableId={column.key}
//                 index={index}
//               >
//                 {provided => (
//                   <div
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                     style={{
//                       userSelect: 'none',
//                       padding: '16px',
//                       margin: '0 0 8px 0',
//                       minHeight: '50px',
//                       backgroundColor: '#fff',
//                       color: '#333',
//                       ...provided.draggableProps.style
//                     }}
//                   >
//                     {column.label}
//                   </div>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </DragDropContext>
//   )
// }
