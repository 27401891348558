import { gql } from '../../../../gql_generated'

export const GET_CYCLER_OBSERVATIONS = gql(`
  query get_cycler_observations(
    $organization_id: String!
    $workspace_ids: [String!]!
    $dataset_ids: [String!]!
    $cycle_filters: [Filter!]!
    $dataset_filters: [Filter!]!
    $x_property: String!
    $y_properties: [String!]!
    $group_by_property: String
    $normalize_by_properties: [Normalize_By_Properties_Arg!]!
    $additional_properties: [String!]!
  ) {
    get_cycler_observations(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      dataset_ids: $dataset_ids
      cycle_filters: $cycle_filters
      dataset_filters: $dataset_filters
      x_property: $x_property
      y_properties: $y_properties
      group_by_property: $group_by_property
      normalize_by_properties: $normalize_by_properties
      additional_properties: $additional_properties
    ) {
      x_property {
        key
        label
        units
      }
      y_properties {
        key
        label
        units
      }
      datasets {
        dataset_id
        aggregate_by_none_group
        cycles {
          cycle_number
          series {
            x_property
            y_property
            observation_data {
              observation_id
              observation_properties {
                key
                value
                annotations
              }
            }
          }
        }
      }
    }
  }
`)
