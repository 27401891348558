export const emptyObject = {}
export const emptyArray = []

export enum DateFormats {
  DATE_VERBOSE = 'MMMM D, YYYY',
  DATE_TIME_VERBOSE = 'MMMM D, YYYY h:mm A',
  DATE_TIME = 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_WITH_TZ = 'YYYY-MM-DD HH:mm:ss z',
  DATE = 'YYYY-MM-DD',
  TIME = 'HH:mm:ss',
  CHART_DATE_TIME = 'MMM D, HH:mm:ss'
}

export enum RangeValue {
  Min = 'min',
  Max = 'max'
}

export const CurrentSchemaVersions = {
  Report: 2,
  Chart: 2,
  Table: 1
}
