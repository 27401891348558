import { Link as LinkIcon } from '@carbon/icons-react'
import { Box } from '@mui/material'
import { Select } from 'antd'
import { useAtom } from 'jotai'
import { Link, useParams } from 'react-router'

import { chartStatesFamily } from '../jotai/charts.atoms'
import { Menu_Header, use_menu_nav } from './menus'

export function InsightsPanelTarget({ chartId }: { chartId: string }) {
  const { organization_key, workspace_key } = useParams()

  const { return_to_root } = use_menu_nav()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const { metricTargetsApplied } = chartState

  return (
    <>
      <Menu_Header title='Target(s)' on_go_back={return_to_root} />

      <Box width={500} paddingBottom={2} paddingInline={1}>
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          options={[
            {
              label: 'Upper',
              value: 'upper_limit'
            },
            {
              label: 'Lower',
              value: 'lower_limit'
            }
          ]}
          value={metricTargetsApplied}
          onChange={values => {
            setChartState(prev => ({
              ...prev,
              metricTargetsApplied: values
            }))
          }}
          showSearch={false}
          allowClear
          placeholder='None selected'
        />

        <Link
          to={`/${organization_key}/${workspace_key}/cell-specifications`}
          className='mt-4 text-xs flex items-center gap-2'
        >
          <LinkIcon /> Change Cell Specifications
        </Link>
      </Box>
    </>
  )
}
