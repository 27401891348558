import { Grid2 } from '@mui/material'
import { Divider } from 'antd'
import { ErrorBoundary } from 'react-error-boundary'

import { BaseLayout } from '../../components/BaseLayout'
import { InsightsChartView } from '../charts/InsightsChartView'
import { Insights_Datasets_Table } from '../tables/Insights_Datasets_Table'

export const Insight_Dashboard = () => {
  return (
    <BaseLayout>
      <Divider />

      <div className='my-4'>
        <InsightsChartView />
      </div>

      <Grid2 size={12}>
        <ErrorBoundary fallback={null}>
          <Insights_Datasets_Table />
        </ErrorBoundary>
      </Grid2>
    </BaseLayout>
  )
}
