import { useQuery } from '@apollo/client'
import { Add, Filter, TrashCan, WarningHexFilled } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { compact } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useParams } from 'react-router'

import { Button, Popconfirm } from '@/components'
import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '@/gql_generated/graphql'
import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types
} from '@/insights/charts'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '@/insights/charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import {
  Absolute_Time_Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '@/insights/charts/models/byterat_properties.model'
import { format_property_label } from '@/insights/charts/utils'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { mapListKeysToValues } from '@/utils'

import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../../../store/report.molecule'
import { PropertyKeyOption, ReportRouteParams } from '../../../../../types'
import { FilterPair, MenuHeader } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

export const PanelInCycleFilters = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const { config, type } = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )
  const report = useAtomValue(reportAtomFamily({ id: reportId }))

  let additionalFilterKeys: string[] = []
  if (type === VisualizationType.Chart) {
    const { propertyFilters } = config
    additionalFilterKeys = compact(
      Object.values(propertyFilters ?? {}).map(filter => filter.property?.key)
    )
  }

  const { data, previousData, loading } = useQuery(
    GET_CYCLE_OBSERVATION_FILTER_CONFIGS,
    {
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        dataset_ids: report.datasetIds || [],
        dataset_filters: [],
        additional_filter_keys: additionalFilterKeys
      },
      skip: !organization_id || !workspace_id
    }
  )

  const dataToUse = loading ? previousData : data

  const additionalPropertyKeyOptions = additionalFilterKeys.reduce(
    (acc: Record<string, Filter_Option[]>, property_key) => {
      acc[property_key] =
        dataToUse?.get_observation_filter_configs
          ?.find(property => property.filter_property === property_key)
          ?.options.map((option: any) => ({
            ...option,
            label: format_property_label(option.label, option.units)
          })) || []
      return acc
    },
    {}
  )

  const availablePropertyKeyOptions: PropertyKeyOption[] = mapListKeysToValues(
    dataToUse?.get_observation_filter_configs
      ?.find(property => property.filter_property === 'property_filter_keys')
      ?.options.filter(
        // Remove any time-based properties
        (option: any) =>
          !Absolute_Time_Byterat_Property.includes(option?.key) &&
          !Non_Absolute_Time_Byterat_Property.includes(option?.key)
      )
      .map((option: any) => ({
        ...option,
        label: format_property_label(option.label, option.units)
      })) || []
  )

  if (type !== VisualizationType.Chart) return null

  const { propertyFilters } = config

  const configPropertyFilters = propertyFilters ?? {}
  const configPropertyFilterList = Object.entries(configPropertyFilters)

  if (configPropertyFilterList.length === 0) {
    configPropertyFilterList.push([
      nanoid(),
      { property: null, values: [], filter_type: Filter_Type.IsAnyOf }
    ])
  }

  const configPropertyFiltersWithSelectionsCount = Object.values(
    configPropertyFilters
  ).filter(filter => filter.property != null || filter.values.length > 0).length

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <Filter />
            In-Cycle Filters
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-y-2'>
          {configPropertyFilterList.map(([key, filter]) => {
            return (
              <FilterPair
                filterType={filter.filter_type}
                key={key}
                leftInputProps={{
                  options: availablePropertyKeyOptions,
                  placeholder: 'Filter Property',
                  allowClear: false,
                  loading,
                  onChange: (_, option) => {
                    const typedOption = option as PropertyKeyOption
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...configPropertyFilters,
                        [key]: {
                          ...filter,
                          property: typedOption,
                          filter_type: Numeric_Property_Types.includes(
                            typedOption.type as Property_Type
                          )
                            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
                            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
                        }
                      }
                    })
                  },
                  value: filter.property
                }}
                centerOperatorProps={{
                  onChange: filterType => {
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...configPropertyFilters,
                        [key]: {
                          ...filter,
                          filter_type: filterType
                        }
                      }
                    })
                  },
                  value: filter.filter_type
                }}
                rightInputProps={{
                  placeholder: 'Filter Value',
                  className: 'text-xs',
                  loading,
                  disabled: filter.property == null,
                  options: filter.property
                    ? (additionalPropertyKeyOptions[filter.property.key] ?? [])
                    : [],
                  onChange: values => {
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...configPropertyFilters,
                        [key]: {
                          ...filter,
                          values
                        }
                      }
                    })
                  },
                  value: filter.values
                }}
                onClickX={() => {
                  const { [key]: _, ...newPropertyFilters } =
                    configPropertyFilters
                  updateVisualizationConfig({
                    propertyFilters: newPropertyFilters
                  })
                }}
              />
            )
          })}
          <div className='flex flex-row gap-x-2 items-center justify-between'>
            <Button
              variant='text'
              color='primary'
              size='small'
              className='text-xs font-semibold self-start px-0.5'
              onClick={() => {
                updateVisualizationConfig({
                  propertyFilters: {
                    ...configPropertyFilters,
                    [nanoid()]: {
                      property: null,
                      values: [],
                      filter_type: Filter_Type.IsAnyOf
                    }
                  }
                })
              }}
            >
              <Add size={16} />
              Add Filter
            </Button>
            {configPropertyFiltersWithSelectionsCount > 0 && (
              <Popconfirm
                key='delete'
                placement='topRight'
                title={`Are you sure you want to delete all ${configPropertyFiltersWithSelectionsCount} filters?`}
                onConfirm={() =>
                  updateVisualizationConfig({
                    propertyFilters: {}
                  })
                }
                okText='Yes'
                cancelText='No'
                icon={
                  <WarningHexFilled size={22} className='mr-1 text-amber-500' />
                }
              >
                <Button
                  variant='text'
                  color='primary'
                  size='small'
                  className='text-xs font-semibold self-end px-0.5'
                >
                  <TrashCan />
                  Remove All
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
