export const COLUMN_HEADER_HEIGHT = 80
export const PAGE_SIZE_OPTIONS = [25, 50, 100]

export enum TemplateLibraryKeys {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ChartConfig = 'chart_config',
  TableConfig = 'table_config',
  OrganizationId = 'organization_id',
  WorkspaceId = 'workspace_id',
  __Typename = '__typename'
}

export const EXCLUDED_KEYS = [
  TemplateLibraryKeys.__Typename,
  TemplateLibraryKeys.OrganizationId,
  TemplateLibraryKeys.WorkspaceId,
  TemplateLibraryKeys.ChartConfig,
  TemplateLibraryKeys.TableConfig
]

export const ValidKeys = Object.values(TemplateLibraryKeys).filter(
  key => !EXCLUDED_KEYS.includes(key)
)
